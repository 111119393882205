import { PuntajePlanPersonalesModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UtilModule, TranslationsModule } from "@puntaje/shared/core"
import { ApiModule } from "@puntaje/puntaje/api-services"
import { routing } from "./profesores_plan_personales.routing"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PlanPersonalesModule } from "@puntaje/puntaje/new-modules/plan-personales"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { HomePlanesPersonalesComponent } from "./home_planes_personales.component"

import { NebuModule } from "@puntaje/nebulosa/api-services"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ProfesoresLayoutsModule,
        PlanPersonalesModule,
        routing,
        EnsayosModule,
        SharedModule,
        NebuModule,
        TranslationsModule,
        CUILayoutsModule,
        PuntajePlanPersonalesModule
    ],
    exports: [HomePlanesPersonalesComponent],
    declarations: [HomePlanesPersonalesComponent],
    providers: []
})
export class ProfesoresPlanPersonalesModule {}
