<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <ng-template ngFor let-c [ngForOf]="clasificacionesByHasHijos[1]">
                <clasificaciones-checkboxes-plan-personal
                    *ngIf="clasificacionesByTipo && clasificacionesByTipo[c.clasificacionTipo]"
                    [checkAll]="false"
                    [enableCantidadPreguntas]="enableCantidadPreguntas"
                    (emitToParent)="emitToParent()"
                    [id]="c.htmlId"
                    [name]="c.name"
                    [clasificaciones]="clasificacionesByTipo[c.clasificacionTipo]"
                    [hasHijos]="c.hasHijos"
                    [tipoInstrumento]="tipoInstrumento"
                ></clasificaciones-checkboxes-plan-personal>
            </ng-template>
        </div>
        <div class="col-lg-12 col-md-12">
            <ng-template ngFor let-c [ngForOf]="clasificacionesByHasHijos[0]">
                <clasificaciones-checkboxes-plan-personal
                    *ngIf="clasificacionesByTipo && clasificacionesByTipo[c.clasificacionTipo]"
                    [checkAll]="true"
                    [enableCantidadPreguntas]="enableCantidadPreguntas"
                    (emitToParent)="emitToParent()"
                    [id]="c.htmlId"
                    [name]="c.name"
                    [clasificaciones]="clasificacionesByTipo[c.clasificacionTipo]"
                    [hasHijos]="c.hasHijos"
                    [tipoInstrumento]="tipoInstrumento"
                ></clasificaciones-checkboxes-plan-personal>
            </ng-template>
        </div>
    </div>
</loading-layout>
