<div>
    <filtro-grafico-evolutivo-clase-seguimiento
        (enviarCurso)="enviarCurso($event)"
        (enviarUsuario)="enviarUsuario($event)"
        [idPlanificacion]="idPlanificacion"
    ></filtro-grafico-evolutivo-clase-seguimiento>
</div>
<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading && data.length > 0">
        <grafico-evolutivo-evaluacion *ngIf="loadingLayout" [data]="data"></grafico-evolutivo-evaluacion>
    </div>
    <div *ngIf="!loadingLayout.loading && data.length == 0" class="not-found">No hay Evaluaciones contestadas</div>
</loading-layout>
