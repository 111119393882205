import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    AvanceTareaComponent,
    PlanesPersonalesReforzamientosComponent,
    DiagnosticoComponent,
    EstadisticasPlanesPersonalesComponent,
    GenerarPlanPersonalComponent,
    HistorialPlanesPersonalesComponent,
    PlanPersonalProfesorComponent,
    PlanesPersonalesComponent,
    PlanPersonalSesionComponent
} from "@puntaje/puntaje/core"
import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { HomePlanesPersonalesComponent } from "./home_planes_personales.component"

export const routes: Routes = [
    {
        path: "planes_personales",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePlanesPersonalesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planes_personales/generar_plan_personal/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarPlanPersonalComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "diagnostico",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: DiagnosticoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "plan_personal/:id/ciclo/:ciclo/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanPersonalSesionComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planes_personales/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasPlanesPersonalesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planes_personales/reforzamientos/:id",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: PlanesPersonalesReforzamientosComponent, canActivate: [TokenGuard, AuthGuard] }
        ]
    },
    {
        path: "planes_personales/tareas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HistorialPlanesPersonalesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "plan_personal/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanPersonalProfesorComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
