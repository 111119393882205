<ll-titulo>
    <div class="print-hide">Plan Personal: {{ planPersonal?.plan_personal }}</div>
</ll-titulo>
<!-- Datos generales Plan Personal -->
<datos-generales-plan-personal
    *ngIf="planPersonal"
    [planPersonal]="planPersonal"
    [groupedBy]="['eje temático,eje temático']"
    class="print-hide"
></datos-generales-plan-personal>
<div class="row">
    <div class="col-md-8 col-sm-7">
        <datos-generales-generador-plan-personal
            *ngIf="planPersonal"
            [planPersonal]="planPersonal"
            class="print-hide"
        ></datos-generales-generador-plan-personal>
    </div>
    <div class="col-md-4 col-sm-5">
        <opciones-plan-personal
            *ngIf="planPersonal"
            [planPersonal]="planPersonal"
            (planCompartidoEvent)="onPlanCompartidoListener($event)"
        ></opciones-plan-personal>
    </div>
</div>
<div class="top-margin">
    <!-- Vista previa Instrumento -->
    <cui-section-headline class="print-hide">Vista previa</cui-section-headline>
    <plan-personal-sesiones-profesor
        *ngIf="planPersonal"
        [planPersonal]="planPersonal"
    ></plan-personal-sesiones-profesor>
</div>
