<loading-layout #loadingPlanificacion>
    <ng-container *ngIf="planPersonalSesion">
        <div class="big-title-margin">
            <div class="big-title-container big-title-green">
                <div class="big-title-left">
                    <span class="big-title-icon"><ogr-icon name="libro" class="big-ogricon"></ogr-icon></span>
                </div>
                <h4 class="big-title-text">Materiales</h4>
            </div>
        </div>

        <table *ngIf="showMaterialStats" class="table tabla tabla-mat">
            <thead>
                <tr>
                    <th scope="col">Material</th>
                    <th scope="col">Curso</th>
                    <th scope="col">Tiempo Promedio</th>
                    <th scope="col">Cantidad Visto</th>
                    <th scope="col">Total Alumnos</th>
                    <th scope="col">Porcentaje visto</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let stat of materialStats; let mi = index">
                    <tr *ngFor="let guStat of stat.stats_grupo_usuarios; let gui = index">
                        <td *ngIf="gui == 0" class="col1" [attr.rowspan]="stat.stats_grupo_usuarios.length">
                            <span class="icon-margin-left"><ogr-icon name="libro"></ogr-icon></span>
                            {{ stat.material.material }}
                        </td>
                        <td>{{ guStat.grupo_usuario.nombre }}</td>
                        <td>
                            <span class="icon-margin-left"><fa name="clock-o"></fa></span>
                            {{ guStat.tiempo_promedio }} s
                        </td>
                        <td>{{ guStat.cantidad_visto }}</td>
                        <td>{{ guStat.grupo_usuario.numero_alumnos }}</td>
                        <td>{{ guStat.porcentaje_visto | number: "1.0-1" }}%</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div *ngIf="!showMaterialStats">
            <p class="not-found">No se encontraron estadísticas de evaluaciones para esta clase.</p>
        </div>

        <div class="big-title-margin">
            <div class="big-title-container big-title-green">
                <div class="big-title-left">
                    <span class="big-title-icon"><ogr-icon name="prueba" class="big-ogricon"></ogr-icon></span>
                </div>
                <h4 class="big-title-text">Evaluaciones</h4>
            </div>
        </div>

        <table *ngIf="showEvaluacionStats" class="table tabla tabla-eval">
            <thead>
                <tr>
                    <th>Evaluación</th>
                    <th>Curso</th>
                    <th>Tiempo Promedio</th>
                    <th>Contestadas</th>
                    <th>Total Alumnos</th>
                    <th>Porcentaje contestadas</th>
                    <th>Calificación promedio</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let stat of evaluacionStats; let ei = index">
                    <tr *ngFor="let guStat of stat.stats_grupo_usuarios; let gui = index">
                        <td *ngIf="gui == 0" [attr.rowspan]="stat.stats_grupo_usuarios.length">
                            <span class="icon-margin-left"><ogr-icon name="prueba" class="icono"></ogr-icon></span>
                            {{ stat.evaluacion.evaluacion }}
                        </td>
                        <td>{{ guStat.grupo_usuario.nombre }}</td>
                        <td>
                            <span class="icon-margin-left"><fa name="clock-o"></fa></span>
                            {{ guStat.tiempo_promedio }} s
                        </td>
                        <td>{{ guStat.cantidad_contestados }}</td>
                        <td>{{ guStat.grupo_usuario.numero_alumnos }}</td>
                        <td>{{ guStat.porcentaje_contestadas | number: "1.0-1" }}%</td>
                        <td>{{ guStat.calificacion_promedio }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div *ngIf="!showEvaluacionStats">
            <p class="not-found">No se encontraron estadísticas de evaluaciones para esta clase.</p>
        </div>
    </ng-container>
</loading-layout>
