import { Component, OnInit } from "@angular/core"
import { PlanPersonalSesion } from "@puntaje/puntaje/api-services"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./estadisticas-evolutivas-plan.component.html",
    styleUrls: ["./estadisticas-evolutivas-plan.component.scss"]
})
export class EstadisticasEvolutivasPlanComponent implements OnInit {
    planPersonalSesion: PlanPersonalSesion
    idPlanificacion: number

    private sub: Subscription

    constructor(protected titleService: TitleService, public route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.estadisticas_evolutivas_plan")
        this.sub = this.route.params.subscribe(params => {
            this.idPlanificacion = params.id
        })
    }

    planPersonalSesionChange(event) {
        this.planPersonalSesion = event
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
    }
}
