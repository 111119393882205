import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { PlanPersonal } from "@puntaje/puntaje/api-services"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { FiltroEstadisticasPlanPersonalService } from "./filtro-estadistica-planes-personales/filtro_estadisticas_plan_personal.service"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "datos-generales-generador-plan-personal",
    templateUrl: "datos_generales_generador_plan_personal.component.html",
    styleUrls: ["datos_generales_plan_personal.component.scss"]
})
export class DatosGeneralesGeneradorPlanPersonalComponent implements OnInit {
    @Input() planPersonal: PlanPersonal
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    clasificaciones: Clasificacion[]

    constructor(
        protected clasificacionesService: Clasificaciones,
        protected filtroEstadisticasPlanPersonalService: FiltroEstadisticasPlanPersonalService,
        protected router: Router
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()

        let clasificacionIds = this.planPersonal.plan_personal_ciclos.reduce((acc, ppci) => {
            acc = acc.concat(ppci.clasificaciones.split(",").map(c => Number(c)))
            return acc
        }, [])

        let params = {
            clasificacion: {
                id: clasificacionIds
            },
            with_cantidad_preguntas: 1
        }

        this.clasificacionesService.where(params).then((clasificaciones: Clasificacion[]) => {
            this.loadingLayout.ready()
            this.clasificaciones = clasificaciones
        })
    }
}
