import {
    EstadisticasEvolutivasPlanComponent,
    EstadisticasClaseComponent,
    PlanificacionCompartidaComponent,
    PlanificacionPredefinidaComponent,
    ProfesoresPlanificacionesPredefinidasComponent,
    ProfesoresRevisarPlanificacionesComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { HomePlanificacionesComponent } from "./home-planificaciones/home-planificaciones.component"

export const routes: Routes = [
    {
        path: "calendarizacion",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePlanificacionesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones/predefinidas/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanificacionPredefinidaComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones/predefinidas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ProfesoresPlanificacionesPredefinidasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "planificaciones/aplicadas/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanificacionCompartidaComponent,
                data: { disableVerClaseButton: true, disableActualButton: true },
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "planificaciones/aplicadas/:id/estadisticas_clase/:clase_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasClaseComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones/aplicadas",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: ProfesoresRevisarPlanificacionesComponent, canActivate: [TokenGuard, AuthGuard] }
        ]
    },
    {
        path: "planificaciones/aplicadas/:id/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasEvolutivasPlanComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const profesoresPlanificacionesRouting = RouterModule.forChild(routes)
