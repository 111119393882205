import { Component, OnInit, ViewChild } from "@angular/core"
import { Asociaciones, Asociacion } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "top-docentes-asociacion",
    templateUrl: "top-docentes-asociacion.component.html",
    styleUrls: ["top-docentes-asociacion.component.scss"]
})
export class TopDocentesAsociacionComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    fechaInicio: any = {}
    fechaFin: any = {}
    asociaciones = []
    asociacion: Asociacion
    data: any[]

    constructor(protected authService: AuthService, protected asociacionService: Asociaciones) {}

    ngOnInit() {
        let now = new Date()
        this.fechaInicio.jsdate = new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0)
        this.fechaFin.jsdate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 0)

        let params = {
            usuario_id: this.authService.getUserData().id
        }
        this.asociacionService.where(params).then(res => {
            this.asociaciones = res
            this.asociacion = this.asociaciones[0]
            this.getData()
        })
    }

    getData() {
        this.loadingLayout.standby()
        let params = {
            usuario_id: this.authService.getUserData().id,
            fecha_inicial: this.fechaInicio,
            fecha_final: this.fechaFin,
            pais: config.plataforma.pais
        }
        // Ir a buscar los docentes
        if (this.asociacion) {
            this.asociacionService.top_docentes(this.asociacion.id, params).then(res => {
                this.data = res
                this.loadingLayout.ready()
            })
        } else {
            this.loadingLayout.ready()
        }
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicio = fechaInicial
        this.fechaFin = fechaFinal
        if (this.asociacion) this.getData()
    }
}
