import { Component, OnInit, OnDestroy } from "@angular/core"
import { PlanPersonales } from "@puntaje/puntaje/api-services"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-planes-personales-reforzamientos",
    templateUrl: "./planes-personales-reforzamientos.component.html",
    styleUrls: ["./planes-personales-reforzamientos.component.scss"]
})
export class PlanesPersonalesReforzamientosComponent implements OnInit, OnDestroy {
    statsAlumnos: any
    queryParamsSubscription: Subscription
    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected planPersonalesService: PlanPersonales
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.planes_personales_reforzamientos")
        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            this.planPersonalesService
                .alumnoReforzamientoStats(params["evaluacion_id"])
                .then((statsAlumnos: any) => (this.statsAlumnos = statsAlumnos))
        })
    }

    ngOnDestroy() {
        this.queryParamsSubscription.unsubscribe()
    }
}
