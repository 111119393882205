import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"
import {
    Establecimientos,
    GrupoUsuario,
    GrupoUsuarios,
    PlanPersonales,
    Usuario,
    Usuarios
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "filtro-grafico-evolutivo-clase-seguimiento",
    templateUrl: "./filtro-grafico-evolutivo-clase-seguimiento.component.html",
    styleUrls: ["./filtro-grafico-evolutivo-clase-seguimiento.component.scss"]
})
export class FiltroGraficoEvolutivoClaseSeguimientoComponent implements OnInit {
    grupoUsuario: GrupoUsuario
    grupoUsuarios
    usuarios: any = []
    usuario: Usuario

    @Input() idPlanificacion: number
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @Output() enviarCurso: EventEmitter<number> = new EventEmitter()
    @Output() enviarUsuario: EventEmitter<number> = new EventEmitter()

    constructor(
        protected establecimientosService: Establecimientos,
        protected authService: AuthService,
        protected planPersonalesService: PlanPersonales,
        protected usuariosService: Usuarios,
        protected grupoUsuariosService: GrupoUsuarios
    ) {}

    ngOnInit() {
        this.setGrupoUsuariosPlanificacion()
    }

    setGrupoUsuariosPlanificacion() {
        let params = {
            plan_personal_id: this.idPlanificacion
        }
        this.grupoUsuariosService.where(params).then(grupoUsuarios => {
            this.grupoUsuarios = grupoUsuarios
        })
    }

    actualizarCurso() {
        if (!this.obtenerUsuarios) {
            this.enviarCurso.emit(null)
        } else {
            this.enviarCurso.emit(this.grupoUsuario.id)
        }
    }

    actualizarUsuario() {
        this.enviarUsuario.emit(this.usuario.id)
    }

    obtenerUsuarios() {
        let params = {
            grupo_usuario_id: this.grupoUsuario.id
        }

        this.usuariosService.where(params).then((usuarios: Usuario[]) => {
            this.usuarios = usuarios
        })
    }
}
