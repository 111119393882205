import { Asignatura, Asignaturas, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { PlanPersonal, PlanPersonales } from "@puntaje/puntaje/api-services"
import { PaginatorComponent, AuthService } from "@puntaje/shared/core"
import { Component, Input, Output, ViewChild, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { CompartirPlanPersonalProfesorComponent } from "./compartir_plan_personal_profesor.component"
import { ConfigurarClasificacionesPlanPersonalComponent } from "./configurar_clasificaciones_plan_personal.component"

@Component({
    selector: "planes-personales-no-compartidos",
    templateUrl: "planes_personales_no_compartidos.component.html",
    styleUrls: ["planes_personales_no_compartidos.component.scss"]
})
export class PlanesPersonalesNoCompartidosComponent {
    _asignaturaId: number
    _generadorInstrumentos: GeneradorInstrumento[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    planPersonal: PlanPersonal
    @Input() tipoId: number = 1
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @Input() tipoPlanPersonal: string

    //este y el de arriba son distintos porque es del filtro (hay que ver si el otro se puede sacar)
    @Input() planPersonalTipo: string

    @Input()
    set asignaturaId(id: number) {
        this._asignaturaId = id
    }

    get asignaturaId() {
        return this._asignaturaId
    }

    @Input()
    set generadorInstrumentos(gis: GeneradorInstrumento[]) {
        let old = this._generadorInstrumentos
        this._generadorInstrumentos = gis
        old && this.paginator && this.paginator.changePage(1)
    }

    get generadorInstrumentos() {
        return this._generadorInstrumentos
    }

    asignatura: Asignatura

    planesPersonales: PlanPersonal[]

    @Output() planCompartidoEvent = new EventEmitter()

    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    @ViewChild(CompartirPlanPersonalProfesorComponent, { static: true })
    compartirPlanPersonalProfesorComponent: CompartirPlanPersonalProfesorComponent

    constructor(
        protected authService: AuthService,
        protected asignaturasService: Asignaturas,
        protected planPersonalesService: PlanPersonales,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {}

    setEvaluacionTipo(planPersonalTipo) {
        this.planPersonalTipo = planPersonalTipo

        this.paginator.changePage(1)
    }

    setData(page: number, per: number) {
        this.loadingLayout.standby()

        let params = {
            page: page,
            per: per,
            no_compartidos: 1,
            mios: 1,
            plan_personales: {
                usuario_id: this.authService.getUserData().id
            },
            order: "desc",
            sort_by: "created_at",
            render_options: {
                include: {
                    usuario: null,
                    generador_instrumento: null
                }
            }
        }

        if (this.planPersonalTipo) params["tipo_plan_personal"] = { tipo_instrumento: this.planPersonalTipo }
        if (this.generadorInstrumentos)
            params["generador_instrumento_ids"] = this.generadorInstrumentos.map(gi => gi.id)

        //this.cdr.detectChanges(); por alguna razón, al hacer un detectChanges se salta el ngOnInit del compartir.

        return this.planPersonalesService.where(params).then((planesPersonales: PlanPersonal[], total: number) => {
            this.planesPersonales = planesPersonales
            this.loadingLayout.ready()
            return total
        })
    }

    compartirPlanPersonal(planPersonal: PlanPersonal) {
        this.planPersonal = planPersonal
        this.openCompartir()
    }

    openCompartir() {
        this.compartirPlanPersonalProfesorComponent.open()
    }

    onPlanCompartidoListener(value: any) {
        this.planCompartidoEvent.emit(true)
        this.paginator.reload()
    }
}
