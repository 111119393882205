import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, TabService } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    templateUrl: "home_biblioteca.component.html"
})
export class HomeBibliotecaComponent extends MenuComponent {
    config: typeof config = config
    cursos: Clasificacion[]
    menuItems: MenuItemBoxData[] = []
    enableMenu: boolean = false
    usuarioId: number

    constructor(
        protected csService: Clasificaciones,
        protected tabService: TabService,
        protected authService: AuthService,
        protected store: Store<State>,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
        this.usuarioId = this.authService.getUserData().id
    }

    ngOnInit() {
        this.setCursos()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    setCursos() {
        let configCursos = config.evaluaciones["curricular"].cursosMaterial
        let params = {
            clasificacion: {
                clasificacion: configCursos.map((c: any) => c.clasificacion)
            }
        }
        this.csService.where(params).then((clasificaciones: Clasificacion[]) => {
            this.cursos = configCursos.map((c: any) => {
                return clasificaciones.find((cs: any) => cs.clasificacion == c.clasificacion)
            })
            this.setMenuItems()
        })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        let curso = 1
        if (this.cursos && this.cursos.length > 0) curso = this.cursos[0].id
        this.menuItems = [
            {
                route: "/materiales/",
                params: { tipo_instrumento: "ensayo" },
                label: "Material PSU",
                text: "Encuentra materiales, tales como guías y videos, que abordan los contenidos de la PSU. Organizados por asignatura y eje temático.",
                linkText: "Ver material PSU",
                icon: "libro2-o"
            },
            {
                route: "/materiales/",
                params: { tipo_instrumento: "curricular", curso_id: curso },
                label: "Material Curricular",
                text: "Colección de materiales pertinentes al plan curricular. Organizados por asignatura, curso y eje temático.",
                linkText: "Ver material curricular",
                icon: "libro2-o"
            },
            {
                route: "/usuarios/" + this.usuarioId,
                label: "Material Favorito",
                text: "Explora y administra la lista de materiales que has marcado como favoritos.",
                linkText: "Ver materiales favoritos",
                icon: "material-favorito-o",
                action: this.goToFavoritos.bind(this)
            },
            {
                route: "/instrumentos/pruebas_propias/",
                params: { tipo_instrumento: "ensayo", asignatura_id: +asignaturasByEvaluacionTipo["ensayo"][0].id },
                label: "Mis instrumentos PSU",
                text: "Lista de tus pruebas propias tipo curricular que has subido al sistema.",
                linkText: "Ver instrumentos",
                icon: "pdf"
            },
            {
                route: "/instrumentos/pruebas_propias/",
                params: {
                    tipo_instrumento: "curricular",
                    asignatura_id: +asignaturasByEvaluacionTipo["curricular"][0].id
                },
                label: "Mis instrumentos curriculares",
                text: "Lista de tus pruebas propias tipo PSU que has subido al sistema.",
                linkText: "Ver instrumentos",
                icon: "pdf"
            }
        ]

        this.enableMenu = true
    }

    goToFavoritos() {
        if (this.usuarioId != null) {
            this.tabService.setComponentTab("UsuarioComponent", "favoritos")
        }
    }
}
