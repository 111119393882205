<ll-titulo>Generar Plan Personal</ll-titulo>
<menu-asignaturas-horizontal
    [asignaturaId]="asignaturaId"
    [tipoEvaluacion]="tipoInstrumento"
    (asignaturaChanged)="asignaturaId = $event"
></menu-asignaturas-horizontal>
<br />
<div class="row">
    <div class="col-md-12">
        <generar-plan-personal-profesor
            [tipoEvaluacion]="tipoInstrumento"
            [asignaturaId]="asignaturaId"
            (onReadyAsignatura)="onReady($event)"
            [enableBuscador]="true"
        ></generar-plan-personal-profesor>
    </div>
</div>
