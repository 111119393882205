import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subject, Subscription, combineLatest } from "rxjs"
import { ActivatedRoute, Router } from "@angular/router"
import { select, Store } from "@ngrx/store"
import {
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    State,
    SetSelectedAsignaturaWithId
} from "@puntaje/puntaje/store"
import { filter, map } from "rxjs/operators"
import { AuthService } from "@puntaje/shared/core"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-profesores-planificaciones-predefinidas",
    templateUrl: "./profesores-planificaciones-predefinidas.component.html",
    styleUrls: ["./profesores-planificaciones-predefinidas.component.scss"]
})
export class ProfesoresPlanificacionesPredefinidasComponent implements OnInit, OnDestroy {
    asignatura: Asignatura
    asignaturaId: number
    evaluacionTipos = ["curricular"]

    tipoInstrumento: string = "curricular"

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    sub: Subscription
    subQuery: Subscription
    subData: Subscription
    asignaturaId$ = this.asignatura$.pipe(map(asignatura => asignatura.id))

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected store: Store<State>,
        protected authService: AuthService,
        protected router: Router
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.profesores_planificaciones_predefinidas")
        this.subQuery = this.evaluacionTipo$.subscribe(evaluacionTipo => {
            this.tipoInstrumento = evaluacionTipo
        })

        this.subData = this.route.data.subscribe(data => {
            this.evaluacionTipos = [data.evaluacionTipo ?? this.tipoInstrumento]
            this.tipoInstrumento = data.evaluacionTipo ?? this.tipoInstrumento
        })
    }

    ngOnDestroy() {
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
    }
}
