import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Clasificacion } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "clasificaciones-checkboxes-plan-personal",
    templateUrl: "clasificaciones_checkboxes_plan_personal.component.html",
    styleUrls: ["clasificaciones_checkboxes_plan_personal.component.scss"]
})
export class ClasificacionesCheckboxesPlanPersonalComponent implements OnInit {
    @Input() name: string
    @Input() id: string
    @Input() hasHijos: boolean
    @Input() tipoInstrumento: string
    @Input() enableCantidadPreguntas: boolean
    @Input() checkAll: boolean = true

    _clasificaciones: Clasificacion[]

    @Input()
    set clasificaciones(items) {
        this._clasificaciones = items
        this.checkClasificaciones()
    }
    get clasificaciones() {
        return this._clasificaciones
    }

    concats: any

    @Output() emitToParent: EventEmitter<any> = new EventEmitter<any>()

    constructor() {}

    ngOnInit() {
        this.concats = config.evaluaciones[this.tipoInstrumento].clasificaciones.concats
    }

    checkClasificaciones() {
        this.clasificaciones.forEach((c: any) => {
            c.selected = this.checkAll
            if (c.clasificaciones_hijas)
                c.clasificaciones_hijas.forEach(ch => {
                    ch.selected = this.checkAll
                })
        })

        this.clasificacionesEmit()
    }

    clasificacionesEmit() {
        this.emitToParent.emit()
    }

    clasificacionPadreChange(clasificacionPadre: Clasificacion) {
        clasificacionPadre.clasificaciones_hijas.forEach(c => {
            ;(c as any).selected = (clasificacionPadre as any).selected
        })

        this.clasificacionesEmit()
    }

    clasificacionChange(clasificacionPadre: Clasificacion) {
        ;(clasificacionPadre as any).selected = clasificacionPadre.clasificaciones_hijas.reduce(
            (acc, x) => acc || (x as any).selected,
            false
        )

        this.clasificacionesEmit()
    }
}
