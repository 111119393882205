import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostBinding } from "@angular/core"

import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"

/*#################################

Menú arriba del sitio.

#################################*/

@Component({
    templateUrl: "topmenu.component.html",
    selector: "topmenu",
    styleUrls: ["topmenu.component.scss"]
})
export class TopmenuComponent extends MenuComponent {
    @Output() toggleTopmenuEvent = new EventEmitter()
    itemsMenu: any[]
    ITEMS_MENU = [
        {
            name: "Evaluar",
            route: "/ensayos/generar_ensayo",
            icon: "editar-prueba"
        },
        {
            name: "Corregir",
            route: "/evaluacion_instancias/subir",
            icon: "upload"
        },
        {
            name: "Resultados",
            route: "/evaluaciones/resultados",
            icon: "resultados"
        },
        {
            name: "Biblioteca",
            route: "/materiales",
            icon: "libro2-o"
        }
    ]

    @Input() config: any

    constructor(store: Store<State>, protected authService: AuthService, protected router: Router) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        if (this.authService.getEstablecimientos().length != 0) {
            let item = {
                name: "Reportes",
                route: "/estadisticas/resumen_uso",
                icon: "establecimiento-o"
            }
            this.ITEMS_MENU.push(item)
        }
        this.itemsMenu = this.ITEMS_MENU
    }

    close() {
        this.toggleTopmenuEvent.emit()
    }
}
