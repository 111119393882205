<ll-titulo>
    Estadisticas de la clase:
    <span *ngIf="planPersonalSesion">{{ planPersonalSesion.plan_personal_sesion }}</span>
</ll-titulo>
<ng-container *ngIf="route.params | async as params">
    <estadisticas-clase-profesores
        (planPersonalSesionChange)="planPersonalSesionChange($event)"
        [planPersonalSesionId]="+params['clase_id']"
    ></estadisticas-clase-profesores>
</ng-container>
<a href="javascript: history.go(-1)">
    <fa name="arrow-left"></fa>
    Volver atrás
</a>
