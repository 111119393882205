import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { PlanPersonalSesion } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "estadisticas-clase",
    templateUrl: "./estadisticas-clase.component.html",
    styleUrls: ["./estadisticas-clase.component.scss"]
})
export class EstadisticasClaseComponent implements OnInit {
    planPersonalSesion: PlanPersonalSesion

    constructor(protected titleService: TitleService, public route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.estadisticas_clase")
    }

    planPersonalSesionChange(event) {
        this.planPersonalSesion = event
    }
}
