<loading-layout #cargandoPlanificacion>
    <ng-container *ngIf="planificacion$ | async as planificacion">
        <datos-generales-planificacion
            [planPersonal]="planificacion"
            [enableCursos]="true"
        ></datos-generales-planificacion>
        <barra-avance-planificacion
            *ngIf="enableBarraAvance"
            [clases]="planificacion.plan_personal_ciclos[0].plan_personal_sesiones"
        ></barra-avance-planificacion>
        <div transition-group class="clases">
            <!--<div class="clase alert" [ngClass]="{'alert-info': clase.estado == 'activa', 'alert-danger': clase.estado == 'pendiente', 'alert-success': clase.estado == 'completada'}" *ngFor="let clase of planificacion.plan_personal_ciclos[0].plan_personal_sesiones; let i = index ">
        <clase-profesor [clase]="clase" [index]="i" [activa]="true" (activaChange)="updateSesionActiva($event)" (editingChange)="updateEditingSesiones($event)"></clase-profesor>
      </div>-->
            <div
                *ngFor="let clase of planificacion.plan_personal_ciclos[0].plan_personal_sesiones; let i = index"
                transition-group-item
            >
                <clase-profesor
                    [clase]="clase"
                    [disableSeguimiento]="!hasSeguimiento"
                    [index]="i"
                    [activa]="true"
                    [provieneDeCompartida]="true"
                    [enableEstado]="true"
                    [planificacionId]="planificacion.id"
                    [enableGoToEstadisticas]="true"
                    [enableReporte]="enableReporte"
                    [showVerClase]="!disableVerClaseButton"
                    [disableActual]="disableActualButton"
                    (activaChange)="updateSesionActiva($event)"
                    (editingChange)="updateEditingSesiones($event)"
                    (fechasChange)="updateOrdenByFechas()"
                    (toggleActual)="toggleActual($event)"
                    [showVerClase]="showVerClase"
                ></clase-profesor>
            </div>
        </div>
    </ng-container>
</loading-layout>
