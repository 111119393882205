<div class="content">
    <p>
        Los Términos y Condiciones generales que a continuación se establecen regulan el uso de la comunidad de
        <span class="entity-name">{{ companyName }}</span>
        , creada a través del sitio
        <span class="entity-name">{{ companySite }}</span>
        (en adelante
        <span class="entity-name">"{{ companyName }}"</span>
        ), el cual es puesto a disposición de los usuarios de internet.
        <br />
        <br />
        La sola utilización del portal le atribuye la condición de usuario (en adelante, el "usuario") de la comunidad
        de
        <span class="entity-name">{{ companyName }}</span>
        y expresa la aceptación plena y sin reservas de todos y cada uno de sus Términos y Condiciones que se encuentren
        publicados al acceder al portal
        <span class="entity-name">{{ companyName }}</span>
        . En consecuencia, el usuario deberá leer atentamente los Términos y Condiciones en cada una de las ocasiones en
        que se proponga utilizar o acceder al portal
        <span class="entity-name">{{ companyName }}</span>
        .
        <br />
        <br />
        Los Términos y Condiciones podrán ser modificados, total o parcialmente, por
        <span class="entity-name">{{ companyName }}</span>
        , y dichos cambios tendrán vigencia a partir del momento mismo en que sean publicados o insertados en el sitio
        de la comunidad o desde que sean notificados al usuario por cualquier medio. A partir de lo expuesto, se sugiere
        que los visite periódicamente.
        <br />
        <br />
        Las violaciones a los Términos y Condiciones otorgan a
        <span class="entity-name">{{ companyName }}</span>
        el derecho de suspender o terminar la prestación del servicio al usuario que las haya realizado, por acción u
        omisión.
    </p>

    <h3>1° El servicio y la información</h3>
    <h5>1.1.</h5>
    <p>
        Los contenidos que periódicamente se publican en la comunidad de
        <span class="entity-name">{{ companyName }}</span>
        tienen por finalidad brindar al usuario todo tipo de información relacionada con el desarrollo de su educación
        escolar y, fundamentalmente, de la Enseñanza Media, según las regulaciones del
        <strong>Ministerio de Educación de Chile</strong>
        . Por consiguiente,
        <span class="entity-name">{{ companyName }}</span>
        contribuye a preparar de manera satisfactoria la Prueba de Acceso a la Educación Superior (PAES) en el mismo
        país, mediante la generación permanente de pruebas de ensayo, informes estadísticos, material educativo,
        artículos, noticias, planificaciones de clases, publicaciones de interés y otros contenidos relacionados con la
        comunidad escolar en todos sus niveles.
    </p>

    <h5>1.2.</h5>
    <p>
        En ningún caso la información contenida en la comunidad
        <span class="entity-name">{{ companyName }}</span>
        tiene la obligación de satisfacer la totalidad de las necesidades formativas o educativas del usuario. En
        algunas oportunidades, la información contenida en la comunidad
        <span class="entity-name">{{ companyName }}</span>
        simplemente está destinada a complementar otros materiales a los que el usuario pueda acceder por su cuenta,
        acerca de un asunto o materia de su interés.
    </p>

    <h5>1.3.</h5>
    <p>
        La prestación del servicio del sitio por parte de la comunidad
        <span class="entity-name">{{ companyName }}</span>
        es de carácter libre y gratuito para los usuarios. No obstante, su utilización sólo puede hacerse como usuario
        registrado.
    </p>

    <h3>2° Registro</h3>
    <h5>2.1.</h5>
    <p>
        Para ser un usuario de la comunidad es obligatorio completar el formulario de registro ("Registro de usuarios")
        con su información personal de manera precisa y veraz.
    </p>

    <h5>2.2.</h5>
    <p>
        Salvo en los campos en que se indique lo contrario, las respuestas a las preguntas del “Registro de usuarios”
        son voluntarias, sin que la falta de respuesta implique una merma en la calidad o cantidad de los servicios
        correspondientes, a menos que se indique otra cosa. El usuario registrado se compromete a notificar a la
        comunidad de
        <span class="entity-name">{{ companyName }}</span>
        en forma inmediata cualquier uso no autorizado de su clave.
    </p>

    <h5>2.3.</h5>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        se reserva el derecho de rechazar cualquier solicitud de registro o de cancelar un registro previamente
        aceptado.
    </p>

    <h5>2.4.</h5>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        se reserva el derecho de enviar a la dirección de correo indicada por el usuario en el formulario de registro,
        información que considere relevante para sus usuarios. No obstante lo anterior,
        <span class="entity-name">{{ companyName }}</span>
        adhiere a las iniciativas contra el correo no solicitado
        <i>(spamming)</i>
        , en el entendido de que la información enviada a sus usuarios no contiene publicidad comercial e incorpora
        mecanismos sencillos y explícitos para dejar de recibir dichos correos.
    </p>

    <h3>3° Responsabilidad por la información contenida</h3>
    <h5>3.1.</h5>
    <p>
        Debido a que en la actualidad los medios técnicos disponibles no permiten prevenirse totalmente contra la acción
        de terceras personas en los sitios publicados en Internet,
        <span class="entity-name">{{ companyName }}</span>
        de ninguna manera garantiza la exactitud y/o veracidad de la totalidad o parcialidad de la información contenida
        en el sitio, ni su actualización, ni que dicha información no haya sido alterada o modificada en algún grado,
        luego de haber sido incluida en el sitio, ni cualquier otro aspecto o característica de la información brindada
        en el sitio o a través de los links eventualmente incluidos en el mismo.
    </p>

    <h5>3.2.</h5>
    <p>
        Respecto de aquellos programas, materiales o información que se hayan puesto a disposición del usuario para que
        éste pueda descargarlos en su equipo, sistema o cualquier medio o soporte de memoria de su propiedad o cuya
        tenencia detente ("materiales de
        <i>download</i>
        "),
        <span class="entity-name">{{ companyName }}</span>
        informa que inicialmente dichos programas fueron revisados, razonablemente, a efectos de considerarlos libres de
        virus, u otros medios tecnológicos de tipo destructivo.
    </p>

    <h5>3.3.</h5>
    <p>
        Por lo mismo,
        <span class="entity-name">{{ companyName }}</span>
        no será responsable por ningún daño que se derive de cualquier alteración que se haya efectuado a los materiales
        de download provistos directamente por la comunidad
        <span class="entity-name">{{ companyName }}</span>
    </p>

    <h3>4° Privacidad de la Información</h3>
    <h5>4.1.</h5>
    <p>
        La recolección y tratamiento automatizado de los datos personales tiene como finalidad mantener una fluida
        relación entre el usuario y la comunidad
        <span class="entity-name">{{ companyName }}</span>
        , favoreciendo la gestión, administración, prestación, ampliación y mejora de los servicios en los que el
        usuario decida suscribirse, así como la utilización de dichos servicios por parte de los usuarios, el diseño de
        nuevas ofertas relacionadas con dichos servicios, el envío de actualizaciones de los servicios, el envío, por
        medios tradicionales y electrónicos, de información técnica, operativa y comercial acerca de productos y
        servicios ofrecidos por
        <span class="entity-name">{{ companyName }}</span>
        a través de su portal.
    </p>

    <h5>4.1.</h5>
    <p>
        El servicio de
        <span class="entity-name">{{ companyName }}</span>
        automáticamente recaba cierta información general como, por ejemplo, la cantidad y frecuencia de los visitantes
        a la comunidad
        <span class="entity-name">{{ companyName }}</span>
        y a sus distintas áreas. Dicha información se utiliza únicamente con un propósito estadístico. Estos datos
        ayudan a determinar cuántos de los usuarios de la comunidad
        <span class="entity-name">{{ companyName }}</span>
        acceden a un área determinada y qué partes de la comunidad utilizan, a fin de poder mejorarlo y asegurar que sea
        lo más atractivo posible.
    </p>

    <h5>4.2.</h5>
    <p>
        Si decides participar en las encuestas realizadas en el sitio,
        <span class="entity-name">{{ companyName }}</span>
        podrá utilizar tu RUT para acceder a estos datos, los que serán utilizados de manera anónima, en forma agregada,
        confidencial y sólo para objetivos de investigación de
        <span class="entity-name">{{ companyName }}</span>
        o de cualquier entidad a la cual consideremos apropiado cederle esta información. Asimismo, las respuestas de la
        encuesta se podrán unir a datos recolectados por el
        <strong>Ministerio de Educación</strong>
        . La decisión de participar en las encuestas es completamente voluntaria y no existen riesgos asociados a ella.
        Si decides no contestar la encuesta no habrá ninguna consecuencia negativa.
    </p>

    <h5>4.3.</h5>
    <p>
        Las campañas generadas por terceros, puede facultar el uso de datos personales de quienes participen de éstas.
    </p>

    <h3>5° Datos oficiales PAES</h3>
    <h5>5.1.</h5>
    <p>
        El usuario autoriza a
        <span class="entity-name">{{ companyName }}</span>
        para utilizar toda la información oficial de la Prueba de Acceso a la Educación Superior (PAES) (puntajes en las
        distintas pruebas, datos personales, etc.) con el fin de llevar a cabo investigaciones. Esta información no se
        divulgará públicamente, pero los informes resultantes de dichas investigaciones podrán ser difundidos y
        publicados.
    </p>

    <h3>6° Privacidad en Facebook</h3>
    <h5>6.1.</h5>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        no transmitirá, comercializará, ni publicará la información de los usuarios obtenida a través de
        <i>Facebook</i>
        . Esta información es utilizada de manera interna para la autentificación del sitio mediante la cuenta de
        <i>Facebook</i>
        . Para lo anterior solo se guardará el
        <i>ID</i>
        del usuario de
        <i>Facebook</i>
        , de manera de poder asociarlo a la cuenta de
        <span class="entity-name">{{ companyName }}</span>
        permitiendo la autentificación del sitio.
        <br />
        <br />
        Además se utilizará, en caso de que el usuario lo requiera, el
        <i>ID</i>
        para publicar en el muro del usuario el resultado de un ensayo realizado.
    </p>

    <h3>7° Propiedad Intelectual</h3>
    <h5>7.1.</h5>
    <p>
        Se entenderá por "contenidos" todas las informaciones, mensajes, gráficos, dibujos, diseños, logotipos, nombres,
        marcas, archivos de sonido y/o imagen, fotografías, grabaciones, software y, en general, cualquier clase de
        material accesible a través de la comunidad
        <span class="entity-name">{{ companyName }}</span>
        (en adelante referido como los “contenidos”).
        <br />
        <br />
        El usuario se obliga a:
    </p>
    <ul>
        <li>Usar los contenidos de forma diligente, correcta y lícita.</li>
        <li>
            No reproducir, copiar, distribuir, transformar o modificar, poner a disposición, arrendar o comunicar al
            público los contenidos a menos que se cuente con la autorización expresa del titular de los correspondientes
            derechos.
        </li>
        <li>
            No suprimir, eludir, o manipular el copyright y demás datos identificatorios de los derechos de la
            <span class="entity-name">{{ companyName }}</span>
            o de sus titulares incorporados a los contenidos, así como los dispositivos técnicos de protección, los
            identificadores digitales o cualesquiera sean los mecanismos de información que pudieran contener los
            contenidos o la gestión de derechos.
        </li>
        <li>
            No emplear los contenidos y, en particular, la información de cualquier otra clase obtenida a través de la
            comunidad
            <span class="entity-name">{{ companyName }}</span>
            o de los servicios, para emitir publicidad de cualquier tipo bajo cualquier formato, en Chile o en el
            extranjero.
        </li>
    </ul>

    <h5>7.2.</h5>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        no será responsable por el uso indebido que hagan los usuarios del contenido de la comunidad
        <span class="entity-name">{{ companyName }}.</span>
    </p>

    <h5>7.3.</h5>
    <p>
        El usuario acepta que la publicidad de terceros que aparezca en la pantalla es parte inseparable de la comunidad
        <span class="entity-name">{{ companyName }}.</span>
    </p>

    <h5>7.4.</h5>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        no garantiza la veracidad de dicha publicidad y no será responsable por la correspondencia ni por los contratos
        que el usuario celebre con terceros.
    </p>

    <h3>8° Condiciones sorteo Ensayo de la Prueba de Transición 2021 Universidad Autónoma de Chile.</h3>

    <p>
        Podrán participar en este sorteo, todos aquellos que se inscriban en el formulario de inscripción para el Ensayo
        de la prueba de Transición 2021 de Universidad Autónoma de Chile, en el link
        https://ensayonline.uautonoma.cl/landing), desde el día 6 de Agosto del 2020 hasta 6 de diciembre de 2020 y que
        hayan realizado a la fecha del sorteo, al menos uno de los Ensayos programados. Los participantes deberán
        completar con datos verídicos y correctos el formulario de inscripción
    </p>

    <h5>8.1. Premios</h5>

    <ul>
        <li>Cuatro Scooters eléctricos Scooter Eléctrico 350W Radost</li>
        <li>Los premios no son transferibles.</li>
        <li>Cada participante inscrito puede ganar solo un premio.</li>
    </ul>

    <h5>8.2. Sorteo.</h5>

    <ul>
        <li>Se realizarán dos sorteos, uno el día 10 de noviembre y el otro el día 10 de diciembre.</li>
        <li>En cada sorteo se rifará un scooter eléctrico y un computador portátil.</li>
        <li>Ambos sorteos se realizarán en la plataforma https://www.sortea2.com.</li>
        <li>Habrá dos ganadores en cada sorteo, cada uno ganará un scooter eléctrico Scooter Eléctrico 350W Radost</li>
        <li>
            Los ganadores se darán a conocer a través de publicaciones en los perfiles corporativos de la Universidad en
            redes sociales, además de contactar directamente a cada ganador.
        </li>
    </ul>

    <h5>8.3. Despacho del premio.</h5>

    <ul>
        <li>El premio se enviará al domicilio del ganador sin costo, dentro del territorio nacional.</li>
        <li>Los premios no son transferibles.</li>
        <li>
            Se enviará a través de una empresa de transporte/delivery dentro de los 60 días hábiles posteriores al
            sorteo.
        </li>
    </ul>
</div>
