<ll-titulo>Revisar planificaciones aplicadas</ll-titulo>
<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas [evaluacionTipos]="evaluacionTipos"></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>
<ng-container *ngIf="generadorInstrumentos">
    <profesores-planificaciones-compartidas
        [asignaturaId]="asignaturaId"
        [evaluacionTipo]="tipoInstrumento"
        [showEstadistica]="tipoInstrumento == 'ensayo' || tipoInstrumento == 'prueba de transición'"
        [generadorInstrumentos]="generadorInstrumentos"
    ></profesores-planificaciones-compartidas>
</ng-container>
