<loading-layout #loadingLayout>
    <div class="clase-wrap" [class.with-checkbox]="enableToggleActiva">
        <div *ngIf="!loadingLayout.loading" class="clase" [class.with-checkbox]="enableToggleActiva">
            <div class="activar-checkbox-container" *ngIf="enableToggleActiva">
                <label class="activar-checkbox">
                    <input type="checkbox" [(ngModel)]="activa" (change)="updateSesionesActivas()" />
                    <fa name="check" class="icono"></fa>
                </label>
            </div>
            <div class="titulo-wrap">
                <h3 class="label-titulo">{{ sesionAlias | capitalize }} {{ index + 1 }}:</h3>
                <div class="titulo-container">
                    <p class="nombre-editable" *ngIf="!editingSesionFields.nom">
                        {{ clase.plan_personal_sesion }}
                        <a class="edit-pencil" (click)="edit('nom')"><fa name="pencil"></fa></a>
                    </p>
                    <div *ngIf="editingSesionFields.nom" class="edit-titulo-container">
                        <input class="form-control" type="text" [(ngModel)]="clase.plan_personal_sesion" />
                        <div class="clearfix">
                            <cui-button-link class="pull-right btn_style btn-edit" (click)="saveEdit('nom')">
                                Editar&nbsp;
                                <fa name="pencil"></fa>
                            </cui-button-link>
                            <cui-button-link
                                buttonType="cancel"
                                class="pull-right btn_style_2 btn-edit"
                                (click)="cancelEdit('nom')"
                            >
                                Cancelar
                            </cui-button-link>
                        </div>
                    </div>
                </div>
                <div class="titulo-top-right">
                    <a
                        *ngIf="enableGoToEstadisticas"
                        [routerLink]="['/planificaciones/aplicadas', planificacionId, 'estadisticas_clase', clase.id]"
                        class="link-estadisticas"
                    >
                        <fa name="bar-chart"></fa>
                        &nbsp;Ver estadísticas
                    </a>
                    <span class="separator" *ngIf="enableGoToEstadisticas && enableEstado"></span>
                    <div
                        class="estado"
                        *ngIf="enableEstado"
                        [ngClass]="{
                            activa: clase.estado == 'activa',
                            pendiente: clase.estado == 'pendiente',
                            completada: clase.estado == 'completada'
                        }"
                    >
                        <fa name="calendar" class="status-icon" *ngIf="clase.estado == 'pendiente'"></fa>
                        <ogr-icon name="clock-start" class="status-icon" *ngIf="clase.estado == 'activa'"></ogr-icon>
                        <fa name="check" class="status-icon" *ngIf="clase.estado == 'completada'"></fa>
                        <span>{{ clase.estado }}</span>
                    </div>
                    <span class="separator" *ngIf="enableEstado"></span>
                    <span class="label label-success" *ngIf="clase.actual">Clase actual</span>
                    <span class="separator" *ngIf="clase.actual"></span>
                    <button class="btn btn-primary" *ngIf="!disableActual" (click)="onToggleActual()">
                        {{ clase.actual ? "Deseleccionar" : "Seleccionar" }} como clase actual
                    </button>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-6">
                    <p *ngIf="!disableSeguimiento">
                        <label>Seguimiento:</label>
                        <ng-container *ngIf="provieneDeCompartida">
                            <span *ngIf="clase.seguimiento" class="seguimiento check">
                                <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                                activo
                            </span>
                            <span *ngIf="!clase.seguimiento" class="seguimiento cross">
                                <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                                no activo
                            </span>
                        </ng-container>

                        <label class="seguimiento-checkbox" *ngIf="!provieneDeCompartida">
                            <input type="checkbox" [(ngModel)]="clase.seguimiento" />
                            <fa name="check" class="icono"></fa>
                            {{ clase.seguimiento ? "activo" : "no activo" }}
                        </label>
                    </p>
                    <p>
                        <label>Clasificaciones:</label>
                        <span class="clasificacion" *ngFor="let clasificacion of clase.nebu_clasificaciones">
                            {{ clasificacion.clasificacion }}
                        </span>
                    </p>
                </div>
                <div class="col-sm-6">
                    <div class="fechas-container">
                        <div class="fechas">
                            <p>
                                <ogr-icon class="clock clock-start" name="clock-start"></ogr-icon>
                                <label>Fecha de publicación:</label>
                                <ng-container *ngIf="!editingSesionFields.fecha">
                                    {{ clase.fecha_inicial | date: "dd/MM/yyyy HH:mm" }}
                                </ng-container>

                                <ng-container *ngIf="editingSesionFields.fecha">
                                    <datepicker-form
                                        [(ngModel)]="clase.fecha_inicial"
                                        (ngModelChange)="checkDateRange(clase)"
                                        [asISOString]="true"
                                    ></datepicker-form>
                                </ng-container>
                            </p>
                            <p>
                                <ogr-icon class="clock clock-stop" name="clock-stop"></ogr-icon>
                                <label>Fecha de cierre:</label>
                                <ng-container *ngIf="!editingSesionFields.fecha">
                                    {{ clase.fecha_final | date: "dd/MM/yyyy HH:mm" }}
                                </ng-container>

                                <ng-container *ngIf="editingSesionFields.fecha">
                                    <datepicker-form
                                        [(ngModel)]="clase.fecha_final"
                                        (ngModelChange)="checkDateRange(clase)"
                                        [asISOString]="true"
                                    ></datepicker-form>
                                </ng-container>
                            </p>
                            <p *ngIf="hasEvaluaciones">
                                <ogr-icon class="clock" name="clock-evaluacion"></ogr-icon>
                                <label>Fecha publicación pauta:</label>

                                <ng-container *ngIf="!editingSesionFields.fecha">
                                    {{ clase.fecha_pauta | date: "dd/MM/yyyy HH:mm" }}
                                </ng-container>

                                <ng-container *ngIf="editingSesionFields.fecha">
                                    <datepicker-form
                                        [(ngModel)]="clase.fecha_pauta"
                                        [asISOString]="true"
                                    ></datepicker-form>
                                </ng-container>
                            </p>
                            <p *ngIf="editingSesionFields.dateRangeError" class="error-warning">
                                <fa name="times" class="warning-icon"></fa>
                                La fecha de publicación debe ser menor o igual a la fecha de cierre.
                            </p>
                            <ng-container *ngIf="editingSesionFields.fecha">
                                <cui-button
                                    class="btn-primary pull-right btn_style btn-edit"
                                    (click)="saveEdit('fecha'); fechasChange.emit()"
                                >
                                    <fa name="pencil"></fa>
                                    &nbsp;Editar
                                </cui-button>
                                <cui-button
                                    buttonType="cancel"
                                    class="btn-primary pull-right btn_style_2 btn-edit"
                                    (click)="cancelEdit('fecha')"
                                >
                                    Cancelar
                                </cui-button>
                            </ng-container>
                        </div>
                        <div class="editar-container">
                            <cui-button
                                *ngIf="!editingSesionFields.fecha"
                                [isBlock]="true"
                                class="btn-primary pull-right btn_style"
                                (click)="edit('fecha')"
                            >
                                <fa name="clock-o"></fa>
                                &nbsp;Editar fechas
                            </cui-button>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-6 with-separator">
                    <ng-container *ngFor="let contenido of clase.plan_personal_contenidos; let indexContenido = index">
                        <div class="contenido" *ngIf="contenido.tipo == 'Material'">
                            <ogr-icon name="guia" class="icono"></ogr-icon>
                            <a (click)="openMaterialPreview(contenido.material)" class="nombre-contenido">
                                {{ contenido.tipo }}: {{ contenido.material.material }}
                            </a>
                            <input
                                type="checkbox"
                                class="checkbox-contenido"
                                [(ngModel)]="selectedRecursos[indexContenido].selected"
                            />
                        </div>
                    </ng-container>
                </div>
                <div class="col-sm-6">
                    <ng-container *ngFor="let contenido of clase.plan_personal_contenidos; let indexContenido = index">
                        <div class="contenido" *ngIf="contenido.tipo == 'Evaluacion'">
                            <ogr-icon name="prueba" class="icono"></ogr-icon>
                            <div>
                                <a
                                    class="nombre-contenido"
                                    (click)="openEvalPreview(evaluacionesId['contenido'][indexContenido])"
                                >
                                    Evaluación{{
                                        contenido.evaluacion.evaluacion
                                            ? ": " + contenido.evaluacion.evaluacion
                                            : " #" + evaluacionesId["contenido"][indexContenido]
                                    }}
                                </a>
                                <p class="tipo-contenido">
                                    <label>Tipo:</label>
                                    {{ contenido.evaluacion.presencial ? "presencial" : "online" }}
                                </p>
                            </div>
                            <input
                                type="checkbox"
                                class="checkbox-contenido"
                                [(ngModel)]="selectedRecursos[indexContenido].selected"
                            />
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row" *ngIf="showVerClase">
                <div class="col-sm-12">
                    <cui-button-link class="btn_style pull-right" (click)="verClase()">Ver Clase</cui-button-link>
                </div>
            </div>
        </div>
        <evaluacion-preview-modal
            *ngIf="modalEvaluacionId"
            [modalId]="'evaluacion'"
            [evaluacionId]="modalEvaluacionId"
            (closeCrossCallback)="closeEvalPreview()"
            [enableReporte]="enableReporte"
            [enableImprimir]="false"
        ></evaluacion-preview-modal>
        <material-preview-modal
            *ngIf="materialOpen"
            [modalId]="'material'"
            (closeCrossCallback)="closeMaterialPreview()"
        ></material-preview-modal>
    </div>
</loading-layout>
