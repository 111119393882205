import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { PlanPersonal, PlanPersonales, PlanPersonalSesion, PlanPersonalSesiones } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Subject, combineLatest, Observable, from, BehaviorSubject, Subscription } from "rxjs"
import { map, startWith } from "rxjs/operators"

@Component({
    selector: "planificacion-compartida-profesores",
    templateUrl: "./planificacion-compartida-profesores.component.html",
    styleUrls: ["./planificacion-compartida-profesores.component.scss"]
})
export class PlanificacionCompartidaProfesoresComponent implements OnInit {
    @Input() planificacionId: number
    @Input() showVerClase: boolean = true
    @Input() enableReporte: boolean = true
    @Input() disableVerClaseButton: boolean = false
    @Input() disableActualButton: boolean = false
    nombreGrupo = config.plataforma.grupoUsuarioAlias
    planificacion$: Observable<PlanPersonal>
    hasSeguimiento: boolean
    @ViewChild("cargandoPlanificacion", { static: true }) cargandoPlanificacion: LoadingLayoutComponent
    fechasSubject = new Subject()
    sub: Subscription
    @Input() enableBarraAvance: boolean = false

    constructor(
        protected planPersonalSesionService: PlanPersonalSesiones,
        protected planPersonalService: PlanPersonales
    ) {}

    ngOnInit() {
        this.cargandoPlanificacion.standby()

        this.planificacion$ = combineLatest(
            this.planPersonalService.find(this.planificacionId, this.planificacionParams()),
            this.fechasSubject.pipe(startWith(null))
        ).pipe(
            map(([planificacion, _]) => {
                planificacion.plan_personal_ciclos[0].plan_personal_sesiones =
                    planificacion.plan_personal_ciclos[0].plan_personal_sesiones.sort((pps1, pps2) => {
                        const fechaInicial1 = new Date(pps1.fecha_inicial)
                        const fechaInicial2 = new Date(pps2.fecha_inicial)

                        return fechaInicial1.getTime() - fechaInicial2.getTime()
                    })
                this.hasSeguimiento =
                    planificacion.generador_instrumento.tipo_instrumento.tipo_instrumento == "ensayo" ||
                    planificacion.generador_instrumento.tipo_instrumento.tipo_instrumento == "prueba de transición"
                this.cargandoPlanificacion.ready()
                return planificacion
            })
        )
    }

    planificacionParams() {
        return {
            render_options: {
                methods: ["generador_instrumento"],
                include: {
                    plan_personal_usuarios: { include: "receptor" },
                    plan_personal_ciclos: {
                        include: {
                            plan_personal_sesiones: {
                                include: {
                                    plan_personal_contenidos: { include: ["material", "evaluacion"] }
                                },
                                methods: ["estado", "nebu_clasificaciones"]
                            }
                        }
                    }
                }
            }
        }
    }

    updateEditingSesiones(event) {
        let dataRangeError = event.fields.dateRangeError
        let clase = event.clase

        if (!dataRangeError) {
            this.planPersonalSesionService.update(clase.id, clase).then((c: PlanPersonalSesion) => {
                console.log("Clase actualizada")
            })
        }
    }

    updateOrdenByFechas() {
        this.fechasSubject.next()
    }

    toggleActual(clase: PlanPersonalSesion) {
        if (clase.actual) {
            clase.actual = false
            this.planPersonalSesionService.update(clase.id, clase).then(_ => {})
        } else {
            if (this.sub) this.sub.unsubscribe()
            this.sub = this.planificacion$.subscribe(planificacion => {
                planificacion.plan_personal_ciclos[0].plan_personal_sesiones.forEach(clase => (clase.actual = false))
                clase.actual = true
                this.planPersonalSesionService.setActual(clase.id).then(_ => {})
            })
        }
    }

    updateSesionActiva(obj) {}
}
