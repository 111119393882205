import { Component, Input, ViewChild, OnInit, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura, Asignaturas, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { PaginatorComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import {
    PlanPersonal,
    PlanPersonales,
    Establecimiento,
    Establecimientos,
    GrupoUsuario,
    Instrumento,
    Instrumentos,
    Usuario
} from "@puntaje/puntaje/api-services"

import { FiltroEstadisticasService } from "@puntaje/puntaje/new-modules/estadisticas"
import { CompartirPlanPersonalProfesorComponent } from "./compartir_plan_personal_profesor.component"

@Component({
    selector: "planes-personales-compartidos",
    templateUrl: "planes_personales_compartidos.component.html",
    styleUrls: ["planes_personales_compartidos.component.scss"]
})
export class PlanesPersonalesCompartidosComponent implements OnInit {
    _asignaturaId: number
    _generadorInstrumentos: GeneradorInstrumento[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    instrumento: Instrumento
    planPersonalToEdit: PlanPersonal
    @Input() tipoId: number = 1
    @Input() rutaPlanPersonales: string
    openModal: EventEmitter<any> = new EventEmitter<any>()
    openModalEdit: EventEmitter<any> = new EventEmitter<any>()
    planesPersonalesString: string = "PlanPersonales"
    planesPersonalesMinusculaPluralString: string = "planes personales"
    planesPersonalesGeneroString: string = "os"
    nombreGrupo: string
    planPersonal: PlanPersonal
    fechaInicial: Date
    fechaFinal: Date

    @Input() planPersonalTipo: string

    @Input()
    set asignaturaId(id: number) {
        this._asignaturaId = id
    }

    get asignaturaId() {
        return this._asignaturaId
    }

    @Input()
    set generadorInstrumentos(gis: GeneradorInstrumento[]) {
        let old = this._generadorInstrumentos
        this._generadorInstrumentos = gis
        old && this.paginator && this.paginator.changePage(1)
    }

    get generadorInstrumentos() {
        return this._generadorInstrumentos
    }

    establecimiento: Establecimiento
    grupoUsuario: GrupoUsuario

    asignatura: Asignatura
    planesPersonales: PlanPersonal[]
    establecimientos: Establecimiento[]
    propietarios: Usuario[]
    propietario: Usuario

    presencial: number = 0
    tipo: string

    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    @ViewChild(CompartirPlanPersonalProfesorComponent, { static: true })
    compartirPlanPersonalProfesorComponent: CompartirPlanPersonalProfesorComponent

    constructor(
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected router: Router,
        protected asignaturasService: Asignaturas,
        protected planPersonalesService: PlanPersonales,
        protected establecimientosService: Establecimientos,
        protected instrumentosService: Instrumentos,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.planPersonalTipo) {
            this.planesPersonalesString = "Planes Personales"
            this.planesPersonalesMinusculaPluralString = "planes personales"
            this.planesPersonalesGeneroString = "os"
        }
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias || "cursos"
        if (!this.establecimientos) {
            this.loadingLayout.standby()
        }
    }

    setEstablecimientos() {
        let params = {
            mios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            establecimiento: { activo: 1 },
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            if (this.establecimientos.length == 1) {
                this.establecimiento = this.establecimientos[0]
                this.setPropietarios()
            } else if (this.establecimientos.length == 0) {
                this.loadingLayout.ready()
            }
        })
    }

    setPlanPersonalTipo(planPersonalTipo) {
        this.planPersonalTipo = planPersonalTipo

        this.paginator.changePage(1)
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicial = fechaInicial
        this.fechaFinal = fechaFinal

        this.setEstablecimientos()
    }

    setData = (page: number, per: number) => {
        this.loadingLayout.standby()

        let params = {
            page: page,
            per: per,
            mis_colegios: 1,
            with_instrumento: 1,
            plan_personal_usuario: {
                receptor_type: "GrupoUsuario"
            },
            plan_personal_tipo: {
                tipo: "tarea"
            },
            order: "desc",
            sort_by: "created_at",
            render_options: {
                include: {
                    usuario: null,
                    generador_instrumento: null,
                    plan_personal_usuarios: {
                        include: {
                            grupo_usuario: {
                                include: "establecimiento"
                            }
                        }
                    }
                }
            }
        }

        // parametros definidos en filtro en html
        this.establecimiento && (params["establecimiento"] = { id: this.establecimiento.id })
        this.grupoUsuario && (params["grupo_usuario"] = { id: this.grupoUsuario.id })
        this.propietario && (params["plan_personal_usuario"]["usuario_id"] = this.propietario.id)
        if (this.generadorInstrumentos)
            params["generador_instrumento_ids"] = this.generadorInstrumentos.map(gi => gi.id)

        this.cdr.detectChanges()
        return this.planPersonalesService.where(params).then((planesPersonales: PlanPersonal[], total: number) => {
            this.planesPersonales = planesPersonales
            this.loadingLayout.ready()
            return total
        })
    }

    onVerEstadisticas(establecimientoId, grupoUsuarioId, planPersonalId, generadorInstrumentoId, tipoInstrumento) {
        this.filtroEstadisticasService.setFiltrosId(
            establecimientoId,
            grupoUsuarioId,
            null,
            planPersonalId,
            generadorInstrumentoId,
            tipoInstrumento,
            this.asignaturaId
        )
        this.router.navigate(["/estadisticas"], {
            queryParams: { tipo_instrumento: tipoInstrumento, asignatura_id: this.asignaturaId }
        })
    }

    compartirPlanPersonal(planPersonal: PlanPersonal) {
        this.planPersonal = planPersonal
        this.openCompartir()
    }

    openCompartir() {
        this.compartirPlanPersonalProfesorComponent.open()
    }

    onPlanCompartidoListener(value: any) {
        this.paginator.reload()
    }

    editarPlanPersonal(planPersonal: PlanPersonal) {
        this.planPersonalToEdit = planPersonal
        this.openModalEdit.emit()
    }

    setPropietarios() {
        this.propietarios = []
        this.propietario = undefined
        if (this.establecimiento) {
            if (this.grupoUsuario) {
                this.propietarios = this.grupoUsuario.propietarios
            } else {
                this.establecimiento.grupo_usuarios.forEach(g => {
                    g.propietarios.forEach(p => {
                        let prop = this.propietarios.find(pr => pr.id == p.id)
                        if (!prop) {
                            this.propietarios.push(p)
                        }
                    })
                })
            }
        }
    }
}
