<div class="filter-container" *ngIf="grupoUsuarios?.length != 0">
    <h4 class="filter-title">
        <span class="glyphicon glyphicon-search icono"></span>
        Filtros de búsqueda
    </h4>
    <div class="filter-wrap apart">
        <div class="filter">
            <select
                [(ngModel)]="grupoUsuario"
                (change)="actualizarCurso()"
                (change)="obtenerUsuarios()"
                class="form-control select-controller"
            >
                <option [ngValue]="undefined">-- Seleccione un Curso --</option>
                <option *ngFor="let g of grupoUsuarios" [ngValue]="g">{{ g.nombre }}</option>
            </select>
        </div>
        <div class="filter" *ngIf="grupoUsuario">
            <select [(ngModel)]="usuario" class="form-control select-controller" (change)="actualizarUsuario()">
                <option [ngValue]="undefined">-- Seleccione alumno --</option>
                <option *ngFor="let u of usuarios" [ngValue]="u">{{ u.nombreCompleto() }}</option>
            </select>
        </div>
    </div>
</div>
