import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription, Observable } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { map, first } from "rxjs/operators"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./planificacion-compartida.component.html",
    styleUrls: ["./planificacion-compartida.component.scss"]
})
export class PlanificacionCompartidaComponent implements OnInit, OnDestroy {
    subData: Subscription
    disableVerClaseButton: boolean = false
    disableActualButton: boolean = false

    constructor(protected titleService: TitleService, public route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.planificaciones.compartida")
        this.subData = this.route.data.subscribe(data => {
            this.disableVerClaseButton = data.disableVerClaseButton ?? this.disableVerClaseButton
            this.disableActualButton = data.disableActualButton ?? this.disableActualButton
        })
    }

    ngOnDestroy() {
        this.subData.unsubscribe()
    }
}
