import { PuntajeEstadisticasModule, PuntajePlanificacionesModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { HomePlanificacionesComponent } from "./home-planificaciones/home-planificaciones.component"
import { profesoresPlanificacionesRouting } from "./profesores_planificaciones.routing"
import { ProfesoresLayoutsModule } from "../layouts"

import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PlanificacionesModule } from "@puntaje/puntaje/new-modules/planificaciones"

@NgModule({
    declarations: [HomePlanificacionesComponent],
    imports: [
        CommonModule,
        Angular2FontawesomeModule,
        profesoresPlanificacionesRouting,
        ProfesoresLayoutsModule,
        PlanificacionesModule,
        SharedModule,
        CUILayoutsModule,
        PuntajeEstadisticasModule,
        PuntajePlanificacionesModule
    ],
    exports: [],
    providers: []
})
export class ProfesoresPlanificacionesModule {}
