<ll-titulo>Revisar planes personales creados</ll-titulo>
<ll-subtitulo>{{ asignatura?.asignatura }}</ll-subtitulo>
<menu-asignaturas-horizontal
    [asignaturaId]="asignaturaId"
    [tipoEvaluacion]="tipoInstrumento"
    [enableResponsive]="true"
    (asignaturaChanged)="onAsignaturaIdListener($event)"
></menu-asignaturas-horizontal>
<br />
<historial-planes-personales-profesor
    [asignaturaId]="asignaturaId"
    [tipoInstrumento]="tipoInstrumento"
    [generadorInstrumentos]="generadorInstrumentos"
></historial-planes-personales-profesor>
