<div *ngFor="let plan of stats">
    <h3>{{ plan.nombre }}</h3>
    <div class="tarea-stats" *ngIf="plan.usuarios.length > 0">
        <table class="table table-bordered table-stripped">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Rut</th>
                    <th>Omitidas</th>
                    <th>Correctas</th>
                    <th>Incorrectas</th>
                    <th>Promedio</th>
                    <th>Tiempo</th>
                    <th>Avance</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let usuario of plan.usuarios">
                    <td>{{ usuario.nombre }}</td>
                    <td>{{ usuario.apellido_paterno }}</td>
                    <td>{{ usuario.pais_identificador }}</td>
                    <td>{{ usuario.omitidas }}</td>
                    <td>{{ usuario.correctas }}</td>
                    <td>{{ usuario.incorrectas }}</td>
                    <td>{{ usuario.promedio }}%</td>
                    <td>{{ usuario.tiempo }}</td>
                    <td>{{ usuario.avance }} / {{ usuario.total_ciclos }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
