import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./subir-prueba-propia.component.html",
    styleUrls: ["./subir-prueba-propia.component.scss"]
})
export class SubirPruebaPropiaComponent implements OnInit {
    sub: Subscription
    evaluacionTipo: string

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.instrumentos.subir_prueba_propia")
        this.sub = this.route.queryParams.subscribe(params => {
            this.evaluacionTipo = params["tipo_instrumento"] || config.plataforma.evaluacionDefault
        })
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub.unsubscribe()
    }
}
