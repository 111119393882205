<cui-section-headline>Planificaciones {{ evaluacionTipo | capitalize }}</cui-section-headline>
<loading-layout #loadingLayout>
    <div class="filter-container" *ngIf="establecimientos?.length != 0">
        <h4 class="filter-title">
            <span class="glyphicon glyphicon-search icono"></span>
            Filtros de búsqueda
        </h4>
        <div class="filter-wrap apart">
            <div class="filter">
                <select
                    [disabled]="establecimientos && establecimientos.length == 1"
                    [(ngModel)]="establecimiento"
                    (change)="paginator.changePage(1)"
                    (change)="setPropietarios()"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- Seleccione un establecimiento --</option>
                    <option *ngFor="let e of establecimientos" [ngValue]="e">{{ e.establecimiento }}</option>
                </select>
            </div>
            <div class="filter">
                <select
                    *ngIf="establecimiento"
                    [(ngModel)]="grupoUsuario"
                    (change)="paginator.changePage(1)"
                    (change)="setPropietarios()"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- Seleccione un curso --</option>
                    <option *ngFor="let gu of establecimiento.grupo_usuarios" [ngValue]="gu">{{ gu.nombre }}</option>
                </select>
            </div>
            <div class="filter">
                <select
                    *ngIf="establecimiento"
                    [(ngModel)]="propietario"
                    (change)="paginator.changePage(1)"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- Seleccione docente --</option>
                    <option *ngFor="let p of propietarios" [ngValue]="p">{{ p.nombreCompleto() }}</option>
                </select>
            </div>
            <div class="filter">
                <filtro-periodo class="periodo" (select)="onSelectPeriodo($event)"></filtro-periodo>
            </div>
        </div>
    </div>
    <table class="table tabla_planes_personales" *ngIf="planificaciones && planificaciones.length > 0">
        <thead>
            <tr>
                <th>Planificaciones</th>
                <th class="actions">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let planPersonal of planificaciones; let i = index">
                <td>
                    <div class="table-cell-plan-personal row">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <a [routerLink]="['/planificaciones/aplicadas', planPersonal.id]" class="titulo">
                                        <ogr-icon name="planificacion-o" class="icono"></ogr-icon>
                                        {{ planPersonal.plan_personal }}
                                    </a>
                                    <div class="plan-personal-data-pair">
                                        <p>
                                            <span class="inline-label">Planificacion ID:</span>
                                            #{{ planPersonal.id }}
                                        </p>
                                    </div>
                                    <div class="plan-personal-data-pair">
                                        <p>
                                            <span class="inline-label">Tipo:</span>
                                            {{ planPersonal.generador_instrumento.generador_instrumento }}
                                        </p>
                                    </div>
                                    <div class="plan-personal-data-pair">
                                        <p>
                                            <span class="inline-label">Clases:</span>
                                            {{ planPersonal.total_sesiones }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div
                                        class="plan-personal-data-pair"
                                        *ngIf="establecimientos && establecimientos.length > 1"
                                    >
                                        <p>
                                            <span class="inline-label">Establecimiento:</span>
                                            {{
                                                planPersonal.plan_personal_usuarios[0].grupo_usuario.establecimiento
                                                    .establecimiento
                                            }}
                                        </p>
                                    </div>
                                    <div class="plan-personal-data-pair">
                                        <p>
                                            <span class="inline-label">Aplicada por:</span>
                                            <fa name="user" class="icono"></fa>
                                            {{ planPersonal.usuario?.nombreCompleto() }}
                                        </p>
                                    </div>
                                    <div class="plan-personal-data-pair">
                                        <p>
                                            <span class="inline-label date-label">Aplicada el:</span>
                                            <span class="glyphicon glyphicon-time icono tiempo"></span>
                                            {{ planPersonal.created_at | date: "dd-MM-yyyy HH:mm" }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <div class="plan-personal-data-pair">
                                        <p><span class="inline-label">Aplicada a:</span></p>
                                    </div>
                                    <div toggleExpandable="eval_comp_curs_{{ i }}" class="tog_expand_cursos clearfix">
                                        {{ nombreGrupo | pluralize | titlecase }} ({{
                                            planPersonal.plan_personal_usuarios.length
                                        }})
                                        <span class="tog-icon plus"><fa [name]="'plus-square'"></fa></span>
                                        <span class="tog-icon minus"><fa [name]="'minus-square'"></fa></span>
                                    </div>
                                    <div id="eval_comp_curs_{{ i }}" class="expand_cursos" [expandableContent]>
                                        <p *ngFor="let plan_personal_usuario of planPersonal.plan_personal_usuarios">
                                            {{ plan_personal_usuario.grupo_usuario.nombre }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="plan-personal-data-pair">
                                        <p><span class="inline-label">Porcentaje de avance</span></p>
                                        <div class="progressbar">
                                            <progressbar
                                                class="active"
                                                [value]="planPersonal.porcentaje_transcurrido"
                                                [type]="'success'"
                                            >
                                                <i>{{ planPersonal.porcentaje_transcurrido | percentage }}</i>
                                            </progressbar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="actions">
                    <a
                        title="Ver Planificación"
                        [routerLink]="['/planificaciones/aplicadas', planPersonal.id]"
                        class="btn btn-table"
                    >
                        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                    </a>
                    <!-- <button
                        *ngIf="enableCompartir"
                        type="button"
                        (click)="compartirPlanificacion(planPersonal)"
                        class="btn btn-table"
                    >
                        <span class="glyphicon glyphicon-share-alt" aria-hidden="true"></span>
                    </button> -->
                    <a
                        *ngIf="showEstadistica"
                        title="Ver estadísticas"
                        [routerLink]="['/planificaciones/aplicadas', planPersonal.id, 'estadisticas']"
                        class="btn btn-table"
                    >
                        <fa [name]="'bar-chart'"></fa>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <p *ngIf="(!planificaciones || planificaciones.length <= 0) && establecimientos?.length != 0" class="not-found">
        No se encontraron planificaciones {{ evaluacionTipo }} aplicadas.
    </p>
    <p *ngIf="!establecimientos || establecimientos.length == 0">Usted no posee grupos asociados.</p>
    <paginator
        *ngIf="establecimientos && establecimientos.length > 0 && fechaInicial && fechaFinal"
        [getList]="setData"
    ></paginator>
    <!-- <compartir-instrumento-multiple-profesor *ngIf="instrumentoMultiple" [instrumentoMultiple]="instrumentoMultiple" [modalId]="'compartir_' + (presencial ? 'presencial' : 'online')" [openModal]="openModal" [tipoEvaluacion]="evaluacionTipo"></compartir-instrumento-multiple-profesor> -->

    <!-- <editar-evaluacion *ngIf="presencial == 0" [evaluacion]="evaluacionMultipleToEdit" [openModalEdit]="openModalEdit" [tipoEvaluacion]="evaluacionTipo" [paginator]="paginator"></editar-evaluacion> -->
</loading-layout>
