import { Component, ViewChild, OnInit, Input } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"
import { Materiales, Material } from "@puntaje/nebulosa/api-services"
import { PlanPersonalContenido } from "@puntaje/puntaje/api-services"

@Component({
    selector: "plan-personal-sesion-box-profesor",
    templateUrl: "plan_personal_sesion_box_profesor.component.html",
    styleUrls: ["plan_personal_sesion_box_profesor.component.scss"]
})
export class PlanPersonalSesionBoxProfesorComponent implements OnInit {
    _planPersonalSesion: any
    @Input() planPersonalId: number
    @Input() enableReporte: boolean = true
    sesionesRoute: string = ""
    planPersonalComponentes: PlanPersonalContenido[]

    @Input()
    set planPersonalSesion(planPersonalSesion: any) {
        this._planPersonalSesion = planPersonalSesion
        this.setup()
    }
    get planPersonalSesion() {
        return this._planPersonalSesion
    }

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected materialesService: Materiales
    ) {}

    ngOnInit() {}

    setup() {
        this.planPersonalComponentes = this.planPersonalSesion.plan_personal_contenidos
        //this.getMateriales();
    }

    getMateriales() {
        const materialIds = this.planPersonalComponentes
            .filter(ppc => {
                return ppc.tipo === "Material" && (ppc.material === undefined || ppc.material === null)
            })
            .map((ppc: PlanPersonalContenido) => ppc.tipo_id)
        if (materialIds.length > 0) {
            this.materialesService.wherePost({ material: { id: materialIds } }).then((materiales: Material[]) => {
                materiales.forEach((material: Material) => {
                    const ppc = this.planPersonalComponentes.find(
                        ppc => ppc.tipo === "Material" && ppc.tipo_id == material.id
                    )
                    ppc.material = material
                })
            })
        }
    }
}
