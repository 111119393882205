<ll-titulo>Estadísticas</ll-titulo>
<ll-subtitulo>Planes Personales</ll-subtitulo>
<menu-asignaturas-horizontal (asignaturaChanged)="onSelectAsignatura($event)"></menu-asignaturas-horizontal>
<loading-layout #AsignaturaLoading>
    <div class="row">
        <div class="col-md-4">
            <filtro-estadistica-planes-personales
                *ngIf="asignatura"
                [asignatura]="asignatura"
                (filtro)="onFiltro($event)"
            ></filtro-estadistica-planes-personales>
        </div>
        <div class="col-md-8">
            <loading-layout #EstadisticasLoading>
                <estadisticas-planes-personales [stats]="stats"></estadisticas-planes-personales>
            </loading-layout>
        </div>
    </div>
</loading-layout>
