<div class="caja-envio">
    <h5>Compartir Plan Personal</h5>
    <cui-button-link
        [isBlock]="true"
        buttonType="confirm"
        id="compartir_link"
        class="btn2 btn_style_3"
        (click)="openCompartir()"
    >
        Compartir
    </cui-button-link>
    <!--<div class="opciones">
		<h5>Más opciones</h5>
		<cui-button-link [isBlock]="true" class=" btn2 btn_style ">Ver Resultados</cui-button-link>
		<cui-button-link [isBlock]="true" class=" btn2 btn_style ">Eliminar</cui-button-link>				
	</div>-->
</div>
<compartir-plan-personal-profesor
    *ngIf="planPersonal"
    [planPersonal]="planPersonal"
    [closeOnFinished]="true"
    (planCompartidoEvent)="onPlanCompartidoListener($event)"
></compartir-plan-personal-profesor>
