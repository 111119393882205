import { ChangeDetectorRef, Component, OnInit, Input, Output, ViewChild, EventEmitter } from "@angular/core"
import { PlanPersonalSesion } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { EvaluacionPreviewModalComponent } from "@puntaje/puntaje/new-modules/ensayos"
import { MaterialPreviewModalComponent } from "@puntaje/puntaje/new-modules/materiales"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Router } from "@angular/router"
import { SelectContenidosPlanificaciones } from "@puntaje/puntaje/new-modules/shared"

interface EditingSesionFields {
    fecha: boolean
    nom: boolean
    dateRangeError: boolean
}

@Component({
    selector: "clase-profesor",
    templateUrl: "./clase-profesor.component.html",
    styleUrls: ["./clase-profesor.component.scss"]
})
export class ClaseProfesorComponent implements OnInit {
    hasEvaluaciones: boolean = false
    oldClase: PlanPersonalSesion
    modalEvaluacionId: number
    evaluacionesId: any = {}

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(EvaluacionPreviewModalComponent)
    evaluacionPreviewModalComponent: EvaluacionPreviewModalComponent
    @ViewChild(MaterialPreviewModalComponent)
    materialPreviewModalComponent: MaterialPreviewModalComponent

    @Input() clase: PlanPersonalSesion
    @Input() showVerClase: boolean = false
    @Input() index: number
    @Input() activa: boolean
    @Input() provieneDeCompartida: boolean
    @Input() editingSesionFields: EditingSesionFields
    @Input() enableToggleActiva: boolean = false
    @Input() enableEstado: boolean = false
    @Input() enableGoToEstadisticas: boolean = false
    @Input() planificacionId: number
    @Input() disableSeguimiento = false
    @Input() enableReporte: boolean = true
    @Input() disableActual = false

    @Output() activaChange = new EventEmitter<any>()
    @Output() editingChange = new EventEmitter<any>()
    @Output() fechasChange = new EventEmitter<any>()
    @Output() toggleActual = new EventEmitter<any>()

    selectedRecursos: any[]

    materialOpen = false
    sesionAlias: string = "clase"

    constructor(
        protected cdr: ChangeDetectorRef,
        protected selectContenidosPlanificaciones: SelectContenidosPlanificaciones,
        protected router: Router
    ) {}

    ngOnInit() {
        this.sesionAlias = config.plataforma.sesionAlias ? config.plataforma.sesionAlias : this.sesionAlias
        this.editingSesionFields = { fecha: false, nom: false, dateRangeError: false }

        this.evaluacionesId = { contenido: {} }

        this.selectedRecursos = this.clase.plan_personal_contenidos.map(ppc => ({
            contenido_id: ppc.id,
            recurso_type: ppc.tipo,
            recurso_id: ppc.tipo_id,
            selected: true
        }))

        this.clase.plan_personal_contenidos.forEach((contenido, index) => {
            contenido.id = null
            if (contenido.evaluacion) {
                this.hasEvaluaciones = true
                this.evaluacionesId.contenido[index] = contenido.tipo_id // contenido.evaluacion.id
            }
        })
        this.loadingLayout.ready()
    }

    checkDateRange(sesion: PlanPersonalSesion) {
        const fechaInicial = new Date(sesion.fecha_inicial)
        const fechaFinal = new Date(sesion.fecha_final)

        if (fechaInicial.getTime() <= fechaFinal.getTime()) {
            this.editingSesionFields.dateRangeError = false
        } else {
            this.editingSesionFields.dateRangeError = true
        }
    }

    edit(field: string) {
        this.oldClase = this.clase.clone()
        this.toggleEdit(field)
    }

    cancelEdit(field: string) {
        if (field == "fecha") {
            this.clase.fecha_inicial = this.oldClase.fecha_inicial
            this.clase.fecha_final = this.oldClase.fecha_final
            this.clase.fecha_pauta = this.oldClase.fecha_pauta
        }
        if (field == "nom") {
            this.clase.plan_personal_sesion = this.oldClase.plan_personal_sesion
        }

        this.toggleEdit(field)
    }

    saveEdit(field: string) {
        this.updateEditingSesiones()
        this.toggleEdit(field)
    }

    toggleEdit(field: string) {
        this.editingSesionFields[field] = !this.editingSesionFields[field]
    }

    onToggleActual() {
        this.toggleActual.emit(this.clase)
    }

    updateSesionesActivas() {
        this.activaChange.emit({ index: this.index, activa: this.activa })
    }

    updateEditingSesiones() {
        this.editingChange.emit({ index: this.index, fields: this.editingSesionFields, clase: this.clase })
    }

    openEvalPreview(evaluacionId: number) {
        this.modalEvaluacionId = evaluacionId
        this.cdr.detectChanges()
        this.evaluacionPreviewModalComponent.open()
    }

    closeEvalPreview() {
        this.modalEvaluacionId = undefined
    }

    openMaterialPreview(material) {
        this.materialOpen = true
        this.cdr.detectChanges()
        this.materialPreviewModalComponent.open(material)
    }

    closeMaterialPreview() {
        this.materialOpen = false
        this.modalEvaluacionId = undefined
    }

    verClase() {
        const contenidos = this.selectedRecursos.filter(sr => sr.selected)

        this.selectContenidosPlanificaciones.setSelectedRecursos(contenidos)
        this.router.navigate(["/planificaciones", this.planificacionId, "ciclo", 0, "sesiones", this.clase.id])
    }
}
