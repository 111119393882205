<ll-titulo><div class="print-hide">Resumen de uso para asociaciones</div></ll-titulo>
<ll-tabs>
    <ul class="nav nav-tabs print-hide" #tabList>
        <li role="presentation" [ngClass]="{ in: currentTab == 'parcial', active: currentTab == 'parcial' }">
            <a data-toggle="tab" href="#parcial" (click)="setCurrentTab('parcial')">Resumen parcial</a>
        </li>
        <li role="topDocente" [ngClass]="{ in: currentTab == 'topDocente', active: currentTab == 'topDocente' }">
            <a data-toggle="tab" href="#topDocente" (click)="setCurrentTab('topDocente')">Docentes destacados</a>
        </li>
    </ul>
</ll-tabs>
<div class="tab-content">
    <div
        id="parcial"
        class="tab-pane fade print-hide"
        [ngClass]="{ in: currentTab == 'parcial', active: currentTab == 'parcial' }"
    >
        <resumen-utp [tipo_resumen]="tipo_resumen"></resumen-utp>
    </div>
    <div
        id="topDocente"
        class="tab-pane fade"
        [ngClass]="{ in: currentTab == 'topDocente', active: currentTab == 'topDocente' }"
    >
        <top-docentes-asociacion></top-docentes-asociacion>
    </div>
</div>
