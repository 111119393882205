import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import {
    PlanPersonalCiclo,
    PlanPersonalSesion,
    PlanPersonal,
    PlanPersonalSesiones
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { trigger, state, style, animate, transition, keyframes, query, stagger } from "@angular/animations"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Location } from "@angular/common"
import { PlanPersonalSesionBoxComponent } from "./plan_personal_sesion_box.component"

@Component({
    selector: "plan-personal-sesiones-profesor",
    templateUrl: "plan_personal_sesiones_profesor.component.html",
    styleUrls: ["plan_personal_sesiones_profesor.component.scss"]
})
export class PlanPersonalSesionesProfesorComponent implements OnInit {
    currentPlanPersonalCiclo: PlanPersonalCiclo
    currentCicloIndex: number
    planPersonalSesiones: PlanPersonalSesion[]
    @Input() planPersonalId: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() sesiones_route: string
    @Input() cicloInicial: number
    @Input() enableReporte: boolean = true
    enableStartBtn: boolean = false
    asignaturaId: number
    sesionesAvance: { [x: number]: boolean } = {}
    orderingSesions: boolean

    _planPersonal: PlanPersonal
    @Input()
    set planPersonal(planPersonal: PlanPersonal) {
        this._planPersonal = planPersonal
        this.asignaturaId = this._planPersonal.generador_instrumento.asignatura.id

        this.setLastCiclo()
    }
    get planPersonal() {
        return this._planPersonal
    }

    constructor(
        private router: Router,
        private authService: AuthService,
        protected location: Location,
        protected planPersonalSesionesService: PlanPersonalSesiones
    ) {}

    ngOnInit() {
        this.loadingLayout.ready()
    }

    navigateToSesion(sesion_id) {}

    goBack() {
        this.location.back()
    }

    setLastCiclo() {
        if (
            this.planPersonal &&
            this.planPersonal.plan_personal_ciclos &&
            this.planPersonal.plan_personal_ciclos.length > 0
        ) {
            this.currentPlanPersonalCiclo =
                this.planPersonal.plan_personal_ciclos[this.planPersonal.plan_personal_ciclos.length - 1]
            this.planPersonalSesiones = this.currentPlanPersonalCiclo.plan_personal_sesiones
            this.currentCicloIndex = this.planPersonal.plan_personal_ciclos.length - 1
            this.ordenarSesiones()
        }
    }

    previousCycle() {
        this.currentCicloIndex = this.currentCicloIndex - 1
        this.onChangeSelect(this.currentCicloIndex)
    }

    nextCycle() {
        this.currentCicloIndex = this.currentCicloIndex + 1
        this.onChangeSelect(this.currentCicloIndex)
    }

    onChangeSelect(cicleIndex: number) {
        this.loadingLayout.standby()
        this.currentPlanPersonalCiclo = this.planPersonal.plan_personal_ciclos[this.currentCicloIndex]
        this.planPersonalSesiones = this.currentPlanPersonalCiclo.plan_personal_sesiones
        this.loadingLayout.ready()
    }

    onSesionMoverAdelante(sesion: PlanPersonalSesion) {
        this.orderingSesions = true
        this.planPersonalSesionesService.moverAdelante(sesion.id).then(data => {
            let index = this.planPersonalSesiones.indexOf(sesion)

            this.planPersonalSesiones[index].orden = this.planPersonalSesiones[index].orden + 1
            if (
                index < this.planPersonalSesiones.length - 1 &&
                this.planPersonalSesiones[index + 1].orden == this.planPersonalSesiones[index].orden
            ) {
                this.planPersonalSesiones[index + 1].orden = this.planPersonalSesiones[index + 1].orden - 1
                const aux = this.planPersonalSesiones[index]
                this.planPersonalSesiones[index] = this.planPersonalSesiones[index + 1]
                this.planPersonalSesiones[index + 1] = aux
            }

            this.orderingSesions = false
        })
    }

    onSesionMoverAtras(sesion: PlanPersonalSesion) {
        this.orderingSesions = true
        this.planPersonalSesionesService.moverAtras(sesion.id).then(data => {
            let index = this.planPersonalSesiones.indexOf(sesion)

            this.planPersonalSesiones[index].orden = this.planPersonalSesiones[index].orden - 1
            if (
                index > 0 &&
                this.planPersonalSesiones.length > 1 &&
                this.planPersonalSesiones[index - 1].orden == this.planPersonalSesiones[index].orden
            ) {
                this.planPersonalSesiones[index - 1].orden = this.planPersonalSesiones[index - 1].orden + 1
                const aux = this.planPersonalSesiones[index]
                this.planPersonalSesiones[index] = this.planPersonalSesiones[index - 1]
                this.planPersonalSesiones[index - 1] = aux
            }

            this.orderingSesions = false
        })
    }

    private ordenarSesiones() {
        this.planPersonalSesiones = this.planPersonalSesiones.sort((a, b) => {
            return a.orden - b.orden
        })
    }

    trackSesion(sesion) {
        return sesion.id
    }
}
