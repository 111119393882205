import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { ModalDirective } from "ngx-bootstrap/modal"
import { PlanPersonal } from "@puntaje/puntaje/api-services"
import { CompartirPlanPersonalProfesorComponent } from "./compartir_plan_personal_profesor.component"

@Component({
    selector: "opciones-plan-personal",
    templateUrl: "opciones_plan_personal.component.html",
    styleUrls: ["opciones_plan_personal.component.scss"]
})
export class OpcionesPlanPersonalComponent implements OnInit {
    @Input() tipoId: number
    @Input() planPersonal: PlanPersonal
    @Input() tipoPlanPersonal: string
    @Input() redirect: string
    @Input() compartirTodosCursos: boolean
    ultimoPlanPersonalId: number
    ultimosPlanPersonalesIds: number[]
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @ViewChild("mandatoryLoading") mandatoryLoading: ModalDirective
    canceled: boolean = false
    errorMsg: boolean = false

    planPersonalString: string = "Plan Personal"
    ultimPlanPersonalId: number
    imprimirAlCompartir: boolean = false
    @Input() enableAdminOptions: boolean = false

    @ViewChild(CompartirPlanPersonalProfesorComponent)
    compartirPlanPersonalProfesorComponent: CompartirPlanPersonalProfesorComponent

    @Output() planCompartidoEvent = new EventEmitter()

    constructor() {}

    ngOnInit() {}

    openCompartir() {
        this.compartirPlanPersonalProfesorComponent.open()
    }

    onPlanCompartidoListener(value: any) {
        this.planCompartidoEvent.emit(true)
    }
}
