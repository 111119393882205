import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    OnDestroy,
    SimpleChange,
    SimpleChanges,
    OnChanges
} from "@angular/core"
import { PlanPersonalPredefinido } from "@puntaje/puntaje/api-services"
import { PlanPersonalPredefinidos } from "@puntaje/puntaje/api-services"
import { Subject, Observable, Subscription, combineLatest, BehaviorSubject } from "rxjs"
import { switchMap, tap, startWith, map } from "rxjs/operators"
import { GeneradorInstrumentos, Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { select, Store } from "@ngrx/store"
import { selectAsignaturasById, State } from "@puntaje/puntaje/store"
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { filter, last, first } from "rxjs/operators"

@Component({
    selector: "plan_personal-predefinidos-profesores",
    templateUrl: "./plan_personal-predefinidos-profesores.component.html",
    styleUrls: ["./plan_personal-predefinidos-profesores.component.scss"]
})
export class planPersonalPredefinidosProfesoresComponent implements OnInit, OnDestroy, OnChanges {
    @Input() asignaturaId: Observable<number>
    planPersonalPredefinidos$: Observable<PlanPersonalPredefinido[]>
    plan_personal_predefinidos$: Observable<PlanPersonalPredefinido[]>
    asignaturasById$: Observable<{ [id: number]: AsignaturaWithConfig }> = this.store.pipe(
        select(selectAsignaturasById),
        filter(x => !!x)
    )
    asignaturas: any
    asignatura: AsignaturaWithConfig
    @Input() tipoInstrumento: string
    sub: Subscription
    tipoInstrumento$: BehaviorSubject<string> = new BehaviorSubject<string>(null)

    @ViewChild("planPersonalPredefinidos", { static: true }) loadingPlanes: LoadingLayoutComponent

    constructor(
        protected planPersonalPredefinidosService: PlanPersonalPredefinidos,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.sub = this.asignaturasById$.subscribe(asignaturasById => {
            this.asignaturas = asignaturasById
            if (this.asignaturaId) this.asignatura = this.asignaturas[+this.asignaturaId]
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tipoInstrumento) {
            this.tipoInstrumento$.next(this.tipoInstrumento)
        }
    }

    ngAfterViewInit() {
        this.plan_personal_predefinidos$ = combineLatest(
            this.asignaturaId,
            this.tipoInstrumento$.pipe(filter(x => !!x))
        ).pipe(
            map(([asignaturaId, evaluacionTipo]) => asignaturaId),
            startWith(1),
            tap(x => this.loadingPlanes.standby()),
            switchMap(this.generadorInstrumentos),
            switchMap(this.planPersonalPredefinidos),
            tap(x => this.loadingPlanes.ready())
        )
    }

    generadorInstrumentos = asignaturaId => {
        if (this.asignaturas) this.asignatura = this.asignaturas[asignaturaId]
        return this.generadorInstrumentosService.where({
            asignatura_plataforma: {
                asignatura_id: asignaturaId,
                plataforma_id: config.plataforma.id
            },
            tipo_instrumento: {
                tipo_instrumento: this.tipoInstrumento
            }
        })
    }

    planPersonalPredefinidos = generadorInstrumentos => {
        return this.planPersonalPredefinidosService.where({
            plan_personal: {
                generador_instrumento_id: generadorInstrumentos.map(g => g.id)
            }
        })
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe
    }
}
