<div class="contenedor">
    <!-- Login -->
    <div id="section1">
        <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
        <div class="content-logo-decripcion">
            <div class="logo-izq-container">
                <img class="logo-izq" src="assets/img/logos/logoMediumLight.png" />
            </div>
            <div class="descripcion-derecha-container">
                <h1>PREPARA TU PRUEBA DE TRANSICIÓN</h1>
                <h2 class="titulo">ENSAYOS ONLINE</h2>
            </div>
        </div>
        <div class="caja caja-inicio">
            <div class="caja-inner-margin">
                <login-ua
                    [enableContactUs]="false"
                    [enableRegister]="true"
                    [enableFacebookLogin]="true"
                    [enablePasswordRecovery]="true"
                ></login-ua>
            </div>
        </div>
    </div>
    <div id="section2">
        <div class="contentInformacion">
            <p>
                Bienvenidos a nuestra plataforma digital para la preparación de la prueba de transición universitaria.
                Ponemos a tu disposición de forma gratuita, distintas herramientas que sin duda serán de gran ayuda para
                preparar este desafío.
            </p>
            <p>
                Nuestros contenidos están alineados al temario DEMRE, con material descargable de todas las pruebas que
                existen actualmente. Contamos con planes de estudio, biblioteca con variado contenido de repaso y
                ensayos personalizables para aplicar al ritmo que más se adapte a tus necesidades.
            </p>
        </div>
        <div class="contentImages">
            <div class="contentIconDes">
                <div class="icon">
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ua/landing/icono-ensayos.png"
                    />
                </div>
                <div class="iconDes">Ensayos gratuitos multiplataforma</div>
            </div>
            <div class="contentIconDes">
                <div class="icon">
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ua/landing/icono-planes-de-estudio.png"
                    />
                </div>
                <div class="iconDes">Planes de estudio, biblioteca con variado contenido de repaso</div>
            </div>
            <div class="contentIconDes">
                <div class="icon">
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ua/landing/icono-material-descargable.png"
                    />
                </div>
                <div class="iconDes">Material descargable de todas las pruebas que existen actualmente</div>
            </div>
            <div class="contentIconDes">
                <div class="icon">
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ua/landing/icono-numero-de-preguntas.png"
                    />
                </div>
                <div class="iconDes">N° de preguntas ajustables (20, 30 o 65) con corrección y resultado inmediato</div>
            </div>
        </div>
    </div>
    <footer>
        <img
            class="footer-logo"
            src="{{ config.app.assets.logoMediumLight }}"
            alt="{{ config.plataforma.info.companyName }}"
        />
        <div class="right-side">
            <img
                class="footer-logo"
                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ua/landing/gratuidad.png"
                alt="Adscrita gratuidad"
            />
            <img
                class="footer-logo"
                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ua/landing/demre.png"
                alt="Postula a la Autónoma en www.demre.cl"
            />
        </div>
    </footer>
</div>
<simple-modal></simple-modal>
