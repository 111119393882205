<div *ngIf="estadisticas">
    <div *ngFor="let cursoStat of estadisticas" style="border: 1px solid gray">
        <h4>{{ cursoStat.curso }}</h4>
        <div *ngFor="let alumnoStat of cursoStat.estadisticas">
            <div class="row">
                <div class="col-md-3">{{ alumnoStat.alumno }}</div>
                <div class="col-md-9" *ngIf="alumnoStat.sesiones.length == 0">-</div>
                <div class="col-md-9" *ngIf="alumnoStat.sesiones.length > 0">
                    <div class="row">
                        <div
                            class="col-md-4"
                            *ngFor="let sesionStat of alumnoStat.sesiones; let i = index"
                            style="border: 1px solid lightgray"
                        >
                            <p>Sesión {{ i + 1 }}</p>
                            <div *ngFor="let contenidoStat of sesionStat.contenidos">
                                <div *ngIf="contenidoStat.tipo == 'Material'">
                                    <h4><a [routerLink]="['/materiales', contenidoStat.id]">Material</a></h4>
                                    <div *ngIf="contenidoStat.pendiente">pendiente</div>
                                    <div *ngIf="!contenidoStat.pendiente">
                                        tiempo: {{ contenidoStat.tiempo }} visto:
                                        {{ contenidoStat.fecha_visto | date: "dd/mm/yy" }}
                                    </div>
                                </div>
                                <div *ngIf="contenidoStat.tipo == 'Evaluacion'">
                                    <a
                                        *ngIf="contenidoStat.evaluacion"
                                        [routerLink]="['/instrumentos', contenidoStat.evaluacion?.instrumento_id]"
                                        [queryParams]="{ evaluacion_id: contenidoStat.id }"
                                    >
                                        <h4>Evaluación</h4>
                                    </a>
                                    <div *ngIf="!contenidoStat.evaluacion"><h4>Evaluación</h4></div>
                                    <div *ngIf="!contenidoStat.pendiente">
                                        buenas: {{ contenidoStat.correctas }} malas:
                                        {{ contenidoStat.incorrectas }} omitidas:
                                        {{ contenidoStat.omitidas }} porcentaje: {{ contenidoStat.promedio }}%
                                    </div>
                                    <div *ngIf="contenidoStat.pendiente">pendiente</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
