import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { AuthService } from "@puntaje/shared/core"
import { State } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"

@Component({
    templateUrl: "home_ensayos.component.html"
})
export class HomeEnsayosComponent extends MenuComponent implements OnInit {
    menuItems: MenuItemBoxData[] = []

    constructor(
        protected store: Store<State>,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            {
                route: "/ensayos/generar_ensayo",
                params: { tipo_instrumento: "ensayo" },
                label: "Generar Ensayo PSU",
                text: "Genera ensayos similares a la PSU y compártelos con tus estudiantes. Con opciones personalizables.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/evaluaciones",
                params: {
                    tipo_instrumento: "ensayo"
                },
                label: "Historial de ensayos online, impresos y sin configurar",
                text: "Encuentra aquí la lista de todos los ensayos online, impresos y sin configurar.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/ensayos/progreso/",
                params: { tipo_instrumento: "ensayo" },
                label: "Progreso de tus estudiantes",
                text: "Observa la evolución en el tiempo de los resultados obtenidos por los estudiantes.",
                linkText: "Ver progreso",
                icon: "estadistica-o"
            },
            {
                route: "/ensayos/estadisticas/",
                params: {
                    tipo_instrumento: "ensayo"
                },
                label: "Estadísticas PSU",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en ensayos PSU.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            },
            {
                route: "/evaluacion_instancias/subir",
                label: "Cargar Resultados",
                text: "Sube resultados de simulacros a partir de una hoja de respuesta o una planilla CSV.",
                linkText: "Cargar",
                icon: "hacer-prueba"
            },
            {
                route: "/instrumentos/subir_prueba_propia",
                params: { tipo_instrumento: "ensayo" },
                label: "Subir pruebas propias",
                text: "Sube tus propias pruebas y compártelas con tus estudiantes.",
                linkText: "Comenzar",
                icon: "hacer-prueba",
                cornerRibbon: {
                    text: "Beta"
                }
            }
        ]
    }
}
