<div class="sesion-container" *ngIf="planPersonalSesion">
    <div class="sesion-table">
        <div class="sesion-row">
            <div class="sesion-cell type-cell">
                <ogr-icon name="libro2-o" class="icono"></ogr-icon>
                <p>Sesión de estudio</p>
            </div>
            <div class="sesion-cell">
                <h3 class="titulo-sesion">
                    Sesión {{ planPersonalSesion.orden + 1 }} | {{ planPersonalSesion.clasificacion.clasificacion }}
                </h3>
                <label class="avance">Materiales</label>
                <div class="bottom-info">
                    <div class="bottom-info-row">
                        <div *ngIf="planPersonalComponentes" class="bottom-info-cell">
                            <div *ngFor="let component of planPersonalComponentes; let i = index" class="enforce-title">
                                <div *ngIf="component.tipo == 'Material' && component.material">
                                    <p>{{ component.material.material }}</p>
                                </div>
                            </div>
                            <plan-personal-sesion-profesor
                                [plan_personal_id]="planPersonalId"
                                [plan_personal_ciclo_orden]="0"
                                [plan_personal_sesion_id]="planPersonalSesion.id"
                                [enableReporte]="enableReporte"
                            ></plan-personal-sesion-profesor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
