import { Subscription } from "rxjs"
import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Instrumentos } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "pruebas-propias.component.html",
    styleUrls: ["pruebas-propias.component.scss"]
})
export class PruebasPropiasComponent implements OnInit {
    asignaturaId: number
    tipoInstrumento: string
    asignatura: Asignatura
    subQuery: Subscription

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected instrumentosService: Instrumentos
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.instrumentos.pruebas_propias")
        this.subQuery = this.route.queryParams.subscribe(query => {
            this.tipoInstrumento = query["tipo_instrumento"] || config.plataforma.evaluacionDefault
            this.asignaturaId = +query["asignatura_id"]
        })
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    ngOnDestroy() {
        this.subQuery.unsubscribe()
    }
}
