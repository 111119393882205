import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { NgForm } from "@angular/forms"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Clasificaciones, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import {
    GrupoUsuario,
    GrupoUsuarios,
    PlanPersonal,
    PlanPersonales,
    PlanPersonalUsuario
} from "@puntaje/puntaje/api-services"
import { PopupGrupoUsuariosComponent } from "@puntaje/puntaje/new-modules/shared"

@Component({
    selector: "compartir-plan-personal-profesor",
    templateUrl: "compartir_plan_personal_profesor.component.html"
})
export class CompartirPlanPersonalProfesorComponent implements OnInit {
    private _planPersonal: PlanPersonal
    grupoUsuarios: GrupoUsuario[]
    validationsPending: boolean = false
    @ViewChild("form") form: NgForm
    @Input() tipoEvaluacion: string
    @Input() modalId: string = "plan-personal"

    sharing: boolean = false

    @Input() closeOnFinished: boolean = false
    @ViewChild(PopupGrupoUsuariosComponent, { static: true }) popupGrupoUsuarios: PopupGrupoUsuariosComponent

    @Output() planCompartidoEvent = new EventEmitter()

    nombreGrupo: string

    constructor(
        protected planPersonalesService: PlanPersonales,
        protected grupoUsuariosService: GrupoUsuarios,
        protected clasificacionesService: Clasificaciones,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected authService: AuthService,
        protected router: Router
    ) {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias
    }

    @Input()
    get planPersonal() {
        return this._planPersonal
    }

    set planPersonal(pp: PlanPersonal) {
        this._planPersonal = pp
    }

    ngOnInit() {
        const params = {
            establecimiento_id_not_nil: 1,
            activos: 1,
            propios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            grupo_usuario: {
                privado: 1
            },
            grupo_usuario_usuario: {
                propietario: 1
            },
            establecimiento: {
                activo: 1
            }
        }

        this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[]) => {
            this.grupoUsuarios = grupoUsuarios
        })
    }

    compartir(gruposSeleccionados: GrupoUsuario[]) {
        this.sharing = true

        if (this.planPersonal) {
            this.compartirPlanPersonal(this.planPersonal, gruposSeleccionados).then((pp: PlanPersonal) => {
                this.sharing = false
                this.planCompartido()

                if (this.closeOnFinished) {
                    this.popupGrupoUsuarios.hide()
                }
            })
        }
    }

    compartirPlanPersonal(planPersonal: PlanPersonal, gruposSeleccionados: GrupoUsuario[]) {
        planPersonal = new PlanPersonal()
        planPersonal.plan_personal = this.planPersonal.plan_personal

        planPersonal.plan_personal_usuarios = gruposSeleccionados.map((grupoUsuario: GrupoUsuario) => {
            let ppu = new PlanPersonalUsuario()
            ppu.usuario_id = this.authService.getUserData().id
            ppu.receptor_type = "GrupoUsuario"
            ppu.receptor_id = grupoUsuario.id

            return ppu
        })

        return this.planPersonalesService.update(this.planPersonal.id, planPersonal).then((pp: PlanPersonal) => {
            return pp
        })
    }

    open() {
        this.popupGrupoUsuarios.open()
    }

    planCompartido() {
        this.planCompartidoEvent.emit(true)
    }
}
