<planes-personales-compartidos
    [asignaturaId]="asignaturaId"
    [planPersonalTipo]="tipoInstrumento"
    [generadorInstrumentos]="generadorInstrumentos"
></planes-personales-compartidos>
<planes-personales-no-compartidos
    [asignaturaId]="asignaturaId"
    [planPersonalTipo]="tipoInstrumento"
    [generadorInstrumentos]="generadorInstrumentos"
></planes-personales-no-compartidos>
