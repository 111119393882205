import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from "@angular/core"
import {
    PlanPersonales,
    PlanPersonalSesiones,
    PlanPersonalSesion,
    Evaluacion,
    PlanPersonalContenido,
    GrupoUsuario
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Material } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "estadisticas-clase-profesores",
    templateUrl: "./estadisticas-clase-profesores.component.html",
    styleUrls: ["./estadisticas-clase-profesores.component.scss"]
})
export class EstadisticasClaseProfesoresComponent implements OnInit {
    planPersonalSesion: PlanPersonalSesion
    _planPersonalSesionId: number
    showEvaluacionStats: boolean = true
    showMaterialStats: boolean = true
    @ViewChild("loadingPlanificacion", { static: true }) loadingPlanificacion: LoadingLayoutComponent
    materialStats: {
        material: Material
        stats_grupo_usuarios: {
            grupo_usuario: GrupoUsuario
            tiempo_promedio: number
            cantidad_visto: number
            porcentaje_visto: number
        }[]
    }[]
    evaluacionStats: {
        evaluacion: Evaluacion
        stats_grupo_usuarios: {
            grupo_usuario: GrupoUsuario
            tiempo_promedio: number
            cantidad_contestados: number
            calificacion_promedio: number
            porcentaje_contestadas: number
        }[]
    }[]

    @Input("planPersonalSesionId") set planPersonalId(value: number) {
        this._planPersonalSesionId = value
        this.planPersonalSesionesService.find(value, this.planPersonalSesionParams()).then(planPersonalSesion => {
            let materiales = planPersonalSesion.plan_personal_contenidos.filter(
                contenido => contenido.tipo == "Material"
            )
            let evaluaciones = planPersonalSesion.plan_personal_contenidos.filter(
                contenido => contenido.tipo == "Evaluacion"
            )
            let grupo_usuarios = planPersonalSesion.plan_personal_ciclo.plan_personal.plan_personal_usuarios.map(
                ppu => ppu.grupo_usuario
            )

            this.materialStats = materiales.map(contenido => this.getMaterialStat(contenido, grupo_usuarios))
            this.evaluacionStats = evaluaciones.map(contenido => this.getEvaluacionStat(contenido, grupo_usuarios))

            if (this.materialStats.length === 0) {
                this.showMaterialStats = false
            }
            if (this.evaluacionStats.length === 0) {
                this.showEvaluacionStats = false
            }
            this.planPersonalSesion = planPersonalSesion
            this.planPersonalSesionChange.emit(this.planPersonalSesion)

            this.loadingPlanificacion.ready()
        })
    }

    @Output() planPersonalSesionChange = new EventEmitter()

    // set planPersonalSesion(pps) {
    //   console.log(pps)
    //   this._planPersonalSesion = pps
    //   this.planPersonalSesionChange.emit(this._planPersonalSesion);
    // }

    // get planPersonalSesion() {
    //   return this._planPersonalSesion
    // }

    constructor(
        protected PlanPersonalesService: PlanPersonales,
        protected planPersonalSesionesService: PlanPersonalSesiones
    ) {}

    ngOnInit() {}

    getMaterialStat(contenido: PlanPersonalContenido, grupoUsuarios: GrupoUsuario[]) {
        let stat = {
            material: contenido.material,
            stats_grupo_usuarios: []
        }
        grupoUsuarios.forEach(gu => {
            let instancias = contenido.plan_personal_material_instancias.filter(ins =>
                gu.alumnos.map(al => al.id).includes(ins.plan_personal_instancia.usuario.id)
            )
            let total_visto = instancias.length
            stat.stats_grupo_usuarios.push({
                grupo_usuario: gu,
                tiempo_promedio: total_visto == 0 ? 0 : instancias.reduce((acc, i) => acc + i.tiempo, 0) / total_visto,
                cantidad_visto: instancias.length,
                porcentaje_visto: (instancias.length * 100) / gu.numero_alumnos
            })
        })
        return stat
    }

    getEvaluacionStat(contenido: PlanPersonalContenido, grupoUsuarios: GrupoUsuario[]) {
        let stat = {
            evaluacion: contenido.evaluacion,
            stats_grupo_usuarios: []
        }

        grupoUsuarios.forEach(gu => {
            let instancias = contenido.plan_personal_evaluacion_instancias.filter(ins =>
                gu.alumnos.map(al => al.id).includes(ins.plan_personal_instancia.usuario.id)
            )
            let total_contestados = instancias.length
            stat.stats_grupo_usuarios.push({
                grupo_usuario: gu,
                tiempo_promedio:
                    total_contestados == 0
                        ? 0
                        : instancias.reduce((acc, i) => acc + i.evaluacion_instancia.correctas, 0) / total_contestados,
                numero_contestados: instancias.length,
                porcentaje_contestadas: (instancias.length * 100) / gu.numero_alumnos,
                calificacion_promedio:
                    total_contestados == 0
                        ? 0
                        : instancias.reduce((acc, i) => acc + i.evaluacion_instancia.calificacion, 0) /
                          total_contestados
            })
        })

        return stat
    }

    planPersonalSesionParams() {
        return {
            render_options: {
                include: {
                    plan_personal_ciclo: {
                        include: {
                            plan_personal: {
                                include: {
                                    plan_personal_usuarios: {
                                        include: { grupo_usuario: { methods: ["numero_alumnos", "alumnos"] } }
                                    }
                                }
                            }
                        }
                    },
                    plan_personal_contenidos: {
                        include: {
                            plan_personal_material_instancias: {
                                include: {
                                    plan_personal_instancia: { include: "usuario" }
                                }
                            },
                            plan_personal_evaluacion_instancias: {
                                include: {
                                    evaluacion_instancia: null,
                                    plan_personal_instancia: { include: "usuario" }
                                }
                            },
                            evaluacion: null,
                            material: null
                        }
                    }
                }
            }
        }
    }
}
