<ll-titulo>Planificaciones Predefinidas</ll-titulo>
<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas [evaluacionTipos]="evaluacionTipos"></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>
<ng-container *ngIf="tipoInstrumento">
    <plan_personal-predefinidos-profesores
        [tipoInstrumento]="tipoInstrumento"
        [asignaturaId]="asignaturaId$"
    ></plan_personal-predefinidos-profesores>
</ng-container>
