import { Component, OnInit, Input } from "@angular/core"

@Component({
    selector: "estadisticas-planes-personales",
    templateUrl: "./estadisticas-planes-personales.component.html",
    styleUrls: ["./estadisticas-planes-personales.component.scss"]
})
export class EstadisticasPlanesPersonalesComponent implements OnInit {
    @Input() stats: any

    constructor() {}

    ngOnInit() {}
}
