import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import {
    PlanPersonalContenido,
    PlanPersonalContenidos,
    PlanPersonalSesion,
    PlanPersonalSesiones,
    PlanPersonal,
    PlanPersonales,
    PlanPersonalInstancia,
    PlanPersonalInstancias,
    PlanPersonalCiclo,
    Evaluacion,
    Evaluaciones
} from "@puntaje/puntaje/api-services"
import { Materiales, Material } from "@puntaje/nebulosa/api-services"
import { AuthService, ScrollToService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Observable } from "rxjs"
import { HttpResponse } from "@angular/common/http"

@Component({
    selector: "plan-personal-sesion-profesor",
    templateUrl: "plan_personal_sesion_profesor.component.html",
    styleUrls: ["plan_personal_sesion_profesor.component.scss"]
})
export class PlanPersonalSesionProfesorComponent {
    sub: any
    @Input() plan_personal_id: number
    @Input() enableReporte: boolean = true
    private _plan_personal_ciclo_orden: number
    private _plan_personal_sesion_id: number
    planPersonalSesion: PlanPersonalSesion
    planPersonal: PlanPersonal
    planPersonalInstancia: PlanPersonalInstancia
    planPersonalCiclo: PlanPersonalCiclo
    usuarioId: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    hash_evaluaciones: any
    contenidoInstancias: { [x: string]: PlanPersonalContenido } = {}
    activeTab: number = 0
    planPersonalComponentes: PlanPersonalContenido[]
    componentesReady: boolean = false
    currentPreviewIndex: number = -1

    @Input()
    set plan_personal_ciclo_orden(plan_personal_ciclo_orden: any) {
        this._plan_personal_ciclo_orden = plan_personal_ciclo_orden
        //this.setup();
    }
    get plan_personal_ciclo_orden() {
        return this._plan_personal_ciclo_orden
    }
    @Input()
    set plan_personal_sesion_id(plan_personal_sesion_id: any) {
        this._plan_personal_sesion_id = plan_personal_sesion_id
        this.setup()
    }
    get plan_personal_sesion_id() {
        return this._plan_personal_sesion_id
    }

    constructor(
        private authService: AuthService,
        protected scrollToService: ScrollToService,
        protected router: Router,
        protected planPersonalInstanciasService: PlanPersonalInstancias,
        protected planPersonalSesiones: PlanPersonalSesiones,
        protected planPersonalesService: PlanPersonales,
        protected materialesService: Materiales,
        protected planPersonalContenidosService: PlanPersonalContenidos,
        protected evaluacionesService: Evaluaciones
    ) {
        this.usuarioId = this.authService.getUserData().id
    }

    setup() {
        this.activeTab = 0
        this.planPersonalComponentes = null
        this.componentesReady = false

        this.setPlanPersonal()
    }

    setPlanPersonal() {
        let params = {
            plan_personal: {
                id: this.plan_personal_id
            },
            plan_personal_ciclo: {
                orden: this.plan_personal_ciclo_orden
            },
            include:
                "[generador_instrumento:[asignatura],plan_personal_ciclos:[plan_personal_sesiones:[plan_personal_contenidos]]]"
        }
        this.planPersonalesService.where(params).then((planesPersonales: PlanPersonal[]) => {
            if (planesPersonales.length > 0) {
                this.planPersonal = planesPersonales[0]
                this.planPersonalCiclo = this.planPersonal.plan_personal_ciclos.find(
                    c => c.orden == this.plan_personal_ciclo_orden
                )
                this.planPersonalSesion = this.planPersonalCiclo.plan_personal_sesiones.find(
                    s => s.id == this.plan_personal_sesion_id
                )
                this.planPersonalComponentes = this.planPersonalSesion.plan_personal_contenidos

                this.contenidoInstancias = {}
                this.planPersonalComponentes.forEach((c, index3) => {
                    this.contenidoInstancias[c.tipo_id + c.tipo] = c
                })

                this.hash_evaluaciones = {}
                this.planPersonalComponentes.forEach((ppc, index) => {
                    if (ppc.plan_personal_sesion_id == this.plan_personal_sesion_id && ppc.tipo == "Evaluacion") {
                        this.hash_evaluaciones[ppc.plan_personal_sesion_id] = {}
                        this.hash_evaluaciones[ppc.plan_personal_sesion_id][0] = ppc.tipo_id
                    }
                })
            }

            this.getMateriales()
        })
    }

    getMateriales() {
        const promesas = []
        const materialIds = []
        const evaluacionIds = []

        this.planPersonalComponentes.forEach(ppc => {
            if (ppc.tipo == "Material" && (ppc.material === undefined || ppc.material === null)) {
                materialIds.push(ppc.tipo_id)
            }
            if (ppc.tipo == "Evaluacion" && (ppc.evaluacion === undefined || ppc.evaluacion === null)) {
                evaluacionIds.push(ppc.tipo_id)
            }
        })

        if (materialIds.length > 0) {
            promesas.push(
                this.materialesService.wherePost({ material: { id: materialIds } }).then((materiales: Material[]) => {
                    materiales.forEach((material: Material) => {
                        const ppc = this.planPersonalComponentes.find(
                            ppc => ppc.tipo == "Material" && ppc.tipo_id == material.id
                        )
                        ppc.material = material
                    })
                })
            )
        }
        if (evaluacionIds.length > 0) {
            promesas.push(
                this.evaluacionesService
                    .wherePost({ evaluacion: { id: evaluacionIds } })
                    .then((evaluaciones: Evaluacion[]) => {
                        evaluaciones.forEach((evaluacion: Evaluacion) => {
                            const ppc = this.planPersonalComponentes.find(
                                ppc => ppc.tipo == "Evaluacion" && ppc.tipo_id == evaluacion.id
                            )
                            ppc.evaluacion = evaluacion
                        })
                    })
            )
        }

        return Promise.all(promesas).then(() => {
            this.componentesReady = true
            this.loadingLayout.ready()
        })
    }

    checkIfEvaluationExists(orden: number) {
        orden = 0
        return (
            this.hash_evaluaciones &&
            this.hash_evaluaciones[this.plan_personal_sesion_id] &&
            this.hash_evaluaciones[this.plan_personal_sesion_id][orden]
        )
    }

    type(obj: any) {
        return obj.constructor
    }

    togglePreview(index: number) {
        if (this.currentPreviewIndex == index) this.currentPreviewIndex = -1
        else this.currentPreviewIndex = index
    }

    getTypeIcon(comp: PlanPersonalContenido) {
        let thumbType = "libro"
        if (comp.tipo == "Material") {
            switch (comp.material.material_tipo_nombre) {
                case "Video":
                    thumbType = "video"
                    break
                case "Streaming":
                    thumbType = "video"
                    break
                case "Archivo":
                    thumbType = "guia-2"
                    break
                default:
                    thumbType = "guia-2"
                    break
            }
        } else if (comp.tipo == "Evaluacion") {
            thumbType = "hacer-prueba"
        }
        return thumbType
    }
}
