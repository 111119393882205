import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { State } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"
import { AuthService } from "@puntaje/shared/core"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent extends MenuComponent implements OnInit {
    menuItems: MenuItemBoxData[] = []
    warningText =
        "<p>Debido a la nueva prueba de transición, la información histórica de ensayos PSU estará disponible hasta el <b>31 de mayo</b>.</p>"
    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected store: Store<State>,
        public authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true, noTitleBar: true })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            {
                route: "/ensayos/generar_ensayo/",
                params: { tipo_instrumento: "ensayo" },
                label: "Generar Ensayo PSU",
                text: "Genera ensayos similares a la PSU y compártelos con tus estudiantes. Con opciones personalizables.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route:
                    "/pruebas/generar_prueba/" +
                    (this.authService.getUserData().asignatura_id || asignaturasByEvaluacionTipo["curricular"][0].id),
                params: { tipo_instrumento: "curricular" },
                label: "Realizar prueba curricular",
                text: "Genera pruebas de contenido curricular y compártelas con tus estudiantes. Con opciones personalizables.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/ensayos/estadisticas/",
                params: {
                    tipo_instrumento: "ensayo",
                    asignatura_id: this.authService.getUserData().asignatura_id || 1
                },
                label: "Estadísticas PSU",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en ensayos PSU.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            },
            {
                route: "/pruebas/estadisticas/",
                params: {
                    tipo_instrumento: "curricular",
                    asignatura_id: this.authService.getUserData().asignatura_id || 1
                },
                label: "Estadísticas de pruebas curriculares",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            }
        ]
    }
}
