import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { BaseChart, SplineChart } from "@puntaje/shared/core"

@Component({
    selector: "grafico-evolutivo-evaluacion",
    template: `
        <loading-layout #loadingLayout>
            <estadisticas-grafico [grafico]="grafico"></estadisticas-grafico>
        </loading-layout>
    `,
    styles: []
})
export class GraficoEvolutivoEvaluacionComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    _timeFrame: number[]
    colorList: string[] = [
        "#32a0cc",
        "#93c149",
        "#d74545",
        "#6159ce",
        "#48b38c",
        "#fbcb21",
        "#ee7d30",
        "#2b908f",
        "#434348",
        "#91e8e1"
    ]
    @Input() enableDemoMode: boolean = false
    @Input() data
    grafico: BaseChart

    constructor() {}

    ngOnInit() {
        this.setGrafico()
    }

    setGrafico() {
        this.loadingLayout.standby()
        let g1: any = {}
        g1.data = this.data
        g1.titulo = "Desempeño evolutivo"
        g1.descripcion = "Descripción"
        g1.nombreSerie = "Desempeño promedio"
        g1.ejeY = { title: "Resultados", min: 1, max: 7 }
        this.grafico = new SplineChart(g1)
        this.grafico.setCustomChartColors(this.colorList)
        this.loadingLayout.ready()
    }

    setGraficoDemo() {
        let dataSetsDemo = [
            {
                nombre: "clase 1",
                data: [
                    ["ev1", 4.0],
                    ["ev2", 5.0]
                ]
            },
            {
                nombre: "clase 2",
                data: [
                    ["ev1", 5.0],
                    ["eva2", 5.5]
                ]
            }
        ]

        this.loadingLayout.standby()
        let g1: any = {}
        g1.data = dataSetsDemo
        g1.titulo = "Desempeño evolutivo"
        g1.descripcion = "Descripción"
        g1.ejeY = { title: "Resultados", min: 1, max: 7 }
        //g1.tipoColor = ChartColorType.Resultados;
        this.grafico = new SplineChart(g1)
        this.grafico.setCustomChartColors(this.colorList)
        this.loadingLayout.ready()
    }
}
