import { Component, OnInit, Input } from "@angular/core"

@Component({
    selector: "plan-personal-avance-grupo-usuario",
    templateUrl: "./plan-personal-avance-grupo-usuario.component.html",
    styleUrls: ["./plan-personal-avance-grupo-usuario.component.scss"]
})
export class PlanPersonalAvanceGrupoUsuarioComponent implements OnInit {
    @Input() estadisticas: any

    constructor() {}

    ngOnInit() {}
}
