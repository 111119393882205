import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { select, Store } from "@ngrx/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Asignatura, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { PlanPersonal, PlanPersonalPredefinido } from "@puntaje/puntaje/api-services"
import { selectAsignaturasById, State } from "@puntaje/puntaje/store"
import { Observable, Subscription } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "datos-generales-planificacion",
    templateUrl: "./datos_generales_planificacion.component.html",
    styleUrls: ["./datos_generales_planificacion.component.scss"]
})
export class DatosGeneralesPlanificacionComponent implements OnInit, OnDestroy {
    private _planificacion: PlanPersonalPredefinido
    private _planPersonal: PlanPersonal
    totalSesiones: number = 0
    @Output() aplicarCall = new EventEmitter()
    asignaturas: any
    asignatura: Asignatura
    sub: Subscription
    asignaturasById$: Observable<{ [id: number]: AsignaturaWithConfig }> = this.store.pipe(
        select(selectAsignaturasById),
        filter(x => !!x)
    )
    @Input() enableAplicar: boolean = false
    @Input() enableCursos: boolean = false
    nombreGrupo = config.plataforma.grupoUsuarioAlias

    @Input()
    get planificacion() {
        return this._planificacion
    }

    set planificacion(ppp: PlanPersonalPredefinido) {
        this._planificacion = ppp
        this.setPlanPersonal(this._planificacion.plan_personal)
    }

    @Input()
    get planPersonal() {
        return this._planPersonal
    }

    set planPersonal(pp: PlanPersonal) {
        this.setPlanPersonal(pp)
    }

    constructor(protected store: Store<State>) {}

    ngOnInit() {}

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe
    }

    setPlanPersonal(pp: PlanPersonal) {
        this._planPersonal = pp
        if (this._planPersonal.plan_personal_ciclos && this._planPersonal.plan_personal_ciclos.length > 0) {
            this.totalSesiones = this._planPersonal.plan_personal_ciclos[0].plan_personal_sesiones.length
        }
        this.sub = this.asignaturasById$.subscribe(asignaturasById => {
            this.asignaturas = asignaturasById
            if (this._planPersonal.generador_instrumento)
                this.asignatura =
                    this.asignaturas[+this._planPersonal.generador_instrumento.asignatura_plataforma.asignatura.id]
        })
    }

    aplicar() {
        this.aplicarCall.emit()
    }

    createLinkAndOpen(link, downloadString, time = 0) {
        const a = document.createElement("a")
        a.download = downloadString
        a.href = link
        setTimeout(() => {
            a.click()
        }, time)
    }
}
