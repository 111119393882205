<ll-over-title></ll-over-title>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <usuario-showcasebox [showUserDetails]="false"></usuario-showcasebox>
        <br />
        <noticias-showcasebox></noticias-showcasebox>
        <br />
    </div>
    <div class="col-md-4 col-sm-6">
        <grupo-usuarios-profesor-showcase></grupo-usuarios-profesor-showcase>
        <br />
    </div>
    <div class="col-md-4 col-sm-6">
        <lista-establecimientos-profesor></lista-establecimientos-profesor>
        <br />
    </div>
</div>