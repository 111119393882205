import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    Asignatura,
    Asignaturas,
    Clasificaciones,
    GeneradorInstrumento,
    GeneradorInstrumentos,
    Preguntas
} from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import {
    Evaluaciones,
    GrupoUsuarios,
    Instrumento,
    Instrumentos,
    PlanPersonal,
    PlanPersonales,
    PlanPersonalTipo,
    PlanPersonalTipos
} from "@puntaje/puntaje/api-services"
import { AbstractGenerarEnsayoComponent } from "@puntaje/puntaje/new-modules/ensayos"

declare let $

@Component({
    selector: "generar-plan-personal-profesor",
    templateUrl: "generar_plan_personal_profesor.component.html",
    styleUrls: ["generar_plan_personal_profesor.component.scss"]
})
export class GenerarPlanPersonalProfesorComponent
    extends AbstractGenerarEnsayoComponent
    implements OnInit, OnChanges, AfterViewChecked, AfterViewInit
{
    @ViewChild("loadingLayoutSearch") loadingLayoutSearch: LoadingLayoutComponent
    @ViewChild("tabGenerar", { static: true }) tabGenerar: ElementRef
    @ViewChild("tabRecomendados") tabRecomendados: ElementRef
    @Input() asignaturaId: number
    @Input() rutaEvaluaciones: string
    @Input() enableBuscador: boolean = false
    @Input() defaultTab: string = ""
    @Input() enableReporte: boolean = true
    asignatura: Asignatura
    instrumentoAsignatura: Asignatura
    @Output() onReadyAsignatura: EventEmitter<any> = new EventEmitter<any>()

    planPersonal: PlanPersonal
    planPersonalId: number
    nombrePlanPersonal: string = ""
    generadorInstrumentoId: number
    generadorInstrumento: GeneradorInstrumento

    instrumento: Instrumento
    instrumentos: Instrumento[]
    selectedInstrumento: Instrumento

    instrumentoId: number
    buscarInstrumento: boolean = false
    buscado: boolean = false
    instrumentoBuscado: Instrumento
    instrumentosBuscados: Instrumento[]

    @Input() tipoEvaluacion: string
    config = config
    errorMsg: string = ""
    showErrorMsg: boolean = false
    anyEjeSelected: boolean = false
    errorPlanPersonal: string = ""

    @ViewChild("externalFrameReference", { read: ElementRef, static: true }) externalFrame: ElementRef
    enableScrollAndFollow: boolean = true

    constructor(
        protected authService: AuthService,
        protected route: ActivatedRoute,
        protected asignaturasService: Asignaturas,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected preguntasService: Preguntas,
        protected evaluacionesService: Evaluaciones,
        protected grupoUsuariosService: GrupoUsuarios,
        protected clasificacionesService: Clasificaciones,
        protected instrumentosService: Instrumentos,
        protected planPersonalesService: PlanPersonales,
        protected planPersonalTiposService: PlanPersonalTipos
    ) {
        super(router, generadorInstrumentosService, evaluacionesService)
    }

    ngOnInit() {
        //this.loadData();
        super.ngOnInit()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["tipoEvaluacion"]) {
            this.loadData()
        }
    }

    ngAfterViewInit() {
        this.setDefaultTab()
    }

    ngAfterViewChecked() {
        this.enableScrollAndFollow = window.innerWidth > 991
    }

    callbackRecomendado(i: Instrumento) {
        this.instrumento = i
    }

    setDefaultTab() {
        switch (this.defaultTab) {
            case "generar":
                ;(this.tabGenerar.nativeElement as HTMLElement).click()
                break

            case "recomendados":
                ;(this.tabRecomendados.nativeElement as HTMLElement).click()
                break

            default:
                break
        }
    }

    loadData() {
        this.loadingLayout.standby()
        if (this.asignaturaId) {
            this.asignaturasService
                .find(this.asignaturaId, {
                    tipo_instrumento: this.tipoEvaluacion,
                    generador_instrumento: { grupal: [0, 1] }
                })
                .then((asignatura: Asignatura) => {
                    this.asignatura = asignatura
                    this.onReadyAsignatura.emit(this.asignatura)
                    this.generadorInstrumentos = asignatura.generador_instrumentos

                    this.loadingLayout.ready()
                })
        }
    }

    generarPlanPersonal() {
        this.errorPlanPersonal = ""

        if (this.clasificacionesIds.length == 0) {
            this.showErrorMsg = true
            this.errorMsg = "Debe seleccionar al menos una categoría."
            return
        } else if (!this.anyEjeSelected) {
            this.showErrorMsg = true
            this.errorMsg = "Debe seleccionar al menos un sub eje."
            return
        } else {
            this.showErrorMsg = false
        }

        this.loadingLayout.standby()

        this.planPersonalTiposService
            .where({ plan_personal_tipo: { tipo: "tarea" } })
            .then((pptipos: PlanPersonalTipo[]) => {
                let planPersonal = new PlanPersonal() as any
                ;(planPersonal.plan_personal = this.nombrePlanPersonal),
                    (planPersonal.usuario_id = this.authService.getUserData().id),
                    (planPersonal.generador_instrumento_id = this.generadorInstrumento.id),
                    (planPersonal.plan_personal_tipo_id = pptipos[0].id),
                    ((planPersonal as any).asignatura_id = this.asignaturaId),
                    ((planPersonal as any).clasificaciones_id = this.clasificacionesIds)

                this.planPersonalesService.enableIgnoreCatch()
                this.planPersonalesService
                    .save(planPersonal)
                    .then((pp: PlanPersonal) => {
                        this.planPersonalId = pp.id
                        this.setPlanPersonal()
                    })
                    .catch(response => {
                        if (response.error && response.error.error && response.error.show_error) {
                            this.errorPlanPersonal = response.error.error
                        } else {
                            this.errorPlanPersonal = "No se ha podido generar el plan personal"
                        }
                        this.loadingLayout.ready()
                    })
            })
    }

    setPlanPersonal() {
        let include_str =
            "[" +
            "plan_personal_tipo," +
            "generador_instrumento:[asignatura]," +
            "plan_personal_ciclos:[" +
            "plan_personal_sesiones:[" +
            "clasificacion,plan_personal_contenidos]]]"

        let params = {
            plan_personal: {
                id: this.planPersonalId
            },
            include: include_str
        }

        this.planPersonalesService.where(params).then((planesPersonales: PlanPersonal[]) => {
            if (planesPersonales.length > 0) {
                this.planPersonal = planesPersonales[0]
            }
            this.loadingLayout.ready()
        })
    }

    cargarInstrumento() {
        this.loadingLayout.standby()
        this.instrumento = null
        this.instrumentos = null
        this.instrumentoAsignatura = null
        this.instrumentosService
            .find(this.instrumentoBuscado.id, { with_generador_instrumento: 1 })
            .then((i: Instrumento) => {
                this.instrumento = i
            })
    }

    cargarInstrumentos() {
        this.loadingLayout.standby()
        this.instrumento = null
        this.instrumentos = null
        this.instrumentoAsignatura = null

        let instrumentoIds = this.instrumentosBuscados.map(i => i.id)
        this.instrumentosService
            .where({ with_instrumento_preguntas: 1, instrumento: { id: instrumentoIds } })
            .then((i: Instrumento[]) => {
                this.instrumentos = i
                this.selectedInstrumento = i[0]
            })
    }

    onExternalLoadingInstrumento() {
        this.loadingLayout.standby()
    }

    onInstrumentoReady() {
        this.loadingLayout.ready()
        this.scrollToInstrumento()
    }

    onAsignaturaInstrumentoReady(instrumentoAsignatura) {
        this.instrumentoAsignatura = instrumentoAsignatura
    }

    onResize(event) {
        this.enableScrollAndFollow = event.target.innerWidth > 991
    }

    scrollToInstrumento() {
        let target = document.getElementById("instrumentoSeleccionado")
        $("html, body").animate(
            {
                scrollTop: target.offsetTop + 120
            },
            1000,
            "easeInOutExpo"
        )
    }

    onSelectGeneradorInstrumento(generadorInstrumento) {
        this.generadorInstrumento = generadorInstrumento
        this.nombrePlanPersonal = "Plan Personal Tarea " + generadorInstrumento.generador_instrumento
    }
}
