import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from "@angular/core"
import { Clasificacion, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Evaluaciones } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "configurar-clasificaciones-plan-personal",
    templateUrl: "configurar_clasificaciones_plan_personal.component.html",
    styleUrls: ["configurar_clasificaciones_plan_personal.component.scss"]
})
export class ConfigurarClasificacionesPlanPersonalComponent implements OnInit {
    @Input() enableCantidadPreguntas: boolean = false
    @Input() tipoInstrumento: string
    @Input() asignaturaId: number
    @Input() validadas: boolean = false
    @Input() clasificacionesIds: number[] = []
    @Output() clasificacionesIdsChange: EventEmitter<any> = new EventEmitter<any>()

    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    clasificacionesByTipo: { [x: number]: Clasificacion[] }
    clasificacionesByHasHijos: { [x: number]: any[] }
    @Input() anyEjeSelected: boolean = false
    @Output() anyEjeSelectedChange: EventEmitter<any> = new EventEmitter<any>()

    show_contenidos: boolean = false

    clasificaciones: Clasificacion[]
    concats: any
    config: typeof config

    private _generadorInstrumentoId: number

    constructor(protected generadorInstrumentosService: GeneradorInstrumentos) {}

    ngOnInit() {
        this.config = config
        this.concats = config.evaluaciones[this.tipoInstrumento].clasificaciones.concats

        this.clasificacionesByHasHijos = { 0: [], 1: [] }
        config.evaluaciones[this.tipoInstrumento].clasificaciones.configuration.forEach(c => {
            this.clasificacionesByHasHijos[c.hasHijos ? 1 : 0].push(c)
        })
    }

    @Input()
    set generadorInstrumentoId(gid: number) {
        this._generadorInstrumentoId = gid

        gid && this.setClasificaciones()
    }

    get generadorInstrumentoId() {
        return this._generadorInstrumentoId
    }

    setClasificaciones() {
        this.concats = this.concats || config.evaluaciones[this.tipoInstrumento].clasificaciones.concats

        this.loadingLayout.standby()
        let params: any = { group_by: config.evaluaciones[this.tipoInstrumento].clasificaciones.groupBy }
        this.concats && (params.concats = this.concats)
        this.validadas && (params.validadas = this.validadas)

        this.generadorInstrumentosService
            .filtroClasificaciones(this.generadorInstrumentoId, params)
            .then((clasificaciones: any[]) => {
                this.clasificacionesByTipo = (clasificaciones as any).groupBy(
                    c => c.clasificacion_tipo.clasificacion_tipo
                )
                this.clasificaciones = clasificaciones

                this.emitToParent()

                this.loadingLayout.ready()
                this.onReady.emit()
            })
    }

    emitToParent() {
        this.anyEjeSelected = false
        let padresEHijos = [].concat.apply(
            [],
            this.clasificaciones.map((c: any) => {
                if (c.clasificaciones_hijas) {
                    this.anyEjeSelected =
                        this.anyEjeSelected || (c.clasificacion_tipo.clasificacion_tipo == "eje temático" && c.selected)
                    return [c].concat(c.clasificaciones_hijas)
                } else return c
            })
        )

        this.clasificacionesIdsChange.emit(padresEHijos.filter(c => c.selected).map(c => c.id))
        this.anyEjeSelectedChange.emit(this.anyEjeSelected)
    }
}
