import { Component, Input } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Asignatura, Asignaturas, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { PlanPersonal, PlanPersonales } from "@puntaje/puntaje/api-services"

@Component({
    selector: "historial-planes-personales-profesor",
    templateUrl: "historial_planes_personales_profesor.component.html"
})
export class HistorialPlanesPersonalesProfesorComponent {
    @Input() asignaturaId: number
    @Input() tipoInstrumento: string
    @Input() generadorInstrumentos: GeneradorInstrumento[]

    planesPersonalesString: string = ""
    planesPersonalesGeneroString: string = ""

    constructor(protected route: ActivatedRoute, protected router: Router) {
        this.planesPersonalesString = "planes personales"
        this.planesPersonalesGeneroString = "os"
    }

    ngOnInit() {}
}
