import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { PlanPersonal, PlanPersonales } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "historial_planes_personales.component.html"
})
export class HistorialPlanesPersonalesComponent {
    asignaturaId: number
    asignatura: Asignatura

    planesPersonales: PlanPersonal[]
    planesPersonalesString: string = ""
    planesPersonalesGeneroString: string = ""

    tipoInstrumento: string

    generadorInstrumentos: GeneradorInstrumento[]

    constructor(
        protected titleService: TitleService,
        protected asignaturasService: Asignaturas,
        protected route: ActivatedRoute,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos
    ) {
        this.planesPersonalesString = "planes personales"
        this.planesPersonalesGeneroString = "os"
    }

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.historial_planes_personales")
        this.route.queryParams.subscribe(query => {
            this.asignaturaId = +query["asignatura_id"]
            this.tipoInstrumento = query["tipo_instrumento"]

            this.asignaturasService.find(this.asignaturaId).then((asignatura: Asignatura) => {
                this.asignatura = asignatura
                this.getGeneradores()
            })
        })
    }

    getGeneradores() {
        let params = {
            asignatura_plataforma: {
                asignatura_id: this.asignaturaId,
                plataforma_id: config.plataforma.id
            },
            tipo_instrumento: {
                tipo_instrumento: this.tipoInstrumento
            },
            include: "[tipo_instrumento]"
        }

        this.generadorInstrumentosService.where(params).then((generadorInstrumentos: GeneradorInstrumento[]) => {
            this.generadorInstrumentos = generadorInstrumentos
        })
    }

    onAsignaturaIdListener(value: any) {
        this.asignaturaId = value
        this.getGeneradores()
    }

    onReadyAsignaturaDropdown(asignaturaId) {
        this.router.navigate(["/planes_personales"], { queryParams: { asignatura_id: asignaturaId } })
    }
}
