<ng-container *ngIf="!saved">
    <form
        *ngIf="form"
        autocomplete="off"
        novalidate
        [formGroup]="form"
        easyScroll
        [scrollOnVariableChange]="currentStep"
        [easyScrollPadding]="-240"
    >
        <ng-container *ngIf="currentStep == 0">
            <form-input
                [form]="form"
                [params]="params['rut']"
                [(value)]="usuarioRegistro['rut']"
                [key]="'rut'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['nombre']"
                [(value)]="usuarioRegistro['nombre']"
                [key]="'nombre'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['apellido_paterno']"
                [(value)]="usuarioRegistro['apellido_paterno']"
                [key]="'apellido_paterno'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['fecha_nacimiento']"
                [(value)]="usuarioRegistro['fecha_nacimiento']"
                [key]="'fecha_nacimiento'"
            ></form-input>
        </ng-container>
        <ng-container *ngIf="currentStep == 1">
            <label for="telefono" [attr.for]="'telefono'">
                Teléfono celular
                <fa
                    [name]="'question-circle-o'"
                    [tooltip]="
                        'Te recomendamos agregar tu celular para que puedas activar o recuperar tu cuenta fácilmente a través de SMS.'
                    "
                ></fa>
            </label>
            <form-input
                [form]="form"
                [params]="params['telefono']"
                [(value)]="usuarioRegistro['telefono']"
                [key]="'telefono'"
                [withLabel]="false"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['email']"
                [(value)]="usuarioRegistro['email']"
                [key]="'email'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['password']"
                [enablePasswordBar]="true"
                [(value)]="usuarioRegistro['password']"
                [key]="'password'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['password_confirmation']"
                [(value)]="usuarioRegistro['password_confirmation']"
                [key]="'password_confirmation'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['genero']"
                [(value)]="usuarioRegistro['genero']"
                [key]="'genero'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['instagram']"
                [(value)]="usuarioRegistro['instagram']"
                [key]="'instagram'"
            ></form-input>
        </ng-container>
        <ng-container *ngIf="currentStep == 2">
            <!--  <form-input
                [form]="form"
                [params]="params['egresado']"
                [(value)]="usuarioRegistro['egresado']"
                [key]="'egresado'"
            ></form-input> -->

            <form-input 
                [form]="form" 
                [params]="params['perfil']" 
                [(value)]="usuarioRegistro['perfil']"
                [key]="'perfil'"
                (valueChange)="updatePerfil($event)"
                >
            </form-input>

            <form-input 
                [form]="form" 
                [params]="params['nivel_id']" 
                [(value)]="usuarioRegistro['nivel_id']"
                [key]="'nivel_id'"
                [disabled]="usuarioRegistro['perfil'] != 0 && usuarioRegistro['perfil'] != 1"
                >
            </form-input>

            <div [class.hide-school-info]="usuarioRegistro['egresado']">
                <div *ngIf="tipoRegistro == 'alumnos'">
                    <embed-preferencia-carrera-sedes-by-sede
                        #preferencias
                        [universidadesNombres]="universidades"
                        [form]="form"
                        [amount]="1"
                        (emitPreferencias)="updatePreferencias($event)"
                        [notRequired]="tipoRegistro != 'alumnos'"
                    ></embed-preferencia-carrera-sedes-by-sede>
                </div>

                <div>
                    <label [attr.for]="'lugarColegio'">
                        Comuna de tu colegio
                        <span class="required-mark"></span>
                    </label>
                    <autocomplete
                        [placeholder]="'Escribe y selecciona entre las opciones'"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="lugarColegio"
                        [options]="filteredLugarColegio"
                        (filterChange)="getLugarColegio($event)"
                        (controlValue)="updateLugarColegio($event)"
                        class="autocomplete"
                    ></autocomplete>

                    <label [attr.for]="'colegio'">{{ params["colegio"].label }}</label>
                    <autocomplete
                        [placeholder]="'Escribe y selecciona entre las opciones'"
                        [formControlName]="'colegio'"
                        [options]="filteredEstablecimientos"
                        (filterChange)="getEstablecimientos($event)"
                        (controlValue)="updateColegio($event)"
                        showTextFun="toStringWithIdPais"
                        class="autocomplete"
                        [disabled]="!lugarColegio"
                    ></autocomplete>

                    <div *ngIf="dirty && !usuarioRegistro.colegio">
                        <ul class="error-list">
                            <li>
                                <div class="align-table">
                                    <div class="left-icon">
                                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                                    </div>
                                    <div class="right-message">Debes ingresar un Colegio</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- <form-input
                    *ngIf="tipoRegistro == 'alumnos'"
                    [form]="form"
                    [params]="params['nivel_id']"
                    [(value)]="usuarioRegistro['nivel_id']"
                    [key]="'nivel_id'"
                ></form-input> -->
            </div>

            <!--    <form-input
                [form]="form"
                [params]="params['nem']"
                [(value)]="usuarioRegistro['nem']"
                [key]="'nem'"
            ></form-input> -->
            <form-input
                [form]="form"
                [params]="params['terminos_y_condiciones']"
                [(value)]="usuarioRegistro['terminos_y_condiciones']"
                [key]="'terminos_y_condiciones'"
            ></form-input>
        </ng-container>
        <div class="btn-container">
            <button (click)="previousStep()" type="button" class="btn btn-default previous-btn" *ngIf="currentStep > 0">
                <fa name="arrow-left" class="icono-left"></fa>
                Anterior
            </button>
            <button
                (click)="nextStep()"
                type="button"
                class="btn btn-default next-btn"
                *ngIf="currentStep < steps.length - 1"
            >
                Siguiente
                <fa name="arrow-right" class="icono-right"></fa>
            </button>
            <button
                (click)="registrarse()"
                type="button"
                class="btn btn-default submit-btn"
                *ngIf="currentStep == steps.length - 1"
            >
                Regístrate
                <fa name="check-square-o" class="icono-right"></fa>
            </button>
        </div>
        <a routerLink="/home">Volver a la página de Inicio</a>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>
