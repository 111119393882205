<!--<h3>#{{planificacion.id}}</h3>
<p><i>{{planificacion.plan_personal.descripcion}}</i></p>
<p>3 clases</p>-->
<div class="info-container clearfix" *ngIf="planPersonal">
    <div class="left-container">
        <div class="info-container-icon">
            <ogr-icon class="icono" name="planificacion-o"></ogr-icon>
        </div>
    </div>
    <div class="info-container-info" [class.with-bottom-padding]="enableAplicar">
        <div class="title-wrap">
            <h4>{{ planPersonal.plan_personal }}</h4>
            <span class="plan-id">#{{ planPersonal.id }}</span>
            <a
                *ngIf="planPersonal && planPersonal.archivo_url"
                class="btn btn-primary btn-xs btn-descargar"
                [href]="planPersonal.archivo_url"
                download
                target="_blank"
            >
                Descargar
            </a>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="info-mini-table">
                    <div class="info-mini-container">
                        <div class="mini-container-header">Asignatura</div>
                        <div class="mini-container-content">
                            {{ asignatura?.asignatura | easyplaceholder: "-" }}
                        </div>
                    </div>
                </div>
                <div class="info-mini-table">
                    <div class="info-mini-container">
                        <div class="mini-container-header">Clases</div>
                        <div class="mini-container-content">
                            {{ totalSesiones | easyplaceholder: "-" }}
                        </div>
                    </div>
                </div>
                <div class="info-mini-table">
                    <div class="info-mini-container">
                        <div class="mini-container-header">Descripción</div>
                        <div class="mini-container-content">
                            {{ planPersonal.descripcion | easyplaceholder: "-" }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="enableCursos">
                <div class="grupos-info">
                    <label>Compartido con:</label>
                    <table class="table tabla-grupos">
                        <thead>
                            <tr>
                                <th>{{ nombreGrupo | capitalize }}</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let g of planPersonal.plan_personal_usuarios">
                                <ng-container *ngIf="g.receptor">
                                    <td>{{ $any(g.receptor).nombre }}</td>
                                    <td>
                                        {{ g.created_at !== null ? (g.created_at | date: "dd/MM/yyyy HH:mm") : "" }}
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <cui-button *ngIf="enableAplicar" [isBlock]="true" class="btn_style" (click)="aplicar()">
                    Aplicar Planificación
                </cui-button>
            </div>
        </div>
    </div>
</div>
