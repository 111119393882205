import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { State } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"

@Component({
    templateUrl: "home_planes_personales.component.html"
})
export class HomePlanesPersonalesComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []

    constructor(protected store: Store<State>, protected loggedLayoutService: LoggedLayoutService) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        let evaluacionTipo = "diagnostico ensayo"
        let tipoInstrumento = "diagnostico ensayo"

        this.menuItems = [
            {
                route: "/planes_personales/generar_plan_personal/" + asignaturasByEvaluacionTipo[evaluacionTipo][0].id,
                params: { tipo_instrumento: tipoInstrumento },
                label: "Generar Plan Personal",
                text: "Genera planes personales y compártelos con tus estudiantes. Con opciones personalizables.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/planes_personales/tareas",
                params: {
                    tipo_instrumento: tipoInstrumento,
                    asignatura_id: asignaturasByEvaluacionTipo[evaluacionTipo][0].id
                },
                label: "Historial de planes personales compartidos y no compartidos",
                text: "Encuentra aquí la lista de todos los planes personales compartidos y no compartidos.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/ensayos/progreso/",
                params: { tipo_instrumento: tipoInstrumento },
                label: "Progreso de tus estudiantes",
                text: "Observa la evolución en el tiempo de los resultados obtenidos por los estudiantes.",
                linkText: "Ver progreso",
                icon: "estadistica-o"
            },
            {
                route: "/planes_personales/estadisticas/",
                label: "Estadísticas Planes Personales",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en planes personales.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            }
        ]
    }
}
