<loading-layout #loadingLayout>
    <cui-section-headline class="print-hide">Datos Generales del Generador Instrumento</cui-section-headline>
    <div class="info-container clearfix print-page-break-after">
        <div class="info-container-icon">
            <ogr-icon [name]="'prueba'"></ogr-icon>
        </div>
        <div class="info-container-info">
            <div class="row">
                <div class="col-md-12 print-hide">
                    <h4>Generador Instrumento #{{ planPersonal.generador_instrumento.id }}</h4>
                    <div class="info-mini-table">
                        <div class="info-mini-container">
                            <div class="mini-container-header">Tipo</div>
                            <div class="mini-container-content">
                                {{ planPersonal.generador_instrumento.generador_instrumento }}
                            </div>
                        </div>
                    </div>
                    <div class="info-mini-table">
                        <div class="info-mini-container">
                            <div class="mini-container-header">Categorías</div>
                            <div class="mini-container-content">
                                <div *ngFor="let c of clasificaciones">
                                    <span class="pull-left">
                                        {{ c.clasificacion }}
                                    </span>
                                    <span class="pull-right numero-preguntas">{{ c.cantidad_preguntas }} preg.</span>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
