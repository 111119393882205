<loading-layout #planPersonalPredefinidos>
    <ng-container *ngIf="plan_personal_predefinidos$ | async as plan_personal_predefinidos">
        <div class="planificaciones">
            <div
                class="planificacion {{ (asignatura?.confByEvaluacionTipo)[this.tipoInstrumento].clase }}"
                *ngFor="let plan_personal_predefinido of plan_personal_predefinidos"
            >
                <div class="header">
                    <p class="nivel"></p>
                    <p class="planificacion-id">#{{ plan_personal_predefinido.id }}</p>
                </div>
                <div class="info-title">
                    <ogr-icon name="planificacion-o" class="icono"></ogr-icon>
                    <div>
                        <h3>
                            <a routerLink="/planificaciones/predefinidas/{{ plan_personal_predefinido.id }}">
                                {{ plan_personal_predefinido.plan_personal.plan_personal }}
                            </a>
                        </h3>
                        <p>
                            <span class="asignatura">{{ asignatura?.asignatura }}</span>
                            <span class="separator"></span>
                            Clases:&nbsp;
                            <b>{{ plan_personal_predefinido.plan_personal.total_sesiones | easyplaceholder: "-" }}</b>
                        </p>
                    </div>
                </div>
                <p class="descripcion">{{ plan_personal_predefinido.plan_personal.descripcion }}</p>
                <div class="bottom-options">
                    <!--<cui-button-link routerLink="/planificaciones/predefinidas/{{plan_personal_predefinido.id}}" class=" btn-primary btn_style">Aplicar planificación</cui-button-link>-->
                    <a routerLink="/planificaciones/predefinidas/{{ plan_personal_predefinido.id }}" class="link-ver">
                        Ver planificación
                    </a>
                </div>
            </div>
            <p class="not-found" *ngIf="plan_personal_predefinidos && plan_personal_predefinidos.length == 0">
                <ogr-icon name="planificacion-o" class="icono"></ogr-icon>
                Sin planificaciones disponibles.
            </p>
        </div>
    </ng-container>
</loading-layout>
