import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-resumen-uso-asociaciones",
    templateUrl: "./resumen-uso-asociaciones.component.html",
    styleUrls: ["./resumen-uso-asociaciones.component.scss"]
})
export class ResumenUsoAsociacionesComponent implements OnInit {
    openingTab: string = "parcial"
    currentTab: string

    tipo_resumen: string = "asociacion"

    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.resumen_uso_asociaciones")
        this.currentTab = this.openingTab
    }

    setCurrentTab(tab: string) {
        this.currentTab = tab
    }
}
