import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { GeneradorInstrumento, Asignatura } from "@puntaje/nebulosa/api-services"
import {
    PlanPersonal,
    PlanPersonales,
    Establecimiento,
    Establecimientos,
    GrupoUsuario
} from "@puntaje/puntaje/api-services"
import { FiltroEstadisticasPlanPersonalService } from "./filtro_estadisticas_plan_personal.service"

import { Subscription } from "rxjs"

@Component({
    selector: "filtro-estadistica-planes-personales",
    templateUrl: "./filtro-estadistica-planes-personales.component.html",
    styleUrls: ["./filtro-estadistica-planes-personales.component.scss"]
})
export class FiltroEstadisticaPlanesPersonalesComponent implements OnInit {
    @Input() set asignatura(value: Asignatura) {
        this._asignatura = value
        this.generadorInstrumento = null
        this.planId = null
        this.establecimiento = null
    }

    get asignatura() {
        return this._asignatura
    }

    _asignatura: Asignatura
    generadorInstrumento: GeneradorInstrumento
    periodo: any
    planes: PlanPersonal[]
    planId: number
    establecimientos: Establecimiento[]
    establecimiento: Establecimiento
    grupoUsuario: GrupoUsuario

    sub: Subscription

    @Output() filtro = new EventEmitter<any>()

    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    constructor(
        protected planPersonalesService: PlanPersonales,
        protected establecimientosService: Establecimientos,
        protected filtroEstadisticasPlanPersonalService: FiltroEstadisticasPlanPersonalService
    ) {}

    ngOnInit() {
        this.sub = this.filtroEstadisticasPlanPersonalService.changeParams.subscribe(this.updateSelected.bind(this))
    }

    updateSelected(values) {
        let establecimientoId = values.establecimientoId
        let grupoUsuarioId = values.grupoUsuarioId
        let planId = values.planPersonalId
        let generadorInstrumentoId = values.generadorInstrumentoId

        if (!establecimientoId) {
            // this.loadingLayout.ready();
            return
        }

        if (generadorInstrumentoId) {
            this.generadorInstrumento = this.asignatura.generador_instrumentos.find(
                gi => gi.id == generadorInstrumentoId
            )
        }

        this.establecimiento = this.establecimientos.find(e => e.id == establecimientoId)
        if (grupoUsuarioId) {
            this.grupoUsuario = this.establecimiento.grupo_usuarios.find(gu => gu.id == grupoUsuarioId)
        }

        let promise = this.updatePlanes()

        if (promise && planId) {
            promise.then(() => {
                this.planId = this.planes.find(p => p.id == planId).id
                // this.loadingLayout.ready();
                this.onFiltro()
            })
        } else {
            this.loadingLayout.ready()
            // this.filtrar();
        }
    }

    onFiltro() {
        this.filtro.emit(this.planId)
    }

    onSelectPeriodo(periodo) {
        this.periodo = periodo
        this.getEstablecimientos()
    }

    updatePlanes() {
        if (this.grupoUsuario && this.generadorInstrumento) {
            let params = {
                plan_personal_tipo: { tipo: "tarea" },
                plan_personal: { generador_instrumento_id: this.generadorInstrumento.id },
                plan_personal_usuario: { receptor_id: this.grupoUsuario.id }
            }
            return this.planPersonalesService.where(params).then((planes: PlanPersonal[]) => {
                this.planes = planes
            })
        }

        return null
    }

    getEstablecimientos() {
        this.establecimiento = null
        this.grupoUsuario = null
        let params: any = {
            mios: 1,
            establecimiento: { activo: 1 },
            utp_establecimiento: 1,
            utp_asociacion: 1,
            fecha_inicial: this.periodo.fechaInicial,
            fecha_final: this.periodo.fechaFinal
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos

            this.filtroEstadisticasPlanPersonalService.onChangeParams()
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
