<cui-section-headline>{{ planesPersonalesString }} {{ tipo }}</cui-section-headline>
<loading-layout #loadingLayout>
    <div class="periodo">
        <filtro-periodo (select)="onSelectPeriodo($event)"></filtro-periodo>
    </div>
    <div class="filter-container" *ngIf="establecimientos?.length != 0">
        <h4 class="filter-title">
            <span class="glyphicon glyphicon-search icono"></span>
            Filtros de búsqueda
        </h4>
        <div class="row">
            <div class="col-md-3">
                <select
                    [disabled]="establecimientos && establecimientos.length == 1"
                    [(ngModel)]="establecimiento"
                    (change)="paginator.changePage(1)"
                    (change)="setPropietarios()"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- Seleccione un establecimiento --</option>
                    <option *ngFor="let e of establecimientos" [ngValue]="e">{{ e.establecimiento }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <select
                    *ngIf="establecimiento"
                    [(ngModel)]="grupoUsuario"
                    (change)="paginator.changePage(1)"
                    (change)="setPropietarios()"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- Seleccione un curso --</option>
                    <option *ngFor="let gu of establecimiento.grupo_usuarios" [ngValue]="gu">{{ gu.nombre }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <select
                    *ngIf="establecimiento"
                    [(ngModel)]="propietario"
                    (change)="paginator.changePage(1)"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- Seleccione docente --</option>
                    <option *ngFor="let p of propietarios" [ngValue]="p">{{ p.nombreCompleto() }}</option>
                </select>
            </div>
        </div>
    </div>
    <table class="table tabla_planes_personales" *ngIf="planesPersonales && planesPersonales.length > 0">
        <thead>
            <tr>
                <th>Planes Personales</th>
                <th class="actions">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let planPersonal of planesPersonales; let i = index">
                <td>
                    <div class="table-cell-plan-personal row">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <a [routerLink]="['/plan_personal', planPersonal.id]" class="titulo">
                                        <ogr-icon name="plan-estudio" class="icono"></ogr-icon>
                                        {{ planPersonal.plan_personal }}
                                    </a>
                                    <div class="plan-personal-data-pair">
                                        <p>
                                            <span class="inline-label">Plan Personal ID:</span>
                                            #{{ planPersonal.id }}
                                        </p>
                                    </div>
                                    <!-- <div class="plan-personal-data-pair">
                                        <p><span class="inline-label">Instrumento ID:</span>#{{planPersonal.instrumento_id}}</p>
                                    </div> -->
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="plan-personal-data-pair">
                                        <p>
                                            <span class="inline-label">Tipo:</span>
                                            {{ planPersonal.generador_instrumento.generador_instrumento }}
                                        </p>
                                    </div>
                                    <div
                                        class="plan-personal-data-pair"
                                        *ngIf="establecimientos && establecimientos.length > 1"
                                    >
                                        <p>
                                            <span class="inline-label">Establecimiento:</span>
                                            {{
                                                planPersonal.plan_personal_usuarios[0].grupo_usuario.establecimiento
                                                    .establecimiento
                                            }}
                                        </p>
                                        <!-- <p><span class="inline-label">Establecimiento:</span>{{planPersonal.plan_personal_usuarios[0].grupo_usuario.establecimiento ? 'TieneEst' : 'NOTieneEst' }}</p> -->
                                    </div>
                                    <div class="plan-personal-data-pair">
                                        <p>
                                            <span class="inline-label">Creador:</span>
                                            <fa name="user" class="icono"></fa>
                                            {{ planPersonal.usuario?.nombreCompleto() }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <div class="plan-personal-data-pair">
                                        <p>
                                            <span class="inline-label date-label">Creación:</span>
                                            <span class="glyphicon glyphicon-time icono tiempo"></span>
                                            {{ planPersonal.created_at | date: "dd-MM-yyyy HH:mm" }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="plan-personal-data-pair">
                                        <p><span class="inline-label">Compartido con:</span></p>
                                    </div>
                                    <div toggleExpandable="eval_comp_curs_{{ i }}" class="tog_expand_cursos clearfix">
                                        {{ nombreGrupo | pluralize | titlecase }} ({{
                                            planPersonal.plan_personal_usuarios.length
                                        }})
                                        <span class="tog-icon plus"><fa [name]="'plus-square'"></fa></span>
                                        <span class="tog-icon minus"><fa [name]="'minus-square'"></fa></span>
                                    </div>
                                    <div id="eval_comp_curs_{{ i }}" class="expand_cursos" [expandableContent]>
                                        <p *ngFor="let plan_personal_usuario of planPersonal.plan_personal_usuarios">
                                            {{ plan_personal_usuario.grupo_usuario.nombre }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="actions">
                    <a [routerLink]="['/planes_personales/tareas', planPersonal.id, 'avance']" class="btn btn-table">
                        Ver avance
                    </a>
                    <a
                        [routerLink]="[rutaPlanPersonales || '/planes_personales', planPersonal.id]"
                        class="btn btn-table"
                    >
                        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                    </a>
                    <!-- <button *ngIf="presencial == 0" type="button" (click)="editarPlanPersonal(planPersonal)" class="btn btn-table">
                        <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                    </button> -->
                    <button type="button" (click)="compartirPlanPersonal(planPersonal)" class="btn btn-table">
                        <span class="glyphicon glyphicon-share-alt" aria-hidden="true"></span>
                    </button>
                    <!-- <a (click)="onVerEstadisticas(planPersonal.plan_personal_usuarios[0].grupo_usuario.establecimiento.id, null, planPersonal.id, planPersonal.generador_instrumento_id, planPersonal.generador_instrumento.tipo_instrumento.tipo_instrumento)" class="btn btn-table">
                        <fa [name]="'bar-chart'"></fa>
                    </a> -->
                </td>
            </tr>
        </tbody>
    </table>
    <p *ngIf="(!planesPersonales || planesPersonales.length <= 0) && establecimientos?.length != 0" class="not-found">
        No se encontraron {{ planesPersonalesMinusculaPluralString }} compartid{{ planesPersonalesGeneroString }}.
    </p>
    <p *ngIf="!establecimientos || establecimientos.length == 0">Usted no posee grupos asociados.</p>
    <paginator
        *ngIf="establecimientos && establecimientos.length > 0 && fechaInicial && fechaFinal"
        [getList]="setData"
    ></paginator>
    <compartir-plan-personal-profesor
        [planPersonal]="planPersonal"
        [modalId]="'plan-personal-compartido'"
        [closeOnFinished]="true"
        (planCompartidoEvent)="onPlanCompartidoListener($event)"
    ></compartir-plan-personal-profesor>
    <!-- <editar-plan-personal *ngIf="presencial == 0" [evaluacion]="evaluacionToEdit" [openModalEdit]="openModalEdit" [tipoEvaluacion]="evaluacionTipo" [paginator]="paginator"></editar-plan-personal> -->
</loading-layout>
