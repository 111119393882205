import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PlanPersonales } from "@puntaje/puntaje/api-services"

@Component({
    selector: "grafico-evolutivo-clase-seguimiento",
    templateUrl: "./grafico-evolutivo-clase-seguimiento.component.html",
    styleUrls: ["./grafico-evolutivo-clase-seguimiento.component.scss"]
})
export class GraficoEvolutivoClaseSeguimientoComponent implements OnInit {
    @Input() idPlanificacion: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    data = []
    clases: any
    grupoUsuario_id: number
    usuario_id: number

    constructor(protected planPersonalesService: PlanPersonales) {}

    ngOnInit() {
        this.obtenerDatos()
    }

    obtenerDatos() {
        this.data = []
        let params = {
            id: this.idPlanificacion,
            grupoUsuario_id: this.grupoUsuario_id,
            usuario_id: this.usuario_id
        }

        this.planPersonalesService.datosEvolucionEvaluacion(params).then(clases => {
            this.clases = clases
            for (let clase of clases) {
                for (let evaluacion of clase.evaluaciones) {
                    let a = {}
                    a["nombre"] = clase.nombre
                    if (evaluacion.promedio) {
                        a["data"] = [[evaluacion.evaluacion, evaluacion.promedio]]
                    } else {
                        a["data"] = [[evaluacion.evaluacion, 0]]
                    }
                    this.data.push(a)
                }
            }
            this.loadingLayout.ready()
        })
    }

    enviarCurso(event: number) {
        this.usuario_id = null
        this.grupoUsuario_id = event
        this.loadingLayout.standby()
        this.obtenerDatos()
    }

    enviarUsuario(event: number) {
        this.usuario_id = event
        this.loadingLayout.standby()
        this.obtenerDatos()
    }
}
