<ng-template [ngIf]="!hasHijos">
    <h5 class="clickable" [toggleExpandable]="id">
        {{ name }}
        <span class="tog-icon plus"><fa [name]="'plus-square'"></fa></span>
        <span class="tog-icon minus"><fa [name]="'minus-square'"></fa></span>
    </h5>
</ng-template>
<ng-template [ngIf]="!hasHijos">
    <div class="checkbox-list" [id]="id" [expandableContent]>
        <div class="inner-expandable">
            <div class="checkbox" *ngFor="let clasificacion of clasificaciones">
                <label>
                    <input
                        type="checkbox"
                        checked="checked"
                        name="clasificacion{{ clasificacion.clasificacion_tipo_id }}_{{ clasificacion.id }}"
                        [(ngModel)]="$any(clasificacion).selected"
                        (ngModelChange)="clasificacionesEmit()"
                    />
                    {{ clasificacion.clasificacion }}
                    <ng-template
                        [ngIf]="concats && clasificacion[concats[clasificacion.clasificacion_tipo.clasificacion_tipo]]"
                    >
                        ({{
                            clasificacion[concats[clasificacion.clasificacion_tipo.clasificacion_tipo]]?.clasificacion
                        }})
                    </ng-template>
                </label>

                <div
                    *ngIf="enableCantidadPreguntas"
                    class="numero-preguntas clearfix"
                    tooltip="{{ clasificacion.cantidad_preguntas }} pregunta{{
                        clasificacion.cantidad_preguntas != 1 ? 's' : ''
                    }}"
                >
                    {{ clasificacion.cantidad_preguntas }} p.
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="hasHijos">
    <h5>
        {{ name }}
    </h5>
</ng-template>
<ng-template [ngIf]="hasHijos">
    <!-- <div class="checkbox-list" [id]="id" expandableContent> -->
    <div class="checkbox-list" [id]="id">
        <div class="row">
            <ng-template ngFor let-clasificacion [ngForOf]="clasificaciones" let-i="index">
                <div class="col-lg-12 col-md-12">
                    <div class="checkbox expand-checkbox">
                        <div class="expand-checkbox-inner">
                            <label>
                                <input
                                    type="checkbox"
                                    checked="checked"
                                    name="clasificacion{{ clasificacion.clasificacion_tipo_id }}_{{ clasificacion.id }}"
                                    [(ngModel)]="$any(clasificacion).selected"
                                    (ngModelChange)="clasificacionPadreChange(clasificacion)"
                                />
                                {{ clasificacion.clasificacion }}

                                <ng-template
                                    [ngIf]="
                                        concats &&
                                        clasificacion[concats[clasificacion.clasificacion_tipo.clasificacion_tipo]]
                                    "
                                >
                                    ({{
                                        clasificacion[concats[clasificacion.clasificacion_tipo.clasificacion_tipo]]
                                            ?.clasificacion
                                    }})
                                </ng-template>
                            </label>

                            <cui-button
                                *ngIf="
                                    clasificacion.clasificaciones_hijas &&
                                    clasificacion.clasificaciones_hijas.length != 0
                                "
                                type="button"
                                class="tog-icon-2 btn_style btn-sm"
                                toggleExpandable="subclasificaciones_{{ i }}"
                            >
                                <span class="plus"><fa [name]="'plus-square'"></fa></span>
                                <span class="minus"><fa [name]="'minus-square'"></fa></span>
                                {{ "general.ver_mas" | t }}
                            </cui-button>
                            <div
                                *ngIf="enableCantidadPreguntas"
                                class="numero-preguntas"
                                tooltip="{{ clasificacion.cantidad_preguntas }} pregunta{{
                                    clasificacion.cantidad_preguntas != 1 ? 's' : ''
                                }}"
                            >
                                {{ clasificacion.cantidad_preguntas }} p.
                            </div>
                        </div>
                    </div>
                    <!-- <div *ngIf="clasificacion.clasificaciones_hijas && clasificacion.clasificaciones_hijas.length != 0" class="checkbox-list small-checkboxes" id="subclasificaciones_{{i}}" expandableContent> -->
                    <div
                        *ngIf="clasificacion.clasificaciones_hijas && clasificacion.clasificaciones_hijas.length != 0"
                        class="checkbox-list small-checkboxes"
                        id="subclasificaciones_{{ i }}"
                    >
                        <div>
                            <div class="row">
                                <div
                                    class="checkbox form-subeje tooltip-container"
                                    *ngFor="let clasificacionHija of clasificacion.clasificaciones_hijas"
                                >
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="tooltip-btn" *ngIf="clasificacionHija.descripcion">
                                            <cui-button
                                                buttonType="confirm"
                                                [toggleExpandable]="'subcla-' + clasificacionHija.id"
                                                type="button"
                                                class="btn_style_3 btn-sm"
                                            >
                                                {{ "general.detalles" | t }}
                                            </cui-button>
                                        </div>
                                        <div
                                            *ngIf="enableCantidadPreguntas"
                                            class="numero-preguntas"
                                            tooltip="{{ clasificacionHija.cantidad_preguntas }} pregunta{{
                                                clasificacionHija.cantidad_preguntas != 1 ? 's' : ''
                                            }}"
                                        >
                                            {{ clasificacionHija.cantidad_preguntas }} p.
                                        </div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked="checked"
                                                [(ngModel)]="$any(clasificacionHija).selected"
                                                (ngModelChange)="clasificacionChange(clasificacion)"
                                                name="eje_{{ clasificacionHija.id }}"
                                            />
                                            {{ clasificacionHija.clasificacion }}

                                            <ng-template
                                                [ngIf]="
                                                    concats &&
                                                    clasificacionHija[
                                                        concats[clasificacionHija.clasificacion_tipo.clasificacion_tipo]
                                                    ]
                                                "
                                            >
                                                ({{
                                                    clasificacionHija[
                                                        concats[clasificacionHija.clasificacion_tipo.clasificacion_tipo]
                                                    ]?.clasificacion
                                                }})
                                            </ng-template>
                                        </label>
                                        <div
                                            id="subcla-{{ clasificacionHija.id }}"
                                            [expandableContent]
                                            [attr.data-mutual-exclusive]="'cla-' + clasificacion.id"
                                        >
                                            <div class="descripcion-inner">
                                                <div
                                                    class="descripcion-sub"
                                                    [innerHtml]="clasificacionHija.descripcion"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>
