import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Asignatura, Asignaturas, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { AuthService, PaginatorComponent } from "@puntaje/shared/core"
import {
    Establecimiento,
    Establecimientos,
    GrupoUsuario,
    Instrumento,
    Instrumentos,
    PlanPersonal,
    PlanPersonales,
    Usuario
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "profesores-planificaciones-compartidas",
    templateUrl: "./profesores-planificaciones-compartidas.component.html",
    styleUrls: ["./profesores-planificaciones-compartidas.component.scss"]
})
export class ProfesoresPlanificacionesCompartidasComponent implements OnInit {
    _asignaturaId: number
    _generadorInstrumentos: GeneradorInstrumento[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    instrumento: Instrumento
    planificacionToEdit: PlanPersonal
    @Input() tipoId: number = 1
    @Input() rutaPlanificaciones: string
    @Input() enableCompartir: boolean = true
    @Input() showEstadistica: boolean = true
    openModal: EventEmitter<any> = new EventEmitter<any>()
    openModalEdit: EventEmitter<any> = new EventEmitter<any>()
    planificacionesString: string = "Planificaciones"
    planificacionesMinusculaPluralString: string = "planificaciones"
    planificacionesGeneroString: string = "as"
    nombreGrupo: string
    planificacion: PlanPersonal
    fechaInicial: Date
    fechaFinal: Date

    @Input() evaluacionTipo: string

    @Input()
    set asignaturaId(id: number) {
        this._asignaturaId = id
    }

    get asignaturaId() {
        return this._asignaturaId
    }

    @Input()
    set generadorInstrumentos(gis: GeneradorInstrumento[]) {
        let old = this._generadorInstrumentos
        this._generadorInstrumentos = gis
        old && this.paginator && this.paginator.reload()
    }

    get generadorInstrumentos() {
        return this._generadorInstrumentos
    }

    establecimiento: Establecimiento
    grupoUsuario: GrupoUsuario

    asignatura: Asignatura
    planificaciones: PlanPersonal[]
    establecimientos: Establecimiento[]
    propietarios: Usuario[]
    propietario: Usuario
    usuario: Usuario

    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    // @ViewChild(CompartirPlanificacionProfesorComponent) compartirPlanPersonalProfesorComponent: CompartirPlanPersonalProfesorComponent;

    constructor(
        //protected filtroEstadisticasService: FiltroEstadisticasService,
        protected router: Router,
        protected authService: AuthService,
        protected asignaturasService: Asignaturas,
        protected planificacionesService: PlanPersonales,
        protected establecimientosService: Establecimientos,
        protected instrumentosService: Instrumentos,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.usuario = this.authService.getUserData()

        this.nombreGrupo = config.plataforma.grupoUsuarioAlias || "cursos"
        if (!this.establecimientos) {
            this.loadingLayout.standby()
        }
    }

    setEstablecimientos() {
        let params = {
            mios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            establecimiento: { activo: 1 },
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            if (this.establecimientos.length == 1) {
                this.establecimiento = this.establecimientos[0]
                this.setPropietarios()
            } else if (this.establecimientos.length == 0) {
                this.establecimiento = undefined
                this.loadingLayout.ready()
            }

            if (this.paginator) this.paginator.reload()
        })
    }

    // setPlanPersonalTipo(planPersonalTipo) {
    //     this.planPersonalTipo = planPersonalTipo;

    //     this.paginator.changePage(1);
    // }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicial = fechaInicial
        this.fechaFinal = fechaFinal

        this.setEstablecimientos()
    }

    setData = (page: number, per: number) => {
        this.loadingLayout.standby()

        let params = {
            page: page,
            per: per,
            mis_colegios: 1,
            with_instrumento: 1,
            plan_personal: {
                generador_instrumento_id: this.generadorInstrumentos.map(g => g.id)
            },
            plan_personal_tipo: {
                tipo: "planificacion"
            },
            order: "desc",
            sort_by: "created_at",
            render_options: {
                include: {
                    usuario: null,
                    generador_instrumento: null,
                    plan_personal_usuarios: {
                        include: {
                            grupo_usuario: {
                                include: "establecimiento"
                            }
                        }
                    }
                },
                methods: ["total_sesiones", "porcentaje_transcurrido"]
            }
        }

        // parametros definidos en filtro en html
        this.establecimiento && (params["establecimiento"] = { id: this.establecimiento.id })
        this.grupoUsuario && (params["grupo_usuario"] = { id: this.grupoUsuario.id })
        this.propietario &&
            params["plan_personal_usuario"] &&
            (params["plan_personal_usuario"]["usuario_id"] = this.propietario.id)
        if (this.generadorInstrumentos)
            params["generador_instrumento_ids"] = this.generadorInstrumentos.map(gi => gi.id)

        this.cdr.detectChanges()
        return this.planificacionesService.where(params).then((planificaciones: PlanPersonal[], total: number) => {
            this.planificaciones = planificaciones
            this.loadingLayout.ready()
            return total
        })
    }

    // onVerEstadisticas(establecimientoId, grupoUsuarioId, planPersonalId, generadorInstrumentoId, tipoInstrumento) {
    //     this.filtroEstadisticasService.setFiltrosId(establecimientoId, grupoUsuarioId, planPersonalId, generadorInstrumentoId);
    //     this.router.navigate(["/estadisticas"], {queryParams: {tipo_instrumento: tipoInstrumento, asignatura_id: this.asignaturaId}});
    // }

    // compartirPlanPersonal(planPersonal: PlanPersonal) {
    //     this.planPersonal = planPersonal;
    //     this.openCompartir();
    // }

    // openCompartir() {
    // 	this.compartirPlanPersonalProfesorComponent.open();
    // }

    // onPlanCompartidoListener(value: any) {
    //     this.paginator.reload();
    // }

    // editarPlanPersonal(planPersonal: PlanPersonal) {
    //     this.planPersonalToEdit = planPersonal;
    //     this.openModalEdit.emit();
    // }

    setPropietarios() {
        this.propietarios = []
        this.propietario = undefined
        if (this.establecimiento) {
            if (this.grupoUsuario) {
                this.propietarios = this.grupoUsuario.propietarios
            } else {
                this.establecimiento.grupo_usuarios.forEach(g => {
                    g.propietarios.forEach(p => {
                        let prop = this.propietarios.find(pr => pr.id == p.id)
                        if (!prop) {
                            this.propietarios.push(p)
                        }
                    })
                })
            }
        }

        this.propietario = this.propietarios.find(p => p.id == this.usuario.id)
    }

    compartirPlanificacion(obj) {}
}
