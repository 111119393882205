<cui-section-headline>Docentes destacados</cui-section-headline>
<loading-layout #loadingLayout>
    <div class="filter-container">
        <div class="filter">
            <select class="form-control push-down" id="establecimientos" [(ngModel)]="asociacion" (change)="getData()">
                <option *ngFor="let a of asociaciones" [ngValue]="a">{{ a.asociacion }}</option>
            </select>
        </div>
        <div class="filter">
            <filtro-periodo class="periodo" (select)="onSelectPeriodo($event)"></filtro-periodo>
        </div>
    </div>
    <table class="table tabla-docentes" *ngIf="data?.length > 0">
        <thead>
            <tr>
                <th rowspan="2">Docente</th>
                <th rowspan="2">Establecimiento</th>
                <th colspan="3" class="center">Evaluaciones</th>
                <th rowspan="2" class="center">Instrumentos generados</th>
            </tr>
            <tr>
                <th class="center">No presenciales</th>
                <th class="center">Presenciales</th>
                <th class="center">Totales</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let d of data">
                <td>
                    <fa class="icono" name="graduation-cap"></fa>
                    {{ d.docente.nombre }} {{ d.docente.apellido_paterno }}
                </td>
                <td>
                    <div *ngFor="let e of d.establecimiento">{{ e.establecimiento }}</div>
                </td>
                <td class="center">{{ d.n_ensayos_no_presenciales }}</td>
                <td class="center">{{ d.n_ensayos_presenciales }}</td>
                <td class="center">{{ d.n_ensayos }}</td>
                <td class="center">{{ d.instrumentos_generados }}</td>
            </tr>
        </tbody>
    </table>
    <p class="not-found" *ngIf="data?.length == 0">
        Lista de docentes destacados no disponible para los criterios seleccionados.
    </p>
</loading-layout>
