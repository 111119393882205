<div class="filtro">
    <div class="periodo">
        <filtro-periodo (select)="onSelectPeriodo($event)"></filtro-periodo>
    </div>
    <h4>
        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
        Filtrar datos
    </h4>
    <div class="form-group">
        <select class="form-control" [(ngModel)]="generadorInstrumento" (change)="updatePlanes()">
            <option [ngValue]="null">Seleccione Generador</option>
            <option *ngFor="let generador of _asignatura.generador_instrumentos" [ngValue]="generador">
                {{ generador.generador_instrumento }}
            </option>
        </select>

        <select class="form-control" [(ngModel)]="establecimiento">
            <option selected [ngValue]="null">Seleccione Establecimiento</option>
            <option *ngFor="let establecimiento of establecimientos" [ngValue]="establecimiento">
                {{ establecimiento.establecimiento }}
            </option>
        </select>

        <select class="form-control" [(ngModel)]="grupoUsuario" (change)="updatePlanes()">
            <option selected [ngValue]="null">Seleccione Curso</option>
            <ng-container *ngIf="establecimiento">
                <option *ngFor="let grupo_usuario of establecimiento.grupo_usuarios" [ngValue]="grupo_usuario">
                    {{ grupo_usuario.nombre }}
                </option>
            </ng-container>
        </select>

        <select class="form-control" [(ngModel)]="planId">
            <option [ngValue]="null">Seleccione Plan Personal</option>
            <option *ngFor="let plan of planes" [ngValue]="plan.id">{{ plan.plan_personal }}</option>
        </select>
    </div>
    <cui-button type="button" class="btn_style pull-right" (click)="onFiltro()">
        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
        Filtrar resultados
    </cui-button>
</div>
