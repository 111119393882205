<popup-grupo-usuarios
    [grupoUsuarios]="grupoUsuarios"
    [sharing]="sharing"
    [modalId]="modalId"
    (action)="compartir($event)"
>
    <ng-container ngProjectAs="titulo">Compartir Plan Personal</ng-container>

    <ng-container ngProjectAs="titulo-grupos">
        {{ nombreGrupo | pluralize | capitalize }} a los cuales compartir el plan personal:
    </ng-container>

    <ng-container ngProjectAs="mensaje-sin-grupos">
        Usted no puede compartir una evaluación: No posee {{ nombreGrupo | pluralize }} activos.
    </ng-container>

    <ng-container ngProjectAs="nombre-submit">Compartir</ng-container>
</popup-grupo-usuarios>
