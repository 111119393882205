<nav class="navbar" aria-label="Navegación Usuario">
    <div class="container-fluid">
        <div class="navbar-content">
            <div class="navbar-left">
                <button type="button" class="topmenu-toggle" (click)="toggleTopmenu()">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <div class="navbar-logo-container">
                    <a routerLink="/home">
                        <img
                            class="navbar-logo"
                            src="{{ config.app.assets.logoSmall }}"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                        <img
                            class="navbar-logo-res"
                            src="{{ config.app.assets.logoSquare }}"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                </div>
            </div>
            <div class="user-menu">
                <ul class="nav navbar-top-links navbar-right">
                    <li>
                        <a routerLink="/home" role="button">
                            <ogr-icon class="centered-icons" name="home-o"></ogr-icon>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/notificaciones" role="button" class="alerta-container">
                            <span class="centered-icons">
                                <ogr-icon class="centered-icons" name="campana-o"></ogr-icon>
                            </span>
                            <alerta-notificaciones class="alerta"></alerta-notificaciones>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/usuarios', usuario_id]">
                            <span title="{{ usuario_nombre | easyplaceholder: 'Usuario' }}" class="user-name">
                                {{ usuario_nombre | easyplaceholder: "Usuario" }}
                            </span>
                            <fa name="user-o" class="centered-icons user-name-res"></fa>
                        </a>
                    </li>
                    <li class="dropdown last-list-item">
                        <a class="dropdown-toggle" aria-label="Menú de usuario" data-toggle="dropdown" href="#">
                            <div class="clearfix last-item-cont">
                                <div class="user-circle-container pull-left">
                                    <img
                                        *ngIf="usuario_avatar"
                                        class="avatar"
                                        aria-label="Avatar Usuario"
                                        [src]="usuario_avatar_safe"
                                    />
                                    <fa
                                        *ngIf="!usuario_avatar"
                                        class="fa-placeholder"
                                        aria-label="Avatar Usuario por Defecto"
                                        [name]="'user'"
                                    ></fa>
                                </div>
                                <div class="pull-right little-arrow-user">
                                    <fa [name]="'sort-desc'"></fa>
                                </div>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-user">
                            <li>
                                <a (click)="edit()">Editar cuenta</a>
                            </li>
                            <li>
                                <a (click)="logout()">Cerrar sesión</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="topmenu-container">
        <topmenu
            *ngIf="showTopmenu"
            class="topmenu"
            [config]="config"
            (toggleTopmenuEvent)="toggleTopmenu()"
            @topmenu
        ></topmenu>
    </div>
</nav>
