import { Component, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "planificacion-predefinida",
    templateUrl: "./planificacion-predefinida.component.html",
    styleUrls: ["./planificacion-predefinida.component.scss"]
})
export class PlanificacionPredefinidaComponent implements OnInit {
    planificacionId: number
    sub: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.planificaciones.predefinida")
        this.sub = this.route.params.subscribe(params => (this.planificacionId = +params["id"]))
    }
}
