<cui-section-headline>Avance</cui-section-headline>
<div class="icon-wrap">
    <ogr-icon class="icono" name="planificacion-o"></ogr-icon>
    <div class="contenido-barra">
        <label class="label-barra">Avance estimado</label>
        <div class="barra-wrap">
            <div class="barra-avance" [class.fullwidth]="startAnimValues">
                <div class="avance-esperado" [style.width]="avanceEsperado + '%'"></div>
            </div>
        </div>
        <label class="label-barra">Clase actual</label>
        <div class="barra-wrap">
            <div class="barra-avance" [class.fullwidth]="startAnimValues">
                <div class="avance" [style.width]="avance + '%'"></div>
            </div>
        </div>
        <div class="fechas">
            <p *ngIf="startDate">{{ startDate | date: "MMMM y" | capitalize }}</p>
            <p *ngIf="endDate">{{ endDate | date: "MMMM y" | capitalize }}</p>
        </div>
    </div>
</div>
