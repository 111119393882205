<loading-layout #loadingLayout>
    <cui-section-headline>Datos Generales del Plan Personal</cui-section-headline>
    <div class="info-container clearfix">
        <div class="left-container">
            <div class="info-container-icon">
                <ogr-icon name="plan-estudio"></ogr-icon>
            </div>
        </div>
        <div class="info-container-info">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <h4>Plan Personal #{{ planPersonal.id }}</h4>
                    <div class="info-mini-table">
                        <div class="info-mini-container">
                            <div class="mini-container-header">Nombre</div>
                            <div class="mini-container-content">
                                {{ planPersonal.plan_personal }}
                            </div>
                        </div>
                    </div>
                    <div class="info-mini-table">
                        <div class="info-mini-container">
                            <div class="mini-container-header">
                                <ogr-icon name="clock" class="clock"></ogr-icon>
                                Creación
                            </div>
                            <div class="mini-container-content">
                                {{
                                    planPersonal.created_at !== null
                                        ? (planPersonal.created_at | date: "dd/MM/yyyy HH:mm")
                                        : ""
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="info-mini-table">
                        <div class="info-mini-container">
                            <div class="mini-container-header">Tipo</div>
                            <div class="mini-container-content">
                                {{ planPersonal.plan_personal_tipo.tipo }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="grupos-info">
                        <label>Compartido con:</label>
                        <table class="table tabla-grupos">
                            <thead>
                                <tr>
                                    <th>{{ nombreGrupo | capitalize }}</th>
                                    <th>Establecimiento</th>
                                    <th>Fecha</th>
                                    <th class="acciones">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let g of planPersonal.plan_personal_usuarios">
                                    <ng-container *ngIf="g.grupo_usuario">
                                        <td>{{ g.grupo_usuario.nombre }}</td>
                                        <td>{{ g.grupo_usuario.establecimiento.establecimiento }}</td>
                                        <td>
                                            {{ g.created_at !== null ? (g.created_at | date: "dd/MM/yyyy HH:mm") : "" }}
                                        </td>
                                        <td class="acciones">
                                            <button
                                                type="button"
                                                (click)="
                                                    onVerEstadisticas(
                                                        g.grupo_usuario.establecimiento.id,
                                                        g.grupo_usuario.id,
                                                        planPersonal.id,
                                                        planPersonal.generador_instrumento_id,
                                                        planPersonal.generador_instrumento.asignatura.id
                                                    )
                                                "
                                                class="btn btn-table"
                                            >
                                                <fa [name]="'bar-chart'"></fa>
                                            </button>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
