<loading-layout #loadingLayout>
    <div class="components-wrap" *ngIf="componentesReady">
        <div *ngFor="let component of planPersonalComponentes; let i = index" class="component-container">
            <ng-container *ngIf="component.tipo == 'Material'">
                <span class="component-icon">
                    <ogr-icon [name]="getTypeIcon(component)" class="icono"></ogr-icon>
                </span>
                <h3 class="component-title">
                    Material:
                    <a (click)="togglePreview(i)">{{ component.material.material }}</a>
                </h3>
                <a class="preview-link" (click)="togglePreview(i)">Ver material</a>
                <div class="preview-window" *ngIf="currentPreviewIndex == i">
                    <h4 class="preview-title">Vista previa</h4>
                    <button class="btn close-preview" (click)="togglePreview(i)"><fa name="times"></fa></button>
                    <material-preview [material]="component.material"></material-preview>
                </div>
            </ng-container>
            <ng-container *ngIf="component.tipo == 'Evaluacion'">
                <span class="component-icon">
                    <ogr-icon name="hacer-prueba" class="icono"></ogr-icon>
                </span>
                <h3 class="component-title">
                    Evaluación:
                    <a (click)="togglePreview(i)">Instrumento #{{ component.evaluacion.instrumento.id }}</a>
                </h3>
                <a class="preview-link" (click)="togglePreview(i)">Ver evaluación</a>
                <div class="preview-window" *ngIf="currentPreviewIndex == i">
                    <h4 class="preview-title">Vista previa</h4>
                    <button class="btn close-preview" (click)="togglePreview(i)"><fa name="times"></fa></button>
                    <instrumento
                        *ngIf="currentPreviewIndex == i"
                        [instrumento]="component.evaluacion.instrumento"
                        [enableReporte]="enableReporte"
                    ></instrumento>
                </div>
            </ng-container>
            <span *ngIf="i < planPersonalComponentes.length - 1" class="component-line"></span>
        </div>
    </div>
</loading-layout>
