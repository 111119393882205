<cui-section-headline>Planes Personales no compartidos</cui-section-headline>
<loading-layout #loadingLayout>
    <table class="table tabla_planes_personales color-scheme-2" *ngIf="planesPersonales && planesPersonales.length > 0">
        <thead>
            <tr>
                <th>ID</th>
                <th>Tipo</th>
                <th>Creador</th>
                <th>Fecha creación</th>
                <th class="actions">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let planPersonal of planesPersonales">
                <td>{{ planPersonal.id }}</td>
                <td>{{ planPersonal.generador_instrumento.generador_instrumento }}</td>
                <td>{{ planPersonal.usuario.nombreCompleto() }}</td>
                <td>{{ planPersonal.created_at | prettydate: "-" }}</td>
                <td class="actions">
                    <a [routerLink]="['/planes_personales', planPersonal.id]" class="btn btn-table">
                        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                    </a>
                    <button type="button" (click)="compartirPlanPersonal(planPersonal)" class="btn btn-table">
                        <span class="glyphicon glyphicon-share-alt" aria-hidden="true"></span>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <paginator [getList]="setData.bind(this)"></paginator>
    <compartir-plan-personal-profesor
        [planPersonal]="planPersonal"
        [modalId]="'plan-personal-no-compartido'"
        [closeOnFinished]="true"
        (planCompartidoEvent)="onPlanCompartidoListener($event)"
    ></compartir-plan-personal-profesor>
    <p *ngIf="!planesPersonales || planesPersonales.length <= 0" class="not-found">
        No se encontraron planes personales en esta categoría.
    </p>
</loading-layout>
