import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"
import { PlanPersonal, PlanPersonalPredefinido, PlanPersonalPredefinidos } from "@puntaje/puntaje/api-services"
import { combineLatest, Observable, Subject } from "rxjs"
import { map, startWith, tap } from "rxjs/operators"
import { AplicarPlanPersonalProfesoresComponent } from "../aplicar-plan-personal-profesores/aplicar-plan-personal-profesores.component"

interface EditingSesionFields {
    [sesionId: number]: {
        dateRangeError: boolean
    }
}

@Component({
    selector: "plan-personal-predefinido-profesor",
    templateUrl: "./plan-personal-predefinido-profesor.component.html",
    styleUrls: ["./plan-personal-predefinido-profesor.component.scss"]
})
export class PlanPersonalPredefinidoProfesorComponent implements OnInit {
    @Input() planificacionId: number
    @Input() enableReporte: boolean = true
    planPersonal: PlanPersonal
    planificacion$: Observable<PlanPersonalPredefinido>
    planificacion: PlanPersonalPredefinido
    hasSesiones: boolean = true
    hasSeguimiento: boolean

    editingSesionFields: EditingSesionFields = {}
    sesionesActivas: number[] = []
    planPersonalPadreId: number

    modalEvaluacionId: number
    evaluacionesIdPorSesion: any = {}

    fechasSubject = new Subject()

    @ViewChild(AplicarPlanPersonalProfesoresComponent)
    aplicarPlanPersonalComponent: AplicarPlanPersonalProfesoresComponent
    @ViewChild("loading", { static: true }) loading: LoadingLayoutComponent

    constructor(
        protected planPersonalPredefinidosService: PlanPersonalPredefinidos,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.loading.standby()
        this.planificacion$ = combineLatest(
            this.planPersonalPredefinidosService.find(this.planificacionId).then(this.planificacionLimpia),
            this.fechasSubject.pipe(startWith(null))
        ).pipe(
            map(([planificacion, _]) => {
                planificacion.plan_personal.plan_personal_ciclos[0].plan_personal_sesiones =
                    planificacion.plan_personal.plan_personal_ciclos[0].plan_personal_sesiones.sort((pps1, pps2) => {
                        const fechaInicial1 = new Date(pps1.fecha_inicial)
                        const fechaInicial2 = new Date(pps2.fecha_inicial)

                        return fechaInicial1.getTime() - fechaInicial2.getTime()
                    })

                return planificacion
            }),
            tap(_ => this.loading.ready())
        )
    }

    planificacionLimpia = (predef: PlanPersonalPredefinido) => {
        this.planPersonalPadreId = predef.plan_personal.id
        let sesionIndex = 0

        predef.id = null
        predef.plan_personal.id = null
        predef.plan_personal.plan_personal_ciclos.forEach(ciclo => {
            ciclo.id = null
            this.sesionesActivas = new Array(ciclo.plan_personal_sesiones.length).fill(true)
            ciclo.plan_personal_sesiones.forEach(sesion => {
                sesion.id = null

                this.editingSesionFields[sesionIndex] = {
                    dateRangeError: false
                }

                this.evaluacionesIdPorSesion[sesionIndex] = {}

                sesion.plan_personal_contenidos.forEach((contenido, index) => {
                    contenido.id = null
                    this.evaluacionesIdPorSesion[sesionIndex]["contenido"] = {}
                    if (contenido.evaluacion) {
                        this.evaluacionesIdPorSesion[sesionIndex]["contenido"][index] = contenido.evaluacion.id
                        contenido.evaluacion.id = null
                    }
                })

                sesionIndex++
            })
        })

        this.planificacion = predef
        this.planPersonal = predef.plan_personal.clone()
        this.hasSeguimiento =
            this.planPersonal.generador_instrumento.tipo_instrumento.tipo_instrumento == "ensayo" ||
            this.planPersonal.generador_instrumento.tipo_instrumento.tipo_instrumento == "prueba de transición"

        return predef
    }

    aplicar() {
        const shouldApply = Object.keys(this.editingSesionFields)
            .filter(id => this.sesionesActivas[id])
            .every(sesionId => !this.editingSesionFields[sesionId].dateRangeError)

        this.updateSesionesActivas()
        this.setReferencias()

        if (this.hasSesiones && shouldApply) this.aplicarPlanPersonalComponent.open()
    }

    setReferencias() {
        this.planPersonal.usuario_id = this.authService.getUserData().id
        this.planPersonal.plan_personal_padre_id = this.planPersonalPadreId
    }

    updateSesionesActivas() {
        this.cdr.detectChanges()
        const sesiones = this.planificacion.plan_personal.plan_personal_ciclos[0].plan_personal_sesiones.filter(
            (sesion, index) => this.sesionesActivas[index]
        )

        this.planPersonal.plan_personal_ciclos[0].plan_personal_sesiones = sesiones

        this.hasSesiones = sesiones.length > 0
    }

    onPlanCompartido(planPersonal: PlanPersonal) {}

    updateSesionActiva(event) {
        this.sesionesActivas[event.index] = event.activa
        this.updateSesionesActivas()
    }

    updateEditingSesiones(event) {
        let sesionIndex = event.index
        let dataRangeError = event.fields.dateRangeError
        let clase = event.clase

        this.planificacion.plan_personal.plan_personal_ciclos[0].plan_personal_sesiones[sesionIndex] = clase
        this.editingSesionFields[sesionIndex].dateRangeError = dataRangeError
    }

    updateOrdenByFechas() {
        this.fechasSubject.next()
    }
}
