<footer>
    <div class="footer-wrap clearfix">
        <div class="row">
            <div class="col-md-3 col-sm-6 footer-col">
                <h3>Navegación</h3>
                <ul>
                    <li routerLink="/home"><a>Inicio</a></li>
                    <li routerLink="/ensayos/generar_ensayo"><a>Evaluar</a></li>
                    <li routerLink="/evaluacion_instancias/subir"><a>Corregir</a></li>
                    <li routerLink="/evaluaciones/resultados"><a>Resultados</a></li>
                    <li routerLink="/materiales"><a>Biblioteca</a></li>
                </ul>
            </div>

            <div class="col-md-3 col-md-offset-6 col-sm-6 footer-col">
                <h3>La Universidad</h3>
                <ul>
                    <li><a href="https://www.uautonoma.cl/" target="_blank">Sitio oficial</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <div class="bottom-footer-wrap">
            <a class="footer-logo" routerLink="/landing">
                <img
                    src="{{ config.app.assets.logoMediumLight }}"
                    alt="{{ config.plataforma.info.companyName }}"
                    title="{{ config.plataforma.info.companyName }}"
                />
            </a>
            <img
                class="footer-logo"
                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ua/layout/acreditada.png"
                alt="Acreditada"
            />
        </div>
        <div class="bottom-footer-wrap">
            <img
                class="footer-logo"
                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ua/landing/gratuidad.png"
                alt="Adscrita gratuidad"
            />
            <img
                class="footer-logo"
                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ua/landing/demre.png"
                alt="Postula a la Autónoma en www.demre.cl"
            />
        </div>
    </div>
</footer>
