<div class="seccion" (window:resize)="onResize($event)">
    <loading-layout #loadingLayout [opaque]="true">
        <div>
            <cui-section-headline>1. Seleccione un método para generar un Plan Persona</cui-section-headline>
            <div class="col-md-8">
                <p class="tutorial">
                    Los planes personales se generan a partir de un Instrumento de evaluación.
                    <br />
                    Seleccione un instrumento para generar la evaluación a partir de las siguientes opciones:
                </p>
            </div>
            <div class="col-md-4">
                <planes-personales-sidemenu
                    [tipoInstrumento]="tipoEvaluacion"
                    [asignaturaId]="asignaturaId"
                    (generadorInstrumentoChange)="onSelectGeneradorInstrumento($event)"
                    [hideInfo]="true"
                ></planes-personales-sidemenu>
            </div>
            <!-- <cui-section-headline>1. Seleccione un método para generar {{evaluacionArticuloIndefinidoString}} {{evaluacionString}}</cui-section-headline>
			<p class="tutorial">L{{evaluacionesGeneroPluralString}} {{evaluacionesMinusculaPluralString}} se generan a partir de un Instrumento de evaluación.<br>Seleccione un instrumento para generar la evaluación a partir de las siguientes  opciones:</p> -->
            <div class="row">
                <!-- <div class="col-md-12">
					<planes-personales-sidemenu [tipoInstrumento]="tipoEvaluacion" [asignaturaId]="asignaturaId" (generadorInstrumentoChange)="onSelectGeneradorInstrumento($event)"></planes-personales-sidemenu>
				</div> -->
                <div class="col-md-12">
                    <!--     Tabs verticales      -->
                    <div class="col-lg-3 col-md-4">
                        <ul class="vertical-tabs">
                            <li class="active">
                                <a data-toggle="tab" #tabGenerar href="#tab_crear_plan">Generar Plan Personal</a>
                            </li>
                        </ul>
                    </div>
                    <!--     Contenido Tabs verticales      -->
                    <div class="col-lg-9 col-md-8">
                        <div class="tab-content vertical-tab-content" #externalFrameReference>
                            <!-- dejar comentado hasta decidir liberar -->
                            <!--     Instrumentos Existentes     -->
                            <!-- <div class="vertical-tabs-pane tab-pane fade active in" id="tab_default_instrumento">
								<h4 class="h4_sub_title_1"><fa name="arrow-right" class="highlight-chevron"></fa>&nbsp;Seleccione alguno de nuestros instrumentos recomendados</h4>
								<div class="row">
									<div class="col-lg-4 col-md-4 col-xs-6" *ngFor="let tag of config.instrumentosPredefinidos.zonas">
										<recomendado-ensayo-profesor  [asignaturaId]= "asignaturaId" [tag]="tag" (onReadyInstrumento)="callbackRecomendado($event)"></recomendado-ensayo-profesor>
									</div>
								</div>
							</div> -->
                            <!--     Generar Instrumento      -->
                            <div class="vertical-tabs-pane tab-pane fade active in" id="tab_crear_instrumento">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div
                                            id="contenidos-container"
                                            class="contenidos-container clear-both"
                                            [class.contenidos-container-show]="show_contenidos"
                                        >
                                            <h4 class="h4_sub_title_1">
                                                <fa name="arrow-right" class="highlight-chevron"></fa>
                                                &nbsp;Personalizar
                                            </h4>
                                            <p class="tutorial">
                                                Selecciona las categorías para personalizar los contenidos del
                                                instrumento de evaluación.
                                            </p>

                                            <div class="generar-col2 contenidos-ensayo">
                                                <span>Nombre Plan Personal</span>
                                                <input
                                                    type="text"
                                                    [(ngModel)]="nombrePlanPersonal"
                                                    name="nombre"
                                                    placeholder="Nombre Plan Personal"
                                                    class="form-control"
                                                    required
                                                />
                                            </div>
                                            <br />

                                            <ng-container *ngIf="showErrorMsg">
                                                <p class="error-message">
                                                    *{{ errorMsg }}
                                                    <!-- *Debe seleccionar al menos una categoría. -->
                                                </p>
                                            </ng-container>
                                            <div class="generar-col2 contenidos-ensayo">
                                                <configurar-clasificaciones-plan-personal
                                                    *ngIf="generadorInstrumento"
                                                    [enableCantidadPreguntas]="true"
                                                    [tipoInstrumento]="tipoEvaluacion"
                                                    (onReady)="onReady()"
                                                    [generadorInstrumentoId]="generadorInstrumento.id"
                                                    [asignaturaId]="asignaturaId"
                                                    [(clasificacionesIds)]="clasificacionesIds"
                                                    [(anyEjeSelected)]="anyEjeSelected"
                                                ></configurar-clasificaciones-plan-personal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <h4 class="h4_sub_title_1">
                                    <fa name="arrow-right" class="highlight-chevron"></fa>
                                    &nbsp;Generar Plan Personal
                                </h4>
                                <cui-button
                                    type="button"
                                    class="btn_style btn-new"
                                    (click)="generarPlanPersonal()"
                                    [disabled]="!clasificacionesIds"
                                >
                                    <ogr-icon [name]="'prueba'"></ogr-icon>
                                    &nbsp;Generar Plan Personal
                                </cui-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </loading-layout>
</div>

<div *ngIf="errorPlanPersonal" class="col-md-12">
    <div class="row">
        <div class="col-lg-9 col-md-8">
            <div class="vertical-tab-content error-warning">
                <div class="">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    Error: {{ errorPlanPersonal }}
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="planPersonal">
    <cui-section-headline id="instrumentoSeleccionado">
        2. Compartir Plan Personal a partir del Instrumento seleccionado de la sección "evaluar"
    </cui-section-headline>
    <p class="tutorial">Para terminar de generar, elija la opción de Compartir en el menú.</p>
    <div class="row">
        <div class="col-md-4 inverted-col">
            <opciones-plan-personal
                [planPersonal]="planPersonal"
                [scrollAndFollow]="true"
                [responsiveBreak]="991"
                [topPadding]="20"
                [bottomPadding]="20"
                [followTopReference]="followReference"
                [followBottomReference]="followReference"
            ></opciones-plan-personal>
        </div>
        <div class="col-md-8" #followReference>
            <plan-personal-sesiones-profesor
                [planPersonal]="planPersonal"
                [enableReporte]="enableReporte"
            ></plan-personal-sesiones-profesor>
        </div>
    </div>
</div>
