import { Component, Input, OnInit, ChangeDetectorRef } from "@angular/core"
import { PlanPersonalSesion } from "@puntaje/puntaje/api-services"

@Component({
    selector: "barra-avance-planificacion",
    templateUrl: "barra-avance-planificacion.component.html",
    styleUrls: ["barra-avance-planificacion.component.scss"]
})
export class BarraAvancePlanificacionComponent implements OnInit {
    startAnimValues: boolean = false
    enableSmallSize: boolean = false
    avance: number = 0
    avanceEsperado: number = 0
    @Input() clases: PlanPersonalSesion[]
    currentDate: Date
    startDate: Date
    endDate: Date
    weeksTotal: number = 36

    constructor(protected cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.setAvanceBar()
    }

    setAvanceBar() {
        this.avance = 0
        let count = 0
        if (this.clases) {
            this.clases.forEach((c: PlanPersonalSesion) => {
                if (c.estado == "completada") count++
            })
            this.avance = Math.round(100 * (count / this.clases.length))
        }
        this.currentDate = new Date()
        this.startDate = new Date(this.currentDate.getFullYear() + "-3-1")
        this.endDate = new Date(this.startDate.getTime() + 7 * 24 * 60 * 60 * 1000 * this.weeksTotal)
        const currentWeeks = this.weeksBetween(this.startDate, this.currentDate)
        this.avanceEsperado = Math.round(100 * (currentWeeks / this.weeksTotal))
        if (this.avanceEsperado > 100) this.avanceEsperado = 100
        setTimeout(() => {
            this.startAnimValues = true
        }, 1000)
    }

    weeksBetween(d1, d2) {
        return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000))
    }
}
