import { Subscription } from "rxjs"

import { Component, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"

import { Instrumento, Instrumentos, PlanPersonal, PlanPersonales } from "@puntaje/puntaje/api-services"

import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "plan_personal_profesor.component.html",
    styleUrls: ["plan_personal_profesor.component.scss"]
})
export class PlanPersonalProfesorComponent implements OnInit {
    planPersonal: PlanPersonal
    instrumento: Instrumento
    planPersonalId: number
    subParams: Subscription
    subQuery: Subscription

    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected instrumentosService: Instrumentos,
        protected planPersonalesService: PlanPersonales
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.plan_personal_profesor")
        this.setPlanPersonal()
    }

    setPlanPersonal() {
        this.subParams = this.route.params.subscribe(params => {
            let queryParams = {
                //profesor: 1,
                plan_personal_usuario: {
                    receptor_type: "GrupoUsuario"
                },
                include:
                    "[" +
                    "usuario,generador_instrumento," +
                    "plan_personal_ciclos:[plan_personal_sesiones:[clasificacion,plan_personal_contenidos]]," +
                    "plan_personal_tipo," +
                    "plan_personal_usuarios:[grupo_usuario:[establecimiento]]" +
                    "]"
                // render_options: {
                // 	include: {
                // 		usuario: null,
                // 		generador_instrumento: null,
                // 		plan_personal_ciclos: {
                // 			include: {
                // 				plan_personal_sesiones: {
                // 					include: {
                // 						clasificacion: null,
                // 						plan_personal_contenidos: null,
                // 					}
                // 				}
                // 			}
                // 		},
                // 		plan_personal_tipo: null,
                // 		plan_personal_usuarios: {
                // 			include: {
                // 				grupo_usuario:
                // 				{
                // 					include: "establecimiento"
                // 				}
                // 			}
                // 		},
                // 	}
                // }
            }

            this.planPersonalesService.find(+params["id"], queryParams).then((planPersonal: PlanPersonal) => {
                this.planPersonal = planPersonal
                console.log("plan personal view", this.planPersonal)
            })
        })
    }

    ngOnDestroy() {
        this.subParams.unsubscribe()
    }

    onPlanCompartidoListener(value: any) {
        this.setPlanPersonal()
    }
}
