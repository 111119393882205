<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="clearfix">
            <div class="ciclo-select" *ngIf="planPersonal.plan_personal_ciclos.length > 1">
                <label>Ciclo:</label>
                <div class="ciclo-select-container">
                    <select
                        class="form-control"
                        [(ngModel)]="currentCicloIndex"
                        (ngModelChange)="onChangeSelect($event)"
                    >
                        <option *ngFor="let ciclo of planPersonal.plan_personal_ciclos; let i = index" [ngValue]="i">
                            {{ i }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="sesiones-container" [class.no-padding]="planPersonalSesiones && planPersonalSesiones.length == 1">
            <div *ngFor="let sesion of planPersonalSesiones; let i = index; trackBy: trackSesion" class="sesion">
                <div class="order-container" *ngIf="planPersonalSesiones.length > 1">
                    <button
                        class="btn btn-default"
                        [disabled]="orderingSesions || !(i > 0)"
                        (click)="onSesionMoverAtras(sesion)"
                    >
                        <fa name="arrow-up"></fa>
                    </button>
                    <button
                        class="btn btn-default"
                        [disabled]="orderingSesions || !(i < planPersonalSesiones.length - 1)"
                        (click)="onSesionMoverAdelante(sesion)"
                    >
                        <fa name="arrow-down"></fa>
                    </button>
                </div>
                <plan-personal-sesion-box-profesor
                    [planPersonalSesion]="sesion"
                    [planPersonalId]="planPersonal.id"
                    [enableReporte]="enableReporte"
                ></plan-personal-sesion-box-profesor>
            </div>
        </div>
    </div>
</loading-layout>
