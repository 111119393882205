import { Component, EventEmitter, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { Asignatura } from "@puntaje/nebulosa/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "generar_plan_personal.component.html"
})
export class GenerarPlanPersonalComponent {
    asignaturaId: number
    tipoInstrumento: string
    asignatura: Asignatura
    sub: Subscription
    subQuery: Subscription
    generadorInstrumentoId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute, protected router: Router) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.generar_plan_personal")
        this.sub = this.route.params.subscribe(params => {
            this.asignaturaId = +params["asignatura_id"]
        })

        this.subQuery = this.route.queryParams.subscribe(query => {
            this.tipoInstrumento = query["tipo_instrumento"] || config.plataforma.evaluacionDefault
        })
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQuery.unsubscribe()
    }

    onReadyAsignaturaDropdown(asignaturaId) {
        this.router.navigate(["/planes_personales/generar_plan_personal", asignaturaId], {
            queryParams: { tipo_instrumento: this.tipoInstrumento }
        })
    }

    onSelectAsignatura(asignaturaId) {
        this.asignaturaId = asignaturaId
    }

    onSelectGeneradorInstrumento(generadorInstrumentoId) {
        this.generadorInstrumentoId = generadorInstrumentoId
    }
}
