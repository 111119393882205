<loading-layout #loading>
    <ng-container *ngIf="planificacion$ | async as planificacion">
        <div class="content-planificacion">
            <div class="item1">
                <sticky-top>
                    <datos-generales-planificacion
                        [enableAplicar]="true"
                        [planificacion]="planificacion"
                        (aplicarCall)="aplicar()"
                    ></datos-generales-planificacion>
                </sticky-top>
                <aplicar-plan-personal-profesores
                    [planPersonal]="planPersonal"
                    (planCompartidoEvent)="onPlanCompartido($event)"
                ></aplicar-plan-personal-profesores>
            </div>
            <div class="item2">
                <div transition-group class="clases">
                    <div
                        *ngFor="
                            let clase of planificacion.plan_personal.plan_personal_ciclos[0].plan_personal_sesiones;
                            let i = index
                        "
                        transition-group-item
                        class="clase-profesor"
                    >
                        <clase-profesor
                            [planificacionId]="planificacionId"
                            [enableToggleActiva]="true"
                            [disableActual]="true"
                            [disableSeguimiento]="!hasSeguimiento"
                            [provieneDeCompartida]="false"
                            [clase]="clase"
                            [index]="i"
                            [activa]="!!sesionesActivas[i]"
                            [enableReporte]="enableReporte"
                            (activaChange)="updateSesionActiva($event)"
                            (editingChange)="updateEditingSesiones($event)"
                            (fechasChange)="updateOrdenByFechas()"
                        ></clase-profesor>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!hasSesiones">
            <div class="alert alert-danger alert-dismissible">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                <strong>Debe seleccionar al menos 1 sesión</strong>
            </div>
        </div>

        <br />
        <br />
        <div class="clearfix">
            <cui-button class="btn-primary btn_style pull-right" (click)="aplicar()">Aplicar Planificacion</cui-button>
        </div>
    </ng-container>
</loading-layout>
