import { Component, ViewChild } from "@angular/core"
import {
    PlanPersonal,
    PlanPersonales,
    PlanPersonalUsuario,
    PlanPersonalInstancia,
    Usuario,
    EvaluacionInstancia,
    PlanPersonalEvaluacionInstancia,
    PlanPersonalMaterialInstancia,
    Establecimiento
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignaturas, Asignatura, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "estadisticas_planes_personales.component.html"
})
export class EstadisticasPlanesPersonalesComponent {
    stats: any
    @ViewChild("EstadisticasLoading", { static: true }) estadisticasLoading: LoadingLayoutComponent
    @ViewChild("AsignaturaLoading", { static: true }) asignaturaLoading: LoadingLayoutComponent

    establecimiento: Establecimiento
    establecimientos: Establecimiento[]

    asignaturaId: number
    asignatura: Asignatura

    generadorInstrumento: GeneradorInstrumento

    constructor(
        protected titleService: TitleService,
        protected asignaturasService: Asignaturas,
        protected planPersonalesService: PlanPersonales
    ) {}

    getPlan(planId) {
        let usuario_pais = "usuario_" + config.plataforma.pais
        let params = {
            plan_personal: { id: planId },
            with_grupos: 1,
            include:
                "[plan_personal_usuarios:grupo_usuario:alumnos:" +
                "usuario_" +
                config.plataforma.pais +
                ",plan_personal_instancias:[plan_personal_evaluacion_instancias:evaluacion_instancia,plan_personal_material_instancias]]",
            methods: "total_ciclos"
        }
        this.estadisticasLoading.standby()
        this.planPersonalesService.where(params).then((planes: PlanPersonal[]) => {
            let stats: any[] = []
            planes.forEach((plan: PlanPersonal) => {
                let plan_personal_stat = { nombre: plan.plan_personal, usuarios: [] }

                plan.plan_personal_usuarios.forEach((plan_personal_usuario: PlanPersonalUsuario) => {
                    plan_personal_usuario.grupo_usuario.alumnos.forEach((alumno: Usuario) => {
                        plan_personal_stat.usuarios.push({
                            id: alumno.id,
                            nombre: alumno.nombre,
                            apellido_paterno: alumno.apellido_paterno,
                            instancia: false,
                            omitidas: 0,
                            correctas: 0,
                            incorrectas: 0,
                            promedio: 0,
                            avance: 0,
                            tiempo: 0,
                            total_ciclos: plan.total_ciclos,
                            pais_identificador: alumno.paisIdentificador()
                        })
                    })
                })
                plan.plan_personal_instancias.forEach((plan_personal_instancia: PlanPersonalInstancia) => {
                    let usuario_stat = plan_personal_stat.usuarios.find(usuario => {
                        return usuario.id == plan_personal_instancia.usuario_id
                    })
                    if (usuario_stat) {
                        usuario_stat.avance =
                            plan_personal_instancia.plan_personal_evaluacion_instancias.length +
                            plan_personal_instancia.plan_personal_material_instancias.length
                        plan_personal_instancia.plan_personal_evaluacion_instancias.forEach(
                            (plan_personal_evaluacion_instancia: PlanPersonalEvaluacionInstancia) => {
                                usuario_stat.omitidas +=
                                    plan_personal_evaluacion_instancia.evaluacion_instancia.omitidas
                                usuario_stat.correctas +=
                                    plan_personal_evaluacion_instancia.evaluacion_instancia.omitidas
                                usuario_stat.incorrectas +=
                                    plan_personal_evaluacion_instancia.evaluacion_instancia.incorrectas
                            }
                        )
                        plan_personal_instancia.plan_personal_material_instancias.forEach(
                            (plan_personal_material_instancia: PlanPersonalMaterialInstancia) => {
                                usuario_stat.tiempo += plan_personal_material_instancia.tiempo
                            }
                        )
                        let total_respuestas = usuario_stat.omitidas + usuario_stat.correctas + usuario_stat.incorrectas
                        if (total_respuestas == 0) usuario_stat.promedio = 0
                        else usuario_stat.promedio = Math.round((usuario_stat.correctas * 100) / total_respuestas)
                    }
                })
                stats.push(plan_personal_stat)
            })
            this.stats = stats
            this.estadisticasLoading.ready()
        })
    }

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.estadisticas_planes_personales")
        this.estadisticasLoading.ready()
    }

    onSelectAsignatura(asignaturaId) {
        this.stats = null
        this.asignaturaLoading.standby()
        this.asignaturaId = asignaturaId
        this.asignaturasService
            .find(this.asignaturaId, {
                with_generador_instrumentos: 1,
                tipo_instrumento: config.plataforma.evaluacionTipoAdaptiveDefault
            })
            .then((asignatura: Asignatura) => {
                this.asignatura = asignatura
                this.asignaturaLoading.ready()
            })
    }

    onFiltro(planId) {
        this.getPlan(planId)
    }
}
